import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class FileManagerService {
    constructor(private http: HttpClient) {}

    getToken() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        return this.http.get(`${environment.apiUrl}/api/storage/token`, {
            headers,
        });
    }
}
