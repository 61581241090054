import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { SliderItemResponse } from './../../../service/Client';
import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort, MatDialog, MatDialogRef } from '@angular/material';
import { Client } from 'app/service/Client';
import { SliderSiteService } from './slider-site.service';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { environment } from 'environments/environment';
import { ConfirmDialogComponent } from '../news-site/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';


@Component({
    selector: 'app-slider-site',
    templateUrl: './slider-site.component.html',
    styleUrls: ['./slider-site.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class SliderSiteComponent implements OnInit {
    dataSource: FilesDataSource | null;
    displayedColumns = ['pos', 'image', 'title', 'created', 'updated', 'active', 'buttons'];
    public storageUrl = environment.storageUrl;

    noPhoto: string = environment.apiUrlProd + environment.noPhotoUrl;
    confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

    sliderLength = 0;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild('filter')
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;


    constructor(
        private client: Client,
        private sliderSiteService: SliderSiteService,
        private adminBaseService: AdminBaseService,
        public _matDialog: MatDialog, 
        private router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.adminBaseService.resetPage = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.sliderSiteService, this.paginator, this.sort, this.adminBaseService);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.adminBaseService.resetPage = true;
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    changePos(index: number, type: string): void {
        this.sliderSiteService.changePosition(index, type);
    }

    deleteContact(id): void {
        this.confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: 'auto',
            height: 'auto'
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Вы уверены, что хотите удалить?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.sliderSiteService.deleteContact(id).then(x =>{
                    let index: number = this.sliderSiteService.sliderItems.findIndex(d => d.id === id);
                    this.sliderSiteService.sliderItems.splice(index,1);
                    this.sliderSiteService.onSliderItemsChanged.next(this.sliderSiteService.sliderItems);
                    this.ngOnInit();
                });
            }
            this.confirmDialogRef = null;
        });

    }
    refresh(): void {
        window.location.reload();
    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {SliderSiteSerivce} sliderSiteService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private sliderSiteService: SliderSiteService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
        private _adminBaseService: AdminBaseService
    ) {
        super();

        this.filteredData = this.sliderSiteService.sliderItems;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.sliderSiteService.onSliderItemsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    if (this._adminBaseService.resetPage) {
                        this._matPaginator._pageIndex = 0;
                    }
                    let data = this.sliderSiteService.sliderItems.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        data.map(x => x.titleSecond = '');
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._matSort.active) {
                case 'pos':
                    [propertyA, propertyB] = [a.position, b.position];
                    break;
                case 'title':
                    [propertyA, propertyB] = [a.title, b.title];
                    break;
                case 'created':
                    [propertyA, propertyB] = [a.created, b.created];
                    break;
                case 'updated':
                    [propertyA, propertyB] = [a.updated, b.updated];
                    break;
                case 'active':
                    [propertyA, propertyB] = [a.isActive, b.isActive];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}

