export const notyf = {
    type: {
        info: 'info',
        success: 'success',
        warn: 'warn',
        error: 'error',
    },
    message: {
        saveUpdateCompleate: 'Запись успешно обновлена',
        saveUpdateError: 'При обновлении записи возникли ошибки',
        saveAddCompleate: 'Запись успешно добавлена',
        saveAddError: 'При добавлении записи возникли ошибки',
        getError: 'При получении записи возникла ошибка',

        postionChangeCompleate: 'Позиция изменена',
        postionChangeError: 'Не удалось изменить позицию',

        currencyChangeCompleate: 'Валюта заказа изменена',
        currencyChangeError: 'Не удалось изменить валюту заказа',

        loginCompleate: 'Вы успешно авторизировались',
        loginError: 'Не удалось авторизироваться',

        addImageCompleate: 'Изображение добавлено',
        addImageError: 'Не удалось добавить изображение',

        removeImageCompleate: 'Изображение удалено',
        removeImageError: 'Не удалось удалить изображение',

        removeSliderImageCompleate: 'Слайд удален',
        removeSliderImageError: 'Не удалось удалить слайд',

        changeMainImageCompleate: 'Главное изображение изменено',
        changeMainImageError: 'Не удалось изменить главное изображение',

        orderRejectComplete: 'Заказ успешно отменен',
        orderRejectError: 'При отмене заказа возникли ошибки',
        orderRejectAddOrUpdateError: 'Отмененный заказ нельзя редактировать',

        productGetByArticulError: 'Товар с таким артикулом не найден',

        calculateOrderPriceError: 'Не удалось пересчитать стоимость заказа',

        adminUserInfoError: 'Не удалось загрузить данные пользователя',
        getCountryError: 'Не удалось загрузить информация по странам',
        getCityError: 'Не удалось загрузить информация по городам',
        getStoreError: 'Не удалось загрузить информация по складам',
        getArticleError: 'Не удалось загрузить информация по новостям',
        getBrandError: 'Не удалось загрузить информация по брендам',
        getCategoryError: 'Не удалось загрузить информация по категориям',
        getMarkError: 'Не удалось загрузить информация по маркам авто',
        getModelError: 'Не удалось загрузить информация по моделям авто',
        getModificationError: 'Не удалось загрузить информация по модификациям авто',
        getChatError: 'Не удалось загрузить информация по переписке',
        getUserError: 'Не удалось загрузить информация по пользователе',
        getContactUsError: 'Не удалось загрузить информация по формам обратной связи',
        getDownloadInfoError: 'Не удалось загрузить информацию для скачивания',
        getOptionError: 'Не удалось загрузить информацию по характеристикам',
        getOrderError: 'Не удалось загрузить информацию по заказам',
        getPageError: 'Не удалось загрузить информацию по статическим страницам',
        getPartnerError: 'Не удалось загрузить информацию по партнерам',
        getPhoneCountryError: 'Не удалось загрузить информацию по странам(операторам)',
        getPriceLevelError: 'Не удалось загрузить информацию по уровням цен',
        getProductError: 'Не удалось загрузить информацию по товару',
        getRouteError: 'Не удалось загрузить информацию по маршрутам',
        getSectorError: 'Не удалось загрузить информацию по секторам',
        getSliderError: 'Не удалось загрузить информацию по слайдеру',
        getStoError: 'Не удалось загрузить информацию по СТО',
        getUnitError: 'Не удалось загрузить информацию по единицам измерения',
        getHelperAllError: 'Не удалось загрузить информацию из БД',

        brandUpdateCompleate: 'Бренд успешно обновлен',
        brandUpdateError: 'При обновлении бренда возникли ошибки',
        brandAddCompleate: 'Бренд успешно добавлен',
        brandAddError: 'При добавлении бренда возникли ошибки',

        categoryUpdateCompleate: 'Категория успешно обновлена',
        categoryUpdateError: 'При обновлении категории возникли ошибки',
        categoryAddCompleate: 'Категория успешно добавлена',
        categoryAddError: 'При добавлении категории возникли ошибки',

        markUpdateCompleate: 'Марка авто успешно обновлена',
        markUpdateError: 'При обновлении марки авто возникли ошибки',
        markAddCompleate: 'Марка авто успешно добавлена',
        markAddError: 'При добавлении марки авто возникли ошибки',

        modelUpdateCompleate: 'Модель авто успешно обновлена',
        modelUpdateError: 'При обновлении модели авто возникли ошибки',
        modelAddCompleate: 'Модель авто авто успешно добавлена',
        modelAddError: 'При добавлении модели авто возникли ошибки',

        modificationUpdateCompleate: 'Модификация авто успешно обновлена',
        modificationUpdateError: 'При обновлении модификации авто возникли ошибки',
        modificationAddCompleate: 'Модификация авто успешно добавлена',
        modificationAddError: 'При добавлении модификации авто возникли ошибки',

        cityUpdateCompleate: 'Город успешно обновлен',
        cityUpdateError: 'При обновлении города возникли ошибки',
        cityAddCompleate: 'Город успешно добавлен',
        cityAddError: 'При добавлении города возникли ошибки',

        countryUpdateCompleate: 'Страна успешно обновлена',
        countryUpdateError: 'При обновлении страны возникли ошибки',
        countryAddCompleate: 'Страна успешно добавлена',
        countryAddError: 'При добавлении страны возникли ошибки',

        downloadInfoUpdateCompleate: 'Информация для скачивания успешно обновлена',
        downloadInfoUpdateError: 'При обновлении информации для скачивания возникли ошибки',
        downloadInfoAddCompleate: 'Информация для скачивания успешно добавлена',
        downloadInfoAddError: 'При добавлении информации для скачивания возникли ошибки',

        newsUpdateCompleate: 'Запись успешно обновлена',
        newsUpdateError: 'При обновлении записи возникли ошибки',
        newsAddCompleate: 'Запись успешно добавлена',
        newsAddError: 'При добавлении записи возникли ошибки',

        optionUpdateCompleate: 'Характеристика успешно обновлена',
        optionUpdateError: 'При обновлении характеристики возникли ошибки',
        optionAddCompleate: 'Характеристика успешно добавлена',
        optionAddError: 'При добавлении характеристики возникли ошибки',

        orderUpdateCompleate: 'Заказ успешно обновлен',
        orderUpdateError: 'При обновлении заказа возникли ошибки',
        orderAddCompleate: 'Заказ успешно добавлен',
        orderAddError: 'При добавлении заказа возникли ошибки',

        partnerUpdateCompleate: 'Партнер успешно обновлена',
        partnerUpdateError: 'При обновлении парнтера возникли ошибки',
        partnerAddCompleate: 'Партнер успешно добавлена',
        partnerAddError: 'При добавлении парнтера возникли ошибки',

        priceLevelUpdateCompleate: 'Уровень цен успешно обновлен',
        priceLevelUpdateError: 'При обновлении уровня цен возникли ошибки',
        priceLevelAddCompleate: 'Уровень успешно добавлен',
        priceLevelAddError: 'При добавлении уровня цен возникли ошибки',

        productUpdateCompleate: 'Товар успешно обновлен',
        productUpdateError: 'При обновлении товара возникли ошибки',
        productAddCompleate: 'Товар успешно добавлен',
        productAddError: 'При добавлении товара возникли ошибки',

        routeUpdateCompleate: 'Маршрут успешно обновлен',
        routeUpdateError: 'При обновлении маршрута возникли ошибки',
        routeAddCompleate: 'Маршрут успешно добавлен',
        routeAddError: 'При добавлении записи возникли ошибки',

        sectorUpdateCompleate: 'Сектор успешно обновлен',
        sectorUpdateError: 'При обновлении сектора возникли ошибки',
        sectorAddCompleate: 'Сектор успешно добавлен',
        sectorAddError: 'При добавлении сектора возникли ошибки',

        sliderUpdateCompleate: 'Слайд успешно обновлен',
        sliderUpdateError: 'При обновлении слайда возникли ошибки',
        sliderAddCompleate: 'Слайд успешно добавлен',
        sliderAddError: 'При добавлении слайда возникли ошибки',

        unitUpdateCompleate: 'Единица измерения успешно обновлена',
        unitUpdateError: 'При обновлении единицы измерения возникли ошибки',
        unitAddCompleate: 'Единица измерения успешно добавлена',
        unitAddError: 'При добавлении единицы измерения возникли ошибки',

        userUpdateCompleate: 'Пользователь успешно обновлен',
        userUpdateError: 'При обновлении пользователя добавлен ошибки',
        userAddCompleate: 'Пользователь успешно добавлена',
        userAddError: 'При добавлении пользователя возникли ошибки',

        storeUpdateCompleate: 'Склад успешно обновлен',
        storeUpdateError: 'При обновлении склада возникли ошибки',
        storeAddCompleate: 'Склад успешно добавлен',
        storeAddError: 'При добавлении склада возникли ошибки',

        contactUpdateCompleate: 'Форма обратной связи успешно обновлена',
        contactUpdateError: 'При обновлении формы обратной связи возникли ошибки',
        contactAddCompleate: 'Форма обратной связи успешно создана',
        contactAddError: 'При добавлении формы обратной связи возникли ошибки',

        pageUpdateCompleate: 'Статическая страница успешно обновлена',
        pageUpdateError: 'При обновлении статической страницы возникли ошибки',
        pageAddCompleate: 'Статическая страница успешно добавлена',
        pageAddError: 'При добавлении статической страницы возникли ошибки',

        phoneCountryUpdateCompleate: 'Страна(оператор) успешно обновлена',
        phoneCountryUpdateError: 'При обновлении страны(оператора) возникли ошибки',
        phoneCountryAddCompleate: 'Страна(оператор) успешно добавлена',
        phoneCountryAddError: 'При добавлении страны(оператора) возникли ошибки',

        settingUpdateCompleate: 'Настройки сайта успешно обновлены',
        settingUpdateError: 'При обновлении настроек сайта возникли ошибки',
        settingAddCompleate: 'Настройки сайта успешно добавлен',
        settingAddError: 'При добавлении настроек сайта возникли ошибки',

        stoUpdateCompleate: 'Форма записи на СТО успешно обновлена',
        stoUpdateError: 'При обновлении формы записи на СТО возникли ошибки',
        stoAddCompleate: 'Форма записи на СТО успешно создана',
        stoAddError: 'При добавлении формы записи на СТО возникли ошибки',

        tecDocBrandBlackListUpdateCompleate: 'Черный список брендов TecDoc обновлен',
        tecDocBrandBlackListUpdateError: 'При обновлении черного списока брендов TecDoc возникли ошибки',
    }
};