import { AdminTecDocMarkAddOrUpdate } from './../../../../../service/Client';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Type, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Client, AdminTecDocMarkResponse, FileParameter } from 'app/service/Client';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-mark-item',
    templateUrl: './mark-item.component.html',
    styleUrls: ['./mark-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class MarkItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    mark: AdminTecDocMarkResponse;

    public EditorFull = ClassicEditorFull;
    //public Editor = ClassicEditor;
    isNew = false;
    isReady = false;
    id = '';

    markForm: FormGroup;
    name: FormControl;
    fullDescription: FormControl;
    matchCode: FormControl;
    id1C: FormControl;
    tecDocId: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initMark();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {

    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initMark(): void {
        this.client.adminTecDocCar_VehicleById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.mark = data as AdminTecDocMarkResponse;
                this.createFormControls();
            }, error => {
                this.adminService.showNotify(notyf.type.error, notyf.message.getMarkError);
                console.error(error);
            });
    }

    createFormControls(): void {
        if (this.mark) {
            this.name = new FormControl(this.mark.name, Validators.required);
            this.fullDescription = new FormControl(this.mark.fullDescription, Validators.required);
            this.matchCode = new FormControl(this.mark.matchCode);
            this.id1C = new FormControl(this.mark.id1C);
            this.tecDocId = new FormControl(this.mark.tecDocId, Validators.required);
            this.isActive = new FormControl(this.mark.isActive, Validators.required);

        } else {
            this.name = new FormControl(null, Validators.required);
            this.fullDescription = new FormControl(null, Validators.required);
            this.matchCode = new FormControl(null);
            this.id1C = new FormControl(null);
            this.tecDocId = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.markForm = new FormGroup({
            Name: this.name,
            FullDescription: this.fullDescription,
            MatchCode: this.matchCode,
            Id1C: this.id1C,
            TecDocId: this.tecDocId,
            IsActive: this.isActive
        });
        this.isReady = true;
    }

    toPost(): void {
        if (this.markForm.valid) {
            const model: AdminTecDocMarkAddOrUpdate = {
                name: this.markForm.value.Name,
                fullDescription: this.markForm.value.FullDescription,
                matchCode: this.markForm.value.MatchCode,
                id: this.id,
                id1C: this.markForm.value.Id1C,
                tecDocId: this.markForm.value.TecDocId,
                isActive: this.markForm.value.IsActive
            };

            this.client.adminTecDocCar_UpdateVehicle(model)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (this.adminService.helperAll) {
                        const index = this.adminService.helperAll.marks.findIndex(x => x.id === data.id);
                        if (index > -1) {
                            this.adminService.helperAll.marks[index] = data;
                            this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
                        }
                    }
                    this.router.navigate(['/admin/tecdoc/cars/marks']);
                    this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.markAddCompleate : notyf.message.markUpdateCompleate);
                }, error => {
                    this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.markAddError : notyf.message.markUpdateError);
                    console.error(error);
                });
        }
    }
}
