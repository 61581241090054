import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { TecDocBrandResponse, Client, AdminTecDocBrandBlackListResponse, AdminTecDocBrandBlackListAddOrUpdate } from './../../../service/Client';
import { AdminBaseService } from './../../services/admin.base.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-brand-blacklist',
  templateUrl: './brand-blacklist.component.html',
  styleUrls: ['./brand-blacklist.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class BrandBlacklistComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    allBrands: TecDocBrandResponse[] = [];
    allBrandsDisplayed: TecDocBrandResponse[] = [];
    selectedBrandId = 0;
    brandSearch = '';

    isReady = false;

    brandBlacklistArray: FormArray;
    blackList: AdminTecDocBrandBlackListResponse[] = [];

  constructor(
      private adminService: AdminBaseService,
      private client: Client,
      private fb: FormBuilder
  ) { }

  ngOnInit() {
      this.initBlackList();
      this.adminHelperListener();
  }

  public ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
}

adminHelperListener(): void {
    this.adminService.helperAllChangeStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
        if (data) {
            this.allBrands = data.tecDocBrands;
            this.allBrandsDisplayed = this.allBrands;
        }
    });
}

    initBlackList(): void {
        this.client.adminBrand_Blacklist()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.blackList = data as AdminTecDocBrandBlackListResponse[];
            console.log(this.blackList);
            this.createFrmControls();
        }, error => { console.error(error); });
    }

    createFrmControls(): void {
        this.brandBlacklistArray = new FormArray([]);
        if (this.blackList && this.blackList.length > 0) {
            this.blackList.map(x => this.brandBlacklistArray.push(this.fb.group({ 
                id: new FormControl(x.id),
                shortName: new FormControl(x.shortName),
                tecDocBrandId: new FormControl(x.tecDocBrandId),
                created: new FormControl(x.created)
             })));
        }
        this.isReady = true;
    }

    searchBrand(): void {
        if (!this.brandSearch) {
            this.allBrandsDisplayed = this.allBrands;
            return;
        }
        this.allBrandsDisplayed = this.allBrands.filter(x => x.shortName.toLocaleLowerCase().includes(this.brandSearch.toLocaleLowerCase()) ||
        x.id === Number(this.brandSearch));
    }

    removeFromBlackList(index: number): void {
        this.brandBlacklistArray.controls.splice(index, 1);
        this.brandBlacklistArray.value.splice(index, 1);
    }

    addToBlackList(): void {
        const index = this.allBrands.findIndex(x => x.id === this.selectedBrandId);
        const exist = this.brandBlacklistArray.value.findIndex(x => x.tecDocBrandId === this.selectedBrandId);
        if (index < 0 || exist > -1) {
            return;
        }
        this.brandBlacklistArray.push(this.fb.group({ 
            id: new FormControl(null),
            shortName: new FormControl(this.allBrands[index].shortName),
            tecDocBrandId: new FormControl(this.allBrands[index].id),
            created: new FormControl(new Date())
         }));
    }

    toPost(): void {
        if (this.brandBlacklistArray) {
            const tecDocBrandIds: number[] = [];
            const blackListIds: string[] = [];
            for (let i = 0; i < this.brandBlacklistArray.value.length; i++) {
                if (this.brandBlacklistArray.value[i].id) {
                    blackListIds.push(this.brandBlacklistArray.value[i].id);
                } else {
                    tecDocBrandIds.push(this.brandBlacklistArray.value[i].tecDocBrandId);
                }
            }
            const model: AdminTecDocBrandBlackListAddOrUpdate = {
                tecDocBrandIds: tecDocBrandIds,
                blackListIds: blackListIds
            };
            this.client.adminBrand_BlacklistUpdate(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                this.adminService.showNotify(notyf.type.success, notyf.message.tecDocBrandBlackListUpdateCompleate);
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.tecDocBrandBlackListUpdateError );
                console.error(error); });
        }
    }

}
