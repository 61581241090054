import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { Router } from '@angular/router';
import { AuthService } from './../../services/authentication/auth.service.service';
import { Client } from 'app/service/Client';
import { AdminUserProfileResponse } from './../../../service/Client';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { takeUntil } from 'rxjs/operators';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    isLogin = false;

    profile: AdminUserProfileResponse;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private client: Client,
        private authService: AuthService,
        private adminService: AdminBaseService,
        private router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

            this.adminService.inotHelperAll();

            this.authService.authNavStatus$.subscribe(state => {      
                console.log(state);
                          
                if (state && this.isLogin !== state) {
                    this.client.adminSetting_AdminInfo().subscribe(data => {
                        this.profile = data as AdminUserProfileResponse;
                        this.adminService.adminProfile = this.profile;
                    }, error => { 
                        this.adminService.showNotify(notyf.type.error, notyf.message.adminUserInfoError);
                        console.error(error); });
                }
                if (state !== this.isLogin) {
                    this.isLogin = state;
                }

            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
