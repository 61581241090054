import { ContactusSiteSerivce } from './contactus.site.service';
import { takeUntil, debounceTime, distinctUntilChanged, map, startWith, switchMap, catchError } from 'rxjs/operators';
import { AdminContactUsRequest, AdminContactUsHelper, AdminContactUsResponse } from './../../../service/Client';
import { query } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, BehaviorSubject } from 'rxjs';
import { merge, Observable, of as observableOf } from 'rxjs';
import { Client, AdminPageFilterRequest } from 'app/service/Client';
import { MatPaginator, MatSort, MatDialog, MatDialogRef } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { NewsSiteSerivce } from '../news-site/news-site.service';
import { FuseUtils } from '@fuse/utils';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { constants } from 'fs';
import { ConfirmDialogComponent } from '../news-site/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-contactus-site',
    templateUrl: './contactus-site.component.html',
    styleUrls: ['./contactus-site.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class ContactusSiteComponent implements OnInit {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

    dataSource: FilesDataSource | null;

    displayedColumns: string[] = ['state', 'name', 'message', 'contact', 'created', 'updated', 'buttons'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild('filter')
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private client: Client,
        private contactusSiteSerivce: ContactusSiteSerivce,
        private adminBaseService: AdminBaseService,
        public _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.adminBaseService.resetPage = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this.contactusSiteSerivce, this.paginator, this.sort, this.adminBaseService);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }
                this.adminBaseService.resetPage = true;  
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }


    deleteContact(id): void
    {
        this.confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Вы уверены, что хотите удалить?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this.contactusSiteSerivce.deleteContact(id).then(x =>{
                    let index: number = this.contactusSiteSerivce.contactus.findIndex(d => d.id === id);
                    this.contactusSiteSerivce.contactus.splice(index,1);
                    this.contactusSiteSerivce.onContactusChanged.next(this.contactusSiteSerivce.contactus);
                    this.ngOnInit();
                });
            }
            this.confirmDialogRef = null;
        });

    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    pageFilter: AdminPageFilterRequest;

    constructor(
        private contactusSiteSerivce: ContactusSiteSerivce,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
        private _adminBaseService: AdminBaseService
    )
    {
        super();

        this.filteredData = this.contactusSiteSerivce.contactus;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this.contactusSiteSerivce.onContactusChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    if (!this.pageFilter) {
                        this.pageFilter = this._adminBaseService.getDefaultPageFilter(this._matSort.direction);
                    }
                    const newPageFilter = this._adminBaseService.getPageFilter(
                        this._matPaginator.pageIndex + 1, 
                        this._matPaginator.pageSize, 
                        this._filterChange.value, 
                        this._matSort.active, 
                        this._matSort.direction
                    );

                    console.log(this.contactusSiteSerivce.contactusHelper.paginator);

                    if (!this._adminBaseService.comparePageFilter(this.pageFilter, newPageFilter)) {
                        const model: AdminContactUsRequest = {
                            pageFilter: newPageFilter,
                            type: 4,
                        };
                        this.pageFilter = newPageFilter;
                        if (this._adminBaseService.resetPage) {
                            const result = this._adminBaseService.getDefaultPageFilterViaSearch(model.pageFilter, this._matPaginator);
                            model.pageFilter = result[0];
                            this._matPaginator = result[1];
                        }
                        this.contactusSiteSerivce.getContactus(model);
                    }                   
                        let data = this.contactusSiteSerivce.contactus;

                        if (this.contactusSiteSerivce.contactusHelper.paginator) {
                            this._matPaginator._length = this.contactusSiteSerivce.contactusHelper.paginator.countTotal;
                        }
                        if (data) {
                            data = data.slice();
                            this.filteredData = [...data];
                        } else {
                            this.filteredData = [];
                        }
                        return data;
                    }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    get paginatorLength(): number {
        return this.contactusSiteSerivce.contactusHelper.paginator ? this.contactusSiteSerivce.contactusHelper.paginator.countTotal : 0;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }  

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}