import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCountryResponse, AdminOptionAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminOptionResponse, FileParameter, Client, ArticleResponse, OptionResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-option-item',
    templateUrl: './option-item.component.html',
    styleUrls: ['./option-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OptionSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    option: AdminOptionResponse;
    optionsAll: AdminOptionResponse[];
    optionsDisplayed: AdminOptionResponse[];

    id = null;

    // public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    parentId = null;
    optionType = 0;
    searchOption = '';

    optionForm: FormGroup;
    id1c: FormControl;
    name: FormControl;
    description: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initOption();
                } else {
                    this.isNew = true;
                    this.initOptionsAll();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initOptionsAll(): void {
        this.client.adminSetting_OptionsAll()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.optionsAll = data;
            this.optionsDisplayed = this.optionsAll;
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getOptionError );
            console.error(error); });
    }

    initOption(): void {
        this.client.adminOption_OptionById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.option = data.option;
                this.optionsAll = data.allOptions;
                this.optionsDisplayed = this.optionsAll;
                this.id = this.option.id;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getOptionError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.option) {
            this.id1c = new FormControl(this.option.id1C, Validators.required);
            this.name = new FormControl(this.option.name, Validators.required);
            this.description = new FormControl(this.option.description);
            this.isActive = new FormControl(this.option.isActive, Validators.required);
            this.optionType = this.option.type;
            this.parentId = this.option.parentId;
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.description = new FormControl(null);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.optionForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            Description: this.description,
            IsActive: this.isActive
        });

        this.isReady = true;
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    searchOptions(): void {
        if (!this.searchOption) {
            this.optionsDisplayed = this.optionsAll;
            return;
        }
        this.optionsDisplayed = this.optionsAll.filter(x => x.name.toLocaleLowerCase().includes(this.searchOption.toLocaleLowerCase()));
    }
  
    toPost(): void {
        const model: AdminOptionAddOrUpdate = {
            id: this.id,
            id1C: this.optionForm.value.Id1c,
            name: this.optionForm.value.Name,
            description: this.optionForm.value.Description,
            isActive: this.optionForm.value.IsActive,
            parentId: this.parentId,
            type: this.optionType
        };
        this.client.adminOption_OptionAddOrUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (this.isNew) {
                this.adminService.helperAll.options.push(data);
                this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
            } else {
                const index = this.adminService.helperAll.options.findIndex(x => x.id === data.id);
                if (index > -1) {
                    this.adminService.helperAll.options[index].id1C = data.id1C;
                    this.adminService.helperAll.options[index].type = data.type;
                    this.adminService.helperAll.options[index].typeStr = data.typeStr;
                    this.adminService.helperAll.options[index].name = data.name;
                    this.adminService.helperAll.options[index].description = data.description;
                    this.adminService.helperAll.options[index].isActive = data.isActive;
                    this.adminService.helperAll.options[index].parentId = data.parentId;
                }
            }
            this.router.navigate(['/admin/options']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.optionAddCompleate : notyf.message.optionUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.optionAddError : notyf.message.optionUpdateError );
            console.error(error); });
    }
}