import { AuthService } from './../services/authentication/auth.service.service';
import { Directive, Input, OnInit, ViewContainerRef, TemplateRef, ElementRef } from '@angular/core';

@Directive({
    selector: '[hasPermission]'
})

// Определение доступа для ролей в различные блоки html
export class HasPermissionDirective implements OnInit {
    private permissions = [];
    private conditions = true;
    private allRoles = [];

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef, private authService: AuthService
    ) {
    }

    ngOnInit(): void {
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val[0];
        if (val && val.length > 1) {
            this.conditions = val[1];
        }
        else {
            this.conditions = true;
        }
        this.updateView();
    }

    private updateView(): void {
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkPermission(): boolean {
        let result: boolean;
        this.allRoles = this.authService.getUserRoles();
        if (this.allRoles) {
            for (const checkPermission of this.permissions) {
                result = this.allRoles.findIndex(x => x.toUpperCase() === checkPermission.toUpperCase()) > -1;
                if (result && this.conditions) {
                    return true;
                }
            }
        }
        return false;
    }
}
