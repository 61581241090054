import { Client, AdminRouteResponse, AdminRouteRequest, AdminRouteHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class RouteSiteSerivce implements Resolve<any>
{
    public routeHelper: AdminRouteHelper;
    route: AdminRouteResponse[];
    onRoutesChanged: BehaviorSubject<any>;
    onRoutesHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onRoutesChanged = new BehaviorSubject({});
        this.onRoutesHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminContactUsRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminRouteRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRoutes(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getRoutes(model: AdminRouteRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminLogistic_Routes(model)
                .subscribe((response: any) => {
                    this.routeHelper = response;
                    this.route = this.routeHelper.routes;
                    this.onRoutesChanged.next(this.route);
                    this.onRoutesHelperChanged.next(this.routeHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getRouteError );
                });
        });
    }

    public routesLength(): number {
        return this.routeHelper && this.routeHelper.paginator ? this.routeHelper.paginator.countTotal : 0;
    }
}
