import { AdminTecDocModelAddOrUpdate } from './../../../../../service/Client';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Type, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Client, AdminTecDocModelResponse, FileParameter } from 'app/service/Client';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-model-item',
    templateUrl: './model-item.component.html',
    styleUrls: ['./model-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ModelItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    model: AdminTecDocModelResponse;

    public EditorFull = ClassicEditorFull;
    //public Editor = ClassicEditor;
    isNew = false;
    isReady = false;
    id = '';

    modelForm: FormGroup;
    description: FormControl;
    fullDescription: FormControl;
    matchCode: FormControl;
    constructionInterval: FormControl;
    id1C: FormControl;
    tecDocId: FormControl;
    tecDocManufacturerId: FormControl;
    isActive: FormControl;

    selectedVehicleId = '';

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initModel();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {

    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initModel(): void {
        this.client.adminTecDocCar_ModelById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.model = data as AdminTecDocModelResponse;
                this.createFormControls();
            }, error => {
                this.adminService.showNotify(notyf.type.error, notyf.message.getModelError);
                console.error(error);
            });
    }

    createFormControls(): void {
        if (this.model) {
            this.description = new FormControl(this.model.description, Validators.required);
            this.fullDescription = new FormControl(this.model.fullDescription, Validators.required);
            this.matchCode = new FormControl(this.model.matchCode, Validators.required);
            this.constructionInterval = new FormControl(this.model.constructionInterval, Validators.required);
            this.id1C = new FormControl(this.model.id1C);
            this.tecDocId = new FormControl(this.model.tecDocId, Validators.required);
            this.tecDocManufacturerId = new FormControl(this.model.tecDocManufacturerId, Validators.required);
            this.isActive = new FormControl(this.model.isActive, Validators.required);

            this.selectedVehicleId = this.model.vehicleId;
        } else {
            this.description = new FormControl(null, Validators.required);
            this.fullDescription = new FormControl(null, Validators.required);
            this.matchCode = new FormControl(null, Validators.required);
            this.constructionInterval = new FormControl(null, Validators.required);
            this.id1C = new FormControl(null);
            this.tecDocId = new FormControl(null, Validators.required);
            this.tecDocManufacturerId = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.modelForm = new FormGroup({
            Description: this.description,
            FullDescription: this.fullDescription,
            MatchCode: this.matchCode,
            ConstructionInterval: this.constructionInterval,
            Id1C: this.id1C,
            TecDocId: this.tecDocId,
            TecDocManufacturerId: this.tecDocManufacturerId,
            IsActive: this.isActive,

        });
        this.isReady = true;
    }

    toPost(): void {
        if (this.modelForm.valid) {
            const model: AdminTecDocModelAddOrUpdate = {
                description: this.modelForm.value.Description,
                fullDescription: this.modelForm.value.FullDescription,
                matchCode: this.modelForm.value.MatchCode,
                constructionInterval: this.modelForm.value.ConstructionInterval,
                id: this.id,
                id1C: this.modelForm.value.Id1C,
                tecDocId: this.modelForm.value.TecDocId,
                tecDocManufacturerId: this.modelForm.value.TecDocManufacturerId,
                isActive: this.modelForm.value.IsActive,
                vehicleId: this.selectedVehicleId
            };

            this.client.adminTecDocCar_UpdateModel(model)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (this.adminService.helperAll) {
                        const index = this.adminService.helperAll.models.findIndex(x => x.id === data.id);
                        if (index > -1) {
                            this.adminService.helperAll.models[index] = data;
                            this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
                        }
                    }
                    this.router.navigate(['/admin/tecdoc/cars/models']);
                    this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.modelAddCompleate : notyf.message.modelUpdateCompleate);
                }, error => {
                    this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.modelAddError : notyf.message.modelUpdateError);
                    console.error(error);
                });
        }
    }
}
