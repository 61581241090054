import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminDownloadInfoResponse, FileParameter, Client, ArticleResponse, DownloadInfoResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-downloadinfo-site-category',
    templateUrl: './downloadinfo-site-category.component.html',
    styleUrls: ['./downloadinfo-site-category.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DownloadinfoSiteCategoryComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    downloadInfo: AdminDownloadInfoResponse;

    stringKey = '';
    id = null;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    selectedFile: FileParameter;

    downloadInfoForm: FormGroup;
    title: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.stringKey) {
                    this.isNew = false;
                    this.stringKey = params.stringKey;
                    this.initDownloadInfo();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initDownloadInfo(): void {
        this.client.adminDownloadInfo_DownloadInfoStringKey(this.stringKey)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.downloadInfo = data as DownloadInfoResponse;
                this.id = this.downloadInfo.id;
                this.downloadInfo.image = environment.apiUrlProd + environment.downloadInfo + this.downloadInfo.id + '/' + this.downloadInfo.image;
                console.log(this.downloadInfo);

                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.downloadInfo) {
            this.title = new FormControl(this.downloadInfo.title, Validators.required);
            this.isActive = new FormControl(this.downloadInfo.isActive, Validators.required);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.downloadInfoForm = new FormGroup({
            Title: this.title,
            IsActive: this.isActive
        });
        this.isReady = true;
    }

    toPost(): void {
        this.client.adminDownloadInfo_DownloadInfoUpdate(
            this.id,
            this.downloadInfoForm.value.Title,
            this.selectedFile,
            this.downloadInfoForm.value.IsActive
        ).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                console.log(response);
                this.router.navigate(['/admin/downloadinfo']); 
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.downloadInfoAddCompleate : notyf.message.downloadInfoUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.downloadInfoAddError : notyf.message.downloadInfoUpdateError );
                console.error(error); });
    }
    
    fileChanged(event: any) {
        this.selectedFile = event;
        }

}

