import { NOTYF } from './../models/notyf.token';
import { MatPaginator } from '@angular/material';
import {
    AdminBadgeResponse,
    AdminChatInfoRequest,
    AdminUserProfileResponse,
    AdminOptionResponse,
    AdminCategoryParentHelper,
    AdminBrandResponse,
    AdminMainHelper,
    AdminUnitResponse
} from './../../service/Client';
import { FuseNavigationItem, FuseNavigation } from '@fuse/types';
import { Client, AdminPageFilterRequest } from 'app/service/Client';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Inject } from '@angular/core';
import { Notyf } from 'notyf';
import { notyf } from '../models/notyf';

@Injectable()
export class AdminBaseService {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    public badgeCountStatusSource = new BehaviorSubject<boolean>(false);
    badgeCountStatus$ = this.badgeCountStatusSource.asObservable();

    public badgeCountChangeStatusSource = new BehaviorSubject<boolean>(false);
    badgeCountChangeStatus$ = this.badgeCountChangeStatusSource.asObservable();

    public optionValueChangeStatusSource = new BehaviorSubject<string>(null);
    optionValueChangeStatus$ = this.optionValueChangeStatusSource.asObservable();

    public helperAllChangeStatusSource = new BehaviorSubject<AdminMainHelper>(null);
    helperAllChangeStatus$ = this.helperAllChangeStatusSource.asObservable();

    adminBadge: AdminBadgeResponse;

    adminProfile: AdminUserProfileResponse;

    resetPage = false;

    helperAll: AdminMainHelper;

    userBadgeIndex = 1;

    private timeOut = 5000;
    private closeButton = true;
    private progressBar = true;
    private positionClass = 'toast-top-right';
    private preventDuplicates = true;
    private config = {
        timeOut: this.timeOut,
        closeButton: this.closeButton,
        progressBar: this.progressBar,
        positionClass: this.positionClass,
        preventDuplicates: this.preventDuplicates
    };

    constructor(
        private client: Client,
        private toastr: ToastrService,
        @Inject(NOTYF) private notyf: Notyf
    ) {
    }

    defaultAdminBadge(): AdminBadgeResponse {
        const model: AdminBadgeResponse = {
            mailing: 0,
            contactUs: 0,
            sto: 0,
            order: 0
        };
        return model;
    }

    inotHelperAll(): void {
        this.client.adminSetting_HelperAllFull()
            .subscribe(data => {
                this.helperAll = data as AdminMainHelper;
                this.helperAllChangeStatusSource.next(this.helperAll);
            }, error => { 
                this.showNotify(notyf.type.error, notyf.message.getHelperAllError );
                console.error(error); });
    }

    getDefaultPageFilter(column: string): AdminPageFilterRequest {
        const model: AdminPageFilterRequest = {
            page: 1,
            take: 20,
            query: '',
            orderByColumn: column,
            orderBy: ''
        };
        return model;
    }

    getPageFilter(page: number, take: number, query: string, orderByColumn: string, orderBy: string): AdminPageFilterRequest {
        const model: AdminPageFilterRequest = {
            page: page,
            take: take,
            query: query,
            orderByColumn: orderByColumn,
            orderBy: orderBy
        };
        return model;
    }

    comparePageFilter(oldT: AdminPageFilterRequest, newT: AdminPageFilterRequest): boolean {
        if (oldT.page === newT.page &&
            oldT.take === newT.take &&
            oldT.query === newT.query &&
            oldT.orderByColumn === newT.orderByColumn &&
            oldT.orderBy === newT.orderBy) {
            return true;
        }
        return false;
    }

    createBadge(count: number): any {
        const badge = {
            title: String(count),
            count: count,
            translate: '',
            bg: '#f44336',
            fg: '#FFFFFF'
        };
        return badge;
    }

    updateNavigation(navigations: FuseNavigation[], id: string, count: number): FuseNavigation[] {
        const index = navigations[this.userBadgeIndex].children.findIndex(x => x.id === id);
        if (index > -1) {
            navigations[this.userBadgeIndex].children[index].badge = this.createBadge(count);
        }
        return navigations;
    }

    updateNavigations(): void {
        const model = this.client.adminSetting_BadgeCount()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.adminBadge = data as AdminBadgeResponse;
                this.badgeCountStatusSource.next(true);
            }, error => { console.error(error); });
    }

    updateNavgations(navigations: FuseNavigation[]): FuseNavigation[] {
        if (!this.adminBadge) {
            this.adminBadge = this.defaultAdminBadge();
        }
        navigations = this.updateNavigation(navigations, 'chat', this.adminBadge.mailing);
        navigations = this.updateNavigation(navigations, 'contactus', this.adminBadge.contactUs);
        navigations = this.updateNavigation(navigations, 'sto', this.adminBadge.sto);
        navigations = this.updateNavigation(navigations, 'orders', this.adminBadge.order);

        return navigations;
    }

    getUserChatInfo(id: string, skip: number, take: number): AdminChatInfoRequest {

        const model: AdminChatInfoRequest = {
            userId: id,
            take: take,
            skip: skip
        };
        return model;
    }

    getDefaultPageFilterViaSearch(pageFilter: AdminPageFilterRequest, paginator: MatPaginator): [AdminPageFilterRequest, MatPaginator] {
        pageFilter.page = 1;
        paginator._pageIndex = 0;
        const result: [AdminPageFilterRequest, MatPaginator] = [pageFilter, paginator];
        this.resetPage = false;
        return result;
    }

    showNotify(type: string, message: string): void {
        this.notyf.open({
            type: type,
            message: message
        });
    }

}
