import { takeUntil, debounceTime, distinctUntilChanged, map, startWith, switchMap, catchError } from 'rxjs/operators';
import { query } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, BehaviorSubject } from 'rxjs';
import { merge, Observable, of as observableOf } from 'rxjs';
import { Client, AdminPageFilterRequest, AdminContactUsRequest } from 'app/service/Client';
import { MatPaginator, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { constants } from 'fs';
import { DownloadInfoFileService } from './downloadinfo-files.service';
import { saveAs } from 'file-saver';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-downloadinfo-files-site',
  templateUrl: './downloadinfo-files-site.component.html',
  styleUrls: ['./downloadinfo-files-site.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class DownloadinfoFileSiteComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  dataSource: FilesDataSource | null;

  displayedColumns: string[] = ['image', 'nameinfo', 'file', 'created', 'updated', 'active'];

  noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('filter')
  filter: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
      private client: Client,
      private downloadInfoFileSerivce: DownloadInfoFileService,
      private adminBaseService: AdminBaseService
  )
  {
      // Set the private defaults
      this._unsubscribeAll = new Subject();
      this.adminBaseService.resetPage = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
      this.dataSource = new FilesDataSource(this.downloadInfoFileSerivce, this.paginator, this.sort, this.adminBaseService);

      fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(150),
              distinctUntilChanged()
          )
          .subscribe(() => {
              if ( !this.dataSource )
              {
                  return;
              }
              this.adminBaseService.resetPage = true;  
              this.dataSource.filter = this.filter.nativeElement.value;
          });
  }

  private async saveToFileSystem(link: string) {
      const fileName = link.substring(link.lastIndexOf('/')+1);
    await saveAs(link, fileName);
  }
}

export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  pageFilter: AdminPageFilterRequest;

  constructor(
      private downloadInfoFileSerivce: DownloadInfoFileService,
      private _matPaginator: MatPaginator,
      private _matSort: MatSort,
      private _adminBaseService: AdminBaseService
  )
  {
      super();

      this.filteredData = this.downloadInfoFileSerivce.downloadInfoItem;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
      const displayDataChanges = [
          this.downloadInfoFileSerivce.onDownloadInfoItemChanged,
          this._matPaginator.page,
          this._filterChange,
          this._matSort.sortChange
      ];

      return merge(...displayDataChanges)
          .pipe(
              map(() => {
                  if (!this.pageFilter) {
                      this.pageFilter = this._adminBaseService.getDefaultPageFilter(this._matSort.direction);
                  }
                  const newPageFilter = this._adminBaseService.getPageFilter(
                      this._matPaginator.pageIndex + 1, 
                      this._matPaginator.pageSize, 
                      this._filterChange.value, 
                      this._matSort.active, 
                      this._matSort.direction
                  );

                  if (!this._adminBaseService.comparePageFilter(this.pageFilter, newPageFilter)) {
                      const model: AdminContactUsRequest = {
                          pageFilter: newPageFilter,
                          type: 4,
                      };
                      this.pageFilter = newPageFilter;
                      if (this._adminBaseService.resetPage) {
                        const result = this._adminBaseService.getDefaultPageFilterViaSearch(model.pageFilter, this._matPaginator);
                        model.pageFilter = result[0];
                        this._matPaginator = result[1];
                    }
                      this.downloadInfoFileSerivce.getDownloadInfoItem(model);
                  }                   
                      let data = this.downloadInfoFileSerivce.downloadInfoItem;

                      if (this.downloadInfoFileSerivce.downloadInfoItemHelper.paginator) {
                          this._matPaginator._length = this.downloadInfoFileSerivce.downloadInfoItemHelper.paginator.countTotal;
                      }
                      if (data) {
                          data = data.slice();
                          this.filteredData = [...data];
                      } else {
                          this.filteredData = [];
                      }
                      console.log(data);
                      
                      return data;
                  }
              ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any
  {
      return this._filteredDataChange.value;
  }

  get paginatorLength(): number {
      return this.downloadInfoFileSerivce.downloadInfoItemHelper.paginator ? this.downloadInfoFileSerivce.downloadInfoItemHelper.paginator.countTotal : 0;
  }

  set filteredData(value: any)
  {
      this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string
  {
      return this._filterChange.value;
  }

  set filter(filter: string)
  {
      this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any
  {
      if ( !this.filter )
      {
          return data;
      }
      return FuseUtils.filterArrayByString(data, this.filter);
  }  

  /**
   * Disconnect
   */
  disconnect(): void
  {
  }
}
