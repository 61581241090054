import { AdminTecDocModificationAddOrUpdate, Route } from './../../../../../service/Client';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Type, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Client, AdminTecDocModificationResponse, FileParameter } from 'app/service/Client';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';
import { RouterEffects } from 'app/store';

@Component({
    selector: 'app-modification-item',
    templateUrl: './modification-item.component.html',
    styleUrls: ['./modification-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ModificationItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    modification: AdminTecDocModificationResponse;

    public EditorFull = ClassicEditorFull;
    //public Editor = ClassicEditor;
    isNew = false;
    isReady = false;
    id = '';

    modificationForm: FormGroup;
    name: FormControl;
    fullName: FormControl;
    interval: FormControl;
    engineCodes: FormControl;
    powerKw: FormControl;
    powerPs: FormControl;
    capacityCcm: FormControl;
    bodyType: FormControl;
    fuelType: FormControl;
    matchCode: FormControl;
    id1C: FormControl;
    tecDocId: FormControl;
    tecDocModelCarId: FormControl;
    isActive: FormControl;

    selectedModelId = '';

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initModification();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {

    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initModification(): void {
        this.client.adminTecDocCar_ModificationById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.modification = data as AdminTecDocModificationResponse;
                this.createFormControls();
            }, error => {
                this.adminService.showNotify(notyf.type.error, notyf.message.getModificationError);
                console.error(error);
            });
    }

    createFormControls(): void {
        if (this.modification) {
            this.name = new FormControl(this.modification.name, Validators.required);
            this.fullName = new FormControl(this.modification.fullName, Validators.required);
            this.interval = new FormControl(this.modification.interval, Validators.required);
            this.engineCodes = new FormControl(this.modification.engineCodes);
            this.powerKw = new FormControl(this.modification.powerKw);
            this.powerPs = new FormControl(this.modification.powerPs);
            this.capacityCcm = new FormControl(this.modification.capacityCcm);
            this.bodyType = new FormControl(this.modification.bodyType);
            this.fuelType = new FormControl(this.modification.fuelType);
            this.matchCode = new FormControl(this.modification.matchCode);
            this.id1C = new FormControl(this.modification.id1C);
            this.tecDocId = new FormControl(this.modification.tecDocId, Validators.required);
            this.tecDocModelCarId = new FormControl(this.modification.tecDocModelCarId, Validators.required);
            this.isActive = new FormControl(this.modification.isActive, Validators.required);

            this.selectedModelId = this.modification.modelVehicleId;
        } else {
            this.name = new FormControl(null, Validators.required);
            this.fullName = new FormControl(null, Validators.required);
            this.interval = new FormControl(null, Validators.required);
            this.engineCodes = new FormControl(null);
            this.powerKw = new FormControl(null);
            this.powerPs = new FormControl(null);
            this.capacityCcm = new FormControl(null);
            this.bodyType = new FormControl(null);
            this.fuelType = new FormControl(null);
            this.matchCode = new FormControl(null);
            this.id1C = new FormControl(null);
            this.tecDocId = new FormControl(null, Validators.required);
            this.tecDocModelCarId = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.modificationForm = new FormGroup({
            Name: this.name,
            FullName: this.fullName,
            Interval: this.interval,
            EngineCodes: this.engineCodes,
            PowerKw: this.powerKw,
            PowerPs: this.powerPs,
            CapacityCcm: this.capacityCcm,
            BodyType: this.bodyType,
            FuelType: this.fuelType,
            MatchCode: this.matchCode,
            Id1C: this.id1C,
            TecDocId: this.tecDocId,
            TecDocModelCarId: this.tecDocModelCarId,
            IsActive: this.isActive,
        });
        this.isReady = true;
    }

    toPost(): void {
        if (this.modificationForm.valid) {
            const modification: AdminTecDocModificationAddOrUpdate = {
                name: this.modificationForm.value.Name,
                fullName: this.modificationForm.value.FullName,
                interval: this.modificationForm.value.Interval,
                engineCodes: this.modificationForm.value.EngineCodes,
                powerKw: this.modificationForm.value.PowerKw,
                powerPs: this.modificationForm.value.PowerPs,
                capacityCcm: this.modificationForm.value.CapacityCcm,
                bodyType: this.modificationForm.value.BodyType,
                fuelType: this.modificationForm.value.FuelType,
                matchCode: this.modificationForm.value.MatchCode,
                id: this.id,
                id1C: this.modificationForm.value.Id1C,
                tecDocId: this.modificationForm.value.TecDocId,
                tecDocModelCarId: this.modificationForm.value.TecDocModelCarId,
                isActive: this.modificationForm.value.IsActive,
                modelVehicleId: this.selectedModelId
            };

            this.client.adminTecDocCar_UpdateModification(modification)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (this.adminService.helperAll && this.adminService.helperAll.modifications && this.adminService.helperAll.modifications.length > 0) {
                        const index = this.adminService.helperAll.modifications.findIndex(x => x.id === data.id);
                        if (index > -1) {
                            this.adminService.helperAll.modifications[index] = data;
                            this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
                        }
                    }
                    this.router.navigate(['/admin/tecdoc/cars/modifications']);
                    this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.modificationAddCompleate : notyf.message.modificationUpdateCompleate);
                }, error => {
                    this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.modificationAddError : notyf.message.modificationUpdateError);
                    console.error(error);
                });
        }
    }
}
