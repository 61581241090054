import { AdminUserProfileResponse } from 'app/service/Client';
import { AdminBaseService } from './../../../../../services/admin.base.service';
import { AdminShortChatResponse, AdminShortChatHelper, AdminShortChatRequest } from './../../../../../../service/Client';
import { ChatSiteService } from './../../../chat-site.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, timer } from 'rxjs';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import { MediaObserver } from '@angular/flex-layout';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-chats-site',
    templateUrl: './chats-site.component.html',
    styleUrls: ['./chats-site.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ChatsSiteComponent implements OnInit, OnDestroy {
    chats: any[];
    chatSearch: any;
    contacts: any[];
    searchText: string;
    user: any;

    @ViewChild('filter')
    filter: ElementRef;

    timerStopped = true;
    timemerSubscribe: any;

    query = '';

    showMore = true;
    emptyUser = false;

    shortChat: AdminShortChatHelper;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _chatService: ChatSiteService,
        private _fuseMatSidenavHelperService: FuseMatSidenavHelperService,
        public _mediaObserver: MediaObserver,
        private adminService: AdminBaseService
    ) {
        // Set the defaults
        this.chatSearch = {
            name: ''
        };
        this.searchText = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const model: AdminShortChatRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        this._chatService.getShortChat(model);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.query = this.filter.nativeElement.value;
                const model: AdminShortChatRequest = {
                    pageFilter: this.adminService.getPageFilter(1, 20, this.query, '', '')
                };
                this.shortChat = null;
                this._chatService.getShortChat(model);
            });


        this.user = this._chatService.user;
        this.chats = this._chatService.chats;
        this.contacts = this._chatService.contacts;

        this._chatService.onShortChatUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedShortChats => {
                console.log(updatedShortChats);

                if (updatedShortChats) {
                    if (this.shortChat) {
                        this.shortChat.users = this.shortChat.users.concat(updatedShortChats.users);
                        this.shortChat.paginator = updatedShortChats.paginator;
                        this.shortChat.isEmpty = updatedShortChats.isEmpty;
                    } else {
                        this.shortChat = updatedShortChats;
                        this.emptyUser = this.shortChat.isEmpty;
                    }
                    this.showMore = !updatedShortChats.isEmpty;
                }
            });

        this._chatService.onNewMessage
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                if (data && this.shortChat && data[1]) {
                    const index = this.shortChat.users.findIndex(x => x.userId === data[1]);
                    if (index > -1) {
                        this.shortChat.users[index].date = data[0].created;
                        this.shortChat.users[index].lastMessage = data[0].message;
                    }
                }
            });

        this.initTimer();

        this._chatService.onChatsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedChats => {
                this.chats = updatedChats;
            });

        this._chatService.onUserUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedUser => {
                this.user = updatedUser;
            });
    }

    uploadMoreShortChat(): void {
        const model: AdminShortChatRequest = {
            pageFilter: this.adminService.getPageFilter(this.shortChat.paginator.page + 1, 20, this.query, '', '')
        };
        this._chatService.getShortChat(model);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.stopTimer();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    initTimer() {
        if (this.timerStopped === false) { return; }
        this.timerStopped = false;
        this.timemerSubscribe = timer(0, 10000)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(t => {
                if (this.shortChat) {
                    const ids: string[] = [];
                    this.shortChat.users.map(x => ids.push(x.userId));
                    this._chatService.updateLeftChats(ids)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(data => {
                            const model = data as AdminShortChatResponse[];
                            model.map(x => {
                                const index = this.shortChat.users.findIndex(y => y.userId === x.userId);
                                if (index > -1) {
                                    this.shortChat.users[index].lastMessage = x.lastMessage;
                                    this.shortChat.users[index].count = x.count;
                                    this.shortChat.users[index].date = x.date;
                                    this.shortChat.users.sort((n2, n1) => {
                                        if (n1.date > n2.date) {
                                            return 1;
                                        }

                                        if (n1.date < n2.date) {
                                            return -1;
                                        }

                                        return 0;
                                    });
                                }
                            });
                        }, error => { console.error(error); });
                }
            });
    }

    stopTimer() {
        if (this.timerStopped === false) {
            this.timemerSubscribe.unsubscribe();
            this.timerStopped = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chat
     *
     * @param contact
     */
    getChat(contact): void {
        this._chatService.getChat(contact);
        this.adminService.updateNavigations();
        if (!this._mediaObserver.isActive('gt-md')) {
            this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
        }
    }

    getChatInfo(userId: string): void {
        const model = this.adminService.getUserChatInfo(userId, 0, 20);
        const index = this.shortChat.users.findIndex(x => x.userId === userId);
        if (index > -1) {
            this.shortChat.users[index].count = 0;
        }
        this._chatService.getUserChatInfo(model);
        if (!this._mediaObserver.isActive('gt-md')) {
            this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
        }
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        this._chatService.setUserStatus(status);
    }

    /**
     * Change left sidenav view
     *
     * @param view
     */
    changeLeftSidenavView(view): void {
        this._chatService.onLeftSidenavViewChanged.next(view);
    }

    /**
     * Logout
     */
    logout(): void {
        console.log('logout triggered');
    }
}
