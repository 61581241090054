import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminPriceLevelResponse, AdminBrandResponse, AdminPriceLevelBrandRequest, AdminPriceLevelAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ArticleResponse, FileParameter, Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-price-level-item',
  templateUrl: './price-level-item.component.html',
  styleUrls: ['./price-level-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
  
})
export class PriceLevelItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    pricelevel: AdminPriceLevelResponse;

    id = 0;

    isNew = false;
    isReady = false;

    brandAll: AdminBrandResponse[] = [];
    brandAllDisplayed: AdminBrandResponse[] = [];
    brandSearch = '';
    selectedBrandId = null;

    brandArray: FormArray;

    pricelevelForm: FormGroup;
    id1c: FormControl;
    title: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) {
        this.route.params
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(params => {
            console.log(params);
            if (params.id) {
                this.isNew = false;
                this.id = params.id;
                this.initPriceLevel();
            } else {
                this.isNew = true;
                this.createFormControls();
            }
        });
    }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (data) {
                this.brandAll = data.brands;
                this.brandAllDisplayed = data.brands;
            }
        }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
    
    initPriceLevel(): void {
        this.client.adminPriceLevel_PriceLevelById(this.id)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.pricelevel = data as AdminPriceLevelResponse;
            console.log(this.pricelevel);            
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getPriceLevelError );
            console.error(error); });
    }

    createFormControls(): void {
        this.brandArray = new FormArray([]);     
        if (this.pricelevel) {
            this.id1c = new FormControl(this.pricelevel.id1C, Validators.required);
            this.title = new FormControl(this.pricelevel.title, Validators.required);

            this.pricelevel.priceLevelBrands.map(x => this.brandArray.push(this.fb.group({ 
                brandId: new FormControl(x.brandId),
                brandTitle: new FormControl(x.brand.name),
                discount: new FormControl(x.discount),
                id: new FormControl(x.id)
             })));
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.title = new FormControl(null, Validators.required);
        }
        console.log(this.brandArray);
        this.pricelevelForm = new FormGroup({
            Id1C: this.id1c,
            Title: this.title
        });
        this.isReady = true;
    }
    addPriceLevelBrand(): void {
        const index = this.brandAll.findIndex(x => x.id === this.selectedBrandId);
        const existIndex = this.brandArray.value.findIndex(x => x.brandId === this.selectedBrandId);
        if (index === -1 || existIndex > -1) {
            return;
        }
        this.brandArray.push(this.fb.group({ 
            brandId: new FormControl(this.selectedBrandId),
            brandTitle: new FormControl(this.brandAll[index].name),
            discount: new FormControl(null),
            id: new FormControl(null)
         }));
    }

    searchBrand(): void {
        if (!this.brandSearch) {
            this.brandAllDisplayed = this.brandAll;
            return;
        }
        this.brandAllDisplayed = this.brandAll.filter(x => x.name.toLocaleLowerCase().includes(this.brandSearch.toLocaleLowerCase()));
    }

    removePriceLevelBrand(index: number): void {
        this.brandArray.controls.splice(index, 1);
        this.brandArray.value.splice(index, 1);
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        const brandModel: AdminPriceLevelBrandRequest[] = [];

        for(let i = 0; i < this.brandArray.value.length; i++) {
            let prom: AdminPriceLevelBrandRequest = {
                brandId: this.brandArray.value[i].brandId,
                discount: this.brandArray.value[i].discount
            };
            brandModel.push(prom);
        }

        const mainModel: AdminPriceLevelAddOrUpdate = {
            id: this.id,
            id1C: this.pricelevelForm.value.Id1C,
            title: this.pricelevelForm.value.Title,
            priceLevelBrands: brandModel
        };

        this.client.adminPriceLevel_PriceLevelAddOrUpdate(mainModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            console.log(data);
            this.router.navigate(['/admin/pricelevels']); 
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.priceLevelAddCompleate : notyf.message.priceLevelUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.priceLevelAddError : notyf.message.priceLevelUpdateError );
            console.error(error); });
    }
}
