import { FileParameter } from 'app/service/Client';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-file-select',
    templateUrl: './file-select.component.html',
    styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent implements OnInit {
    @Input() title: string;
    @Output() fileChange = new EventEmitter<FileParameter>();
    @Input() fileTitle: string;
    @ViewChild('photoInput') input: ElementRef;

    isFile = false;
    selectedFile: any;

    constructor() {}

    ngOnInit() {
        if(this.fileTitle === "" || this.fileTitle === null || !this.fileTitle) { this.fileTitle = "Файл не выбран" }
        this.selectedFile = null;
    }

    onFileChange(event: any) {
        let fi = event.srcElement;
        if (fi.files && fi.files[0]) {
            this.fileTitle = fi.files[0].name;
            this.isFile = true;
            const model: FileParameter = {
                data: fi.files[0],
                fileName: fi.files[0].name
            };
            this.selectedFile = model;
            this.fileChange.emit(this.selectedFile);
        }
    }

    removeFile(): void {
        this.isFile = false;
        this.fileTitle = 'Файл не выбран';
        this.selectedFile = null;
        this.fileChange.emit(this.selectedFile);
        this.input.nativeElement.value = '';
    }     
}