import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminRouteAddOrUpdate, AdminRouteResponse } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ArticleResponse, FileParameter, Client, AdminStoreResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-route-item',
    templateUrl: './route-item.component.html',
    styleUrls: ['./route-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None

})
export class RouteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    routeDb: AdminRouteResponse;

    id = 0;

    isNew = false;
    isReady = false;

    daysOfWeek = environment.daysOfWeek;

    storeAll: AdminStoreResponse[] = [];
    storeAllDisplayed: AdminStoreResponse[] = [];
    storeSearch = '';
    selectedStoreId = null;

    hours: number[] = [];
    hourSelected = 0;
    minutes: number[] = [];
    minuteSelected = 0;

    routeForm: FormGroup;
    id1c: FormControl;
    title: FormControl;
    isActive: FormControl;
    remainingMinutes: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initRoute();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
        this.initTime();
        this.adminService.helperAllChangeStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                if (data) {
                    this.storeAll = data.stores;
                    this.storeAllDisplayed = this.storeAll;
                }
            }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initTime(): void {
        this.hours = Array.apply(null, { length: 24 }).map(Number.call, Number);
        this.minutes = Array.apply(null, { length: 60 }).map(Number.call, Number);
    }

    initRoute(): void {
        this.client.adminLogistic_RouteById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.routeDb = data as AdminRouteResponse;
                console.log(this.routeDb);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getRouteError );
                console.error(error); });
    }

    initDaysOfWeek(): void {
        this.daysOfWeek.map(x => {
            x.isCheck = this.routeDb.days[x.index] === '1';
        });
        let date = new Date(this.routeDb.time);
        this.hourSelected = date.getHours();
        this.minuteSelected = date.getMinutes();
    }

    createFormControls(): void {
        if (this.routeDb) {
            this.id1c = new FormControl(this.routeDb.id1C, Validators.required);
            this.title = new FormControl(this.routeDb.title, Validators.required);
            this.isActive = new FormControl(this.routeDb.isActive, Validators.required);
            this.remainingMinutes = new FormControl(this.routeDb.remainingMinutes, Validators.required);
            this.selectedStoreId = this.routeDb.storeId;
            this.initDaysOfWeek();
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.title = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.remainingMinutes = new FormControl(null, Validators.required);
        }
        this.routeForm = new FormGroup({
            Id1C: this.id1c,
            Title: this.title,
            IsActive: this.isActive,
            RemainingMinutes: this.remainingMinutes
        });
        this.isReady = true;
    }

    searchStore(): void {
        if (!this.storeSearch) {
            this.storeAllDisplayed = this.storeAll;
            return;
        }
        this.storeAllDisplayed = this.storeAll.filter(x => x.name.toLocaleLowerCase().includes(this.storeSearch.toLocaleLowerCase()));
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    initRequestTime(): Date {
        const date = this.isNew ? new Date() : new Date(this.routeDb.time);
        date.setUTCHours(this.hourSelected);
        date.setUTCMinutes(this.minuteSelected);
        return date;
    }

    initRequestDays(): string {
        let result = '';
        this.daysOfWeek.map(x => result += x.isCheck ? '1' : '0');
        return result;
    }

    toPost(): void {
        const model: AdminRouteAddOrUpdate = {
            id: this.id,
            id1C: this.routeForm.value.Id1C,
            isActive: this.routeForm.value.IsActive,
            days: this.initRequestDays(),
            title: this.routeForm.value.Title,
            time: this.initRequestTime(),
            remainingMinutes: this.routeForm.value.RemainingMinutes,
            storeId: this.selectedStoreId
        };
        console.log(model);

        this.client.adminLogistic_RouteAddOrUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            console.log(data);
            if (this.isNew) {
                this.adminService.helperAll.routes.push(data);
                this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
            }  
            this.router.navigate(['/admin/routes']); 
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.routeAddCompleate : notyf.message.routeUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.routeAddError : notyf.message.routeUpdateError );
            console.error(error); });
    }
}
