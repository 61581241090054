import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { MatPaginator, MatSort } from '@angular/material';
import { Client, AdminPageFilterRequest, AdminContactUsRequest } from 'app/service/Client';
import { ModelCarService } from './model-car.service';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'app-model-car-site',
  templateUrl: './model-car-site.component.html',
  styleUrls: ['./model-car-site.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ModelCarSiteComponent implements OnInit {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    dataSource: FilesDataSource | null;

    displayedColumns: string[] = ['id1с', 'mark', 'name', 'tecdocid', 'created', 'updated', 'active'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild('filter')
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private client: Client,
        private modelSiteSerivce: ModelCarService,
        private adminBaseService: AdminBaseService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.adminBaseService.resetPage = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this.modelSiteSerivce, this.paginator, this.sort, this.adminBaseService);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }
                this.adminBaseService.resetPage = true;     
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    pageFilter: AdminPageFilterRequest;

    constructor(
        private modelSiteSerivce: ModelCarService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
        private _adminBaseService: AdminBaseService
    )
    {
        super();

        this.filteredData = this.modelSiteSerivce.models;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this.modelSiteSerivce.onCategoryChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    if (!this.pageFilter) {
                        this.pageFilter = this._adminBaseService.getDefaultPageFilter(this._matSort.direction);
                    }
                    const newPageFilter = this._adminBaseService.getPageFilter(
                        this._matPaginator.pageIndex + 1, 
                        this._matPaginator.pageSize, 
                        this._filterChange.value, 
                        this._matSort.active, 
                        this._matSort.direction
                    );

                    if (!this._adminBaseService.comparePageFilter(this.pageFilter, newPageFilter)) {
                        const model: AdminContactUsRequest = {
                            pageFilter: newPageFilter,
                            type: 4,
                        };
                        this.pageFilter = newPageFilter;
                        if (this._adminBaseService.resetPage) {
                            const result = this._adminBaseService.getDefaultPageFilterViaSearch(model.pageFilter, this._matPaginator);
                            model.pageFilter = result[0];
                            this._matPaginator = result[1];
                        }
                        this.modelSiteSerivce.getModels(model);
                    }                   
                        let data = this.modelSiteSerivce.models;

                        if (this.modelSiteSerivce.modelsHelper.paginator) {
                            this._matPaginator._length = this.modelSiteSerivce.modelsHelper.paginator.countTotal;
                        }
                        if (data) {
                            data = data.slice();
                            this.filteredData = [...data];
                        } else {
                            this.filteredData = [];
                        }
                        return data;
                    }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    get paginatorLength(): number {
        return this.modelSiteSerivce.modelsHelper.paginator ? this.modelSiteSerivce.modelsHelper.paginator.countTotal : 0;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }  

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
