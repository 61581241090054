import { AdminBaseService } from './../../services/admin.base.service';
import { Client, SettingsResponse, SettingResponse, SocialNetworkRequest } from './../../../service/Client';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-setting-site',
  templateUrl: './setting-site.component.html',
  styleUrls: ['./setting-site.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SettingSiteComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    adminSettings: SettingsResponse;

    hidePass = true;

  constructor(
      private client: Client,
      private adminService: AdminBaseService
  ) { }

  ngOnInit(): void {
      this.initSettings();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initSettings(): void{
      this.client.adminSetting_AdminSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.adminSettings = data as SettingsResponse;
        console.log(this.adminSettings);
    }, error => { 
        this.adminService.showNotify(notyf.type.error, notyf.message.saveUpdateError );
        console.error(error); });
  }

  postSettings(): void {
      const model: SettingResponse[] = [];
      model.push(this.adminSettings.copyright);
      model.push(this.adminSettings.eCash);
      model.push(this.adminSettings.email);
      model.push(this.adminSettings.emailPass);
      model.push(this.adminSettings.footerInfo);
      model.push(this.adminSettings.smsActive);
      model.push(this.adminSettings.tecDocActivity);
      model.push(this.adminSettings.tireCalculator);
      model.push(this.adminSettings.aplBrand);
      model.push(this.adminSettings.aplModel);
      model.push(this.adminSettings.emailActive);
      this.client.adminSetting_ChangeSettings(model).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.adminService.showNotify(notyf.type.success, notyf.message.settingUpdateCompleate );
    }, error => { 
        this.adminService.showNotify(notyf.type.error, notyf.message.settingUpdateError );
        console.error(error); });
  }

  postSocials(): void {
      const model = this.adminSettings.socials as SocialNetworkRequest[];
      this.client.adminSetting_ChangeSocials(model).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        console.log(this.adminSettings);
        this.adminService.showNotify(notyf.type.success, notyf.message.settingUpdateCompleate );
    }, error => { 
        this.adminService.showNotify(notyf.type.error, notyf.message.settingUpdateError );
        console.error(error); });
  }
}
