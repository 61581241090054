import { AdminUserProfileResponse } from 'app/service/Client';
import { ChatSiteService } from './../../../chat-site.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatService } from 'app/main/apps/chat/chat.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-contact-site',
  templateUrl: './contact-site.component.html',
  styleUrls: ['./contact-site.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChatContactSiteComponent implements OnInit, OnDestroy
{
    contact: any;

    user: AdminUserProfileResponse;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _chatService: ChatSiteService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._chatService.onContactSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contact => {
                this.contact = contact;
            });

        this._chatService.onChatInfoSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatInfo => {
                if (chatInfo && chatInfo.user) {
                    this.user = chatInfo.user;
                   
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


