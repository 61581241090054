import { AdminBaseService } from './../../../services/admin.base.service';
import { AdminCountryResponse, AdminPriceLevelResponse, AdminSectorResponse, AdminUserAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminUserResponse, FileParameter, Client, ArticleResponse, AdminCityResponse, AdminStoreResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-user-item',
    templateUrl: './user-item.component.html',
    styleUrls: ['./user-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UserItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    user: AdminUserResponse;

    id = '';

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    userForm: FormGroup;
    id1c: FormControl;
    email: FormControl;
    firstName: FormControl;
    lastName: FormControl;
    middleName: FormControl;
    mainRole: FormControl;
    userUtc: FormControl;
    oldLogin1C: FormControl;
    balance: FormControl;
    phone: FormControl;
    isAccountingPermission: FormControl;

    addressArray: FormArray;
    sectorArray: FormArray;
    storeArray: FormArray;

    storeAll: AdminStoreResponse[] = [];
    storeAllDisplayed: AdminStoreResponse[] = [];
    storeSearch = null;
    selectedSectorId = 0;

    sectorAll: AdminSectorResponse[] = [];
    sectorAllDisplayed: AdminSectorResponse[] = [];
    sectorSearch = null;
    selectedStoreId = 0;

    selectedUserCurrency = 0;
    selectedUserType = 1;

    countryAll: AdminCountryResponse[] = [];
    countryAllDisplayed: AdminCountryResponse[] = [];
    citySearch = null;
    selectedCityId = 0;

    priceLevelAll: AdminPriceLevelResponse[] = [];
    priceLevelAllDisplayed: AdminPriceLevelResponse[] = [];
    priceLevelSearch = null;
    selectedPriceLevelId = 0;

    selectedPhoneCountryId = 0;

    selectedRole = 2;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initUser();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (data) {
                console.log(data);
                this.priceLevelAll = data.priceLevels;
                this.countryAll = data.countries;
                this.storeAll = data.stores;
                this.sectorAll = data.sectors;
                
                this.priceLevelAllDisplayed = data.priceLevels;
                this.countryAllDisplayed = data.countries;
                this.storeAllDisplayed = data.stores;
                this.sectorAllDisplayed = data.sectors;
            }
        }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initUser(): void {
        this.client.adminUser_UserById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.user = data;
                this.id = this.user.id;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getUserError );
                console.error(error); });
    }

    createFormControls(): void {
        this.addressArray = new FormArray([]);
        this.sectorArray = new FormArray([]);
        this.storeArray = new FormArray([]);
        if (this.user) {
            this.id1c = new FormControl(this.user.id1C);
            this.email = new FormControl(this.user.email);
            this.firstName = new FormControl(this.user.firstName);
            this.lastName = new FormControl(this.user.lastName);
            this.middleName = new FormControl(this.user.middleName);
            this.mainRole = new FormControl(this.user.mainRole);
            this.userUtc = new FormControl(this.user.userUtc);
            this.oldLogin1C = new FormControl(this.user.oldLogin1C);
            this.balance = new FormControl(this.user.userBalance.balance);
            this.phone = new FormControl(this.user.phone);
            this.isAccountingPermission = new FormControl(this.user.isAccountingPermission);

            this.user.addresses.map(x => this.addressArray.push(this.fb.group({
                address: new FormControl(x)
            })));

            this.user.sectors.map(x => this.sectorArray.push(this.fb.group({
                sectorId: new FormControl(x.sectorId),
                title: new FormControl(x.sector.title),
                type: new FormControl(x.sector.type)
            })));

            this.user.stores.map(x => this.storeArray.push(this.fb.group({
                storeId: new FormControl(x.storeId),
                title: new FormControl(x.store.name)
            })));

            this.selectedUserCurrency = this.user.userCurrency;
            this.selectedUserType = this.user.userType;
            this.selectedCityId = this.user.cityId;
            this.selectedPriceLevelId = this.user.priceLevelId;
            this.selectedPhoneCountryId = this.user.phoneCountryId;
            this.selectedRole = this.user.role;
            this.selectedStoreId = this.user.stores.length !== 0 ? this.user.stores[0].storeId : 0;

        } else {
            this.id1c = new FormControl(null);
            this.email = new FormControl(null);
            this.firstName = new FormControl(null);
            this.lastName = new FormControl(null);
            this.middleName = new FormControl(null);
            this.mainRole = new FormControl(null);
            this.userUtc = new FormControl(null);
            this.oldLogin1C = new FormControl(null);
            this.balance = new FormControl(null);
            this.phone = new FormControl(null);
            this.isAccountingPermission = new FormControl(null);
        }
        this.userForm = new FormGroup({
            Id1c: this.id1c,
            FirstName: this.firstName,
            LastName: this.lastName,
            MiddleName: this.middleName,
            OldLogin1C: this.oldLogin1C,
            Email: this.email,
            MainRole: this.mainRole,
            UserUtc: this.userUtc,
            Balance: this.balance,
            Phone: this.phone,
            IsAccountingPermission: this.isAccountingPermission
        });

        this.isReady = true;
    }

    searchPriceLevel(): void {
        if (!this.priceLevelSearch) {
            this.priceLevelAllDisplayed = this.priceLevelAll;
            return;
        }
        this.priceLevelAllDisplayed = this.priceLevelAll.filter(x => x.title.toLocaleLowerCase().includes(this.priceLevelSearch.toLocaleLowerCase()));
    }

    searchStore(): void {
        if (!this.storeSearch) {
            this.storeAllDisplayed = this.storeAll;
            return;
        }
        this.storeAllDisplayed = this.storeAll.filter(x => x.name.toLocaleLowerCase().includes(this.storeSearch.toLocaleLowerCase()));
    }

    searchSector(): void {
        if (!this.sectorSearch) {
            this.sectorAllDisplayed = this.sectorAll;
            return;
        }
        this.sectorAllDisplayed = this.sectorAll.filter(x => x.title.toLocaleLowerCase().includes(this.sectorSearch.toLocaleLowerCase()));
    }

    checkCity(title: string): boolean {
        return !this.citySearch ? true : title.toLocaleLowerCase().includes(this.citySearch.toLocaleLowerCase())
    }

    checkCountry(index: number): boolean {
        if (!(this.citySearch)) {
            return true;
        }
        let count = 0;
        this.countryAll[index].cities.map(x => {
            if (x.name) {
                if (x.name.toLocaleLowerCase().includes(this.citySearch.toLocaleLowerCase())) {
                    count++;
                }
            }
        });
        return count > 0;
    }
    
    addAddress(): void {
        if (this.addressArray && this.addressArray.length < 3) {
            this.addressArray.push(this.fb.group({
                address: new FormControl(null)
            }));
        }
    }

    removeAddress(index: number): void {
        if (this.addressArray.value[index]) {
            this.addressArray.value.splice(index, 1);
            this.addressArray.controls.splice(index, 1);
        }
    }

    addStore(): void {
        const index = this.storeAll.findIndex(x => x.id === this.selectedStoreId);
        const existIndex = this.storeArray.value.findIndex(x => x.storeId === this.selectedStoreId);
        if (index > -1 && existIndex === -1) {
            this.storeArray.push(this.fb.group({
                storeId: new FormControl(this.storeAll[index].id),
                title: new FormControl(this.storeAll[index].name)
            }));
        }
    }

    removeStore(index: number): void {
        if (this.storeArray.value[index]) {
            this.storeArray.value.splice(index, 1);
            this.storeArray.controls.splice(index, 1);
        }
    }

    addSector(): void {
        const index = this.sectorAll.findIndex(x => x.id === this.selectedSectorId);
        const existIndex = this.sectorArray.value.findIndex(x => x.sectorId === this.selectedSectorId);
        if (index > -1 && existIndex === -1) {
            this.sectorArray.push(this.fb.group({
                sectorId: new FormControl(this.sectorAll[index].id),
                title: new FormControl(this.sectorAll[index].title),
                type: new FormControl(this.sectorAll[index].type)
            }));
        }
    }

    removeSector(index: number): void {
        if (this.sectorArray.value[index]) {
            this.sectorArray.value.splice(index, 1);
            this.sectorArray.controls.splice(index, 1);
        }
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    getUserSectors(): number[] {
        const model = [];
        this.sectorArray.value.map(x => model.push(x.sectorId));
        return model;
    }

    getUserStores(): number[] {
        const model = [];
        this.storeArray.value.map(x => model.push(x.storeId));
        return model;
    }

    getUserAddresses(): string[] {
        const model = [];
        this.addressArray.value.map(x => model.push(x.address));
        return model;
    }

    toPost(): void {

        const model: AdminUserAddOrUpdate = {
            id: this.id,
            id1C: this.userForm.value.Id1c,
            firstName: this.userForm.value.FirstName,
            lastName: this.userForm.value.LastName,
            middleName: this.userForm.value.MiddleName,
            email: this.userForm.value.Email,
            mainRole: this.userForm.value.MainRole,
            userUtc: this.userForm.value.UserUtc,
            oldLogin1C: this.userForm.value.OldLogin1C,
            balance: this.userForm.value.Balance,
            phone: this.userForm.value.Phone,
            userCurrency: this.selectedUserCurrency,
            userType: this.selectedUserType,
            cityId: this.selectedCityId,
            priceLevelId: this.selectedPriceLevelId,
            phoneCountryId: this.selectedPhoneCountryId,
            isAccountingPermission: this.userForm.value.IsAccountingPermission,
            sectors: this.getUserSectors(),
            stores: this.getUserStores(),
            addresses: this.getUserAddresses(),
            role: this.selectedRole
        };

        this.client.adminUser_AddOrUpdateUser(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.router.navigate(['/admin/users']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.userAddCompleate : notyf.message.userUpdateCompleate );
        }, error => {
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.userAddError : notyf.message.userUpdateError );
            console.error(error); });
    }

    deleteUser(userId): void {
        this.client.adminUser_DeleteUserById(userId)
            .subscribe(data => {
                this.adminService.showNotify(notyf.type.success, `Пользователь успешно удален.` );
                this.router.navigate(['/admin/users']);
            }, error => {
                this.adminService.showNotify(notyf.type.error, error.message);
                this.adminService.showNotify(notyf.type.error, `Невозможно удалить пользователя,т.к. он имеет связь с другими таблицами`);
                console.error(error); });
    }

    checkInputSymbol(): void {
        let value = this.userForm.value.Phone;
        const rep = /[-\.;":'a-zA-Zа-яА-Я]/;
        if (rep.test(value)) {
            value = value.replace(rep, '');
            this.userForm.controls['Phone'].setValue(value);
        }
    }
}
