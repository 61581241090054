import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { Client } from 'app/service/Client';
import { AdminChatInfoHelper, AdminChatInfoRequest, AdminMessageSendRequest, ChatResponse, AdminChatUserUpdateRequest } from './../../../../service/Client';
import { ChatSiteService } from './../chat-site.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit, ViewChild, ViewChildren } from '@angular/core';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { NgForm } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-chat-view-site',
    templateUrl: './view-site.component.html',
    styleUrls: ['./view-site.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChatViewSiteComponent implements OnInit, OnDestroy, AfterViewInit {
    user: any;
    chat: any;
    dialog: any;
    contact: any;
    replyInput: any;
    selectedChat: any;

    chatInfo: AdminChatInfoHelper;

    showMore = true;

    fromUserId = null;
    message = '';


    timerStopped = true;
    timemerSubscribe: any;

    needReply = true;


    @ViewChild(FusePerfectScrollbarDirective)
    directiveScroll: FusePerfectScrollbarDirective;

    @ViewChildren('replyInput')
    replyInputField;

    @ViewChild('replyForm')
    replyForm: NgForm;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _chatService: ChatSiteService,
        private client: Client,
        private adminService: AdminBaseService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.user = this._chatService.user;
        this._chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatData => {
                if (chatData) {
                    this.selectedChat = chatData;
                    this.contact = chatData.contact;
                    this.dialog = chatData.dialog;
                    this.readyToReply();
                }
            });



        this._chatService.onChatInfoSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                const model = data as AdminChatInfoHelper;
                console.log(model.user.userName);
                console.log(model.user.id + "===" + this.fromUserId);
                if (model && model.user) {
                    if (this.fromUserId) {
                        if (model.user.id !== this.fromUserId) {
                            this.chatInfo = null;
                            this.fromUserId = model.user.id;
                        }
                    } else {
                        this.fromUserId = model.user.id;
                    }
                }
                if (this.needReply) {
                    this.readyToReply();
                } else {
                    this.needReply = true;
                }

                if (model.isEmpty || model.messages.length === 0) {
                    this.showMore = false;
                }
                if (this.chatInfo) {
                    if (model.isEmpty || model.messages.length === 0) {
                        this.showMore = false;
                    } else {
                        model.messages = model.messages.concat(this.chatInfo.messages);
                        const result: ChatResponse[] = [];
                        for (let i = 0; i < model.messages.length; i++) {
                            const index = result.findIndex(x => x.id === model.messages[i].id);
                            if (index < 0) {
                                result.push(model.messages[i]);
                            }
                        }
                        result.sort((n2, n1) => {
                            if (n1.created < n2.created) {
                                return 1;
                            }

                            if (n1.created > n2.created) {
                                return -1;
                            }

                            return 0;
                        });
                        this.chatInfo.messages = result;
                        this.chatInfo.take = model.take;
                        this.chatInfo.skip = model.skip;
                    }
                } else {
                    this.chatInfo = model;
                }
                this.updateRead();
                this.initTimer();
                this._chatService.onNewMessage.next([this.chatInfo.messages[this.chatInfo.messages.length - 1], this.fromUserId]);
                this.adminService.updateNavigations();
            });
    }

    initTimer() {
        if (this.timerStopped === false) { return; }
        this.timerStopped = false;
        this.timemerSubscribe = timer(0, 10000)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(t => {
                this.checkUpdateMessage();
            });
    }

    checkUpdateMessage(): void {
        if (this.chatInfo && this.chatInfo.messages && this.chatInfo.messages.length > 0) {
            const model: AdminChatUserUpdateRequest = {
                dialogId: this.chatInfo.messages[this.chatInfo.messages.length - 1].dialogId,
                lastMessageId: this.chatInfo.messages[this.chatInfo.messages.length - 1].id,
                lastMessageTime: this.chatInfo.messages[this.chatInfo.messages.length - 1].created
            };

            this._chatService.updateChatMessage(model)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(data => {
                    data.map(x => this.chatInfo.messages.push(x));
                    this.readyToReply();
                    this.updateRead();
                }, error => { console.error(error); });
        }
    }

    stopTimer() {
        if (this.timerStopped === false) {
            this.timemerSubscribe.unsubscribe();
            this.timerStopped = true;
        }
    }

    showMoreMessages(): void {
        const model: AdminChatInfoRequest = {
            userId: this.chatInfo.user.id,
            take: this.chatInfo.take,
            skip: this.chatInfo.skip + this.chatInfo.take
        };
        this.needReply = false;
        this._chatService.getUserChatInfo(model);
    }

    updateRead(): void {
        if (this.chatInfo) {
            this.client.adminChat_UpdateRead(this.chatInfo.user.id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(data => {
                }, error => { console.error(error); });
        }
    }

    // checkAvatar(i: number, j: number): boolean {
    //     return this.chatInfo.messageGroup[i].isAvatar && j !== this.chatInfo.messageGroup[i].messages.length - 1;
    // }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;
        this.readyToReply();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.stopTimer();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.who === this.contact.id &&
            ((this.dialog[i + 1] && this.dialog[i + 1].who !== this.contact.id) || !this.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.dialog[i - 1] && this.dialog[i - 1].who !== message.who);
    }

    checkFirstMessage(index: number): boolean {
        return (index === 0 || this.chatInfo.messages[index - 1] && this.chatInfo.messages[index - 1].isMine !== this.chatInfo.messages[index].isMine)
    }

    checkLastMessage(index: number): boolean {
        return (index === this.chatInfo.messages.length - 1 || this.chatInfo.messages[index + 1] && this.chatInfo.messages[index + 1].isMine !== this.chatInfo.messages[index].isMine);
    }
    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.dialog.length - 1 || this.dialog[i + 1] && this.dialog[i + 1].who !== message.who);
    }

    /**
     * Select contact
     */
    selectContact(): void {
        this._chatService.selectContact(this.contact);
    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        });
    }

    /**
     * Focus to the reply input
     */
    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }

    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this.message) {
            return;
        }
        const model: AdminMessageSendRequest = {
            fromUserId: this.adminService.adminProfile.id,
            toUserId: this.chatInfo.user.id,
            message: this.message,
        };
        this.message = '';
        this._chatService.sendMessage(model)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                const request: AdminChatInfoRequest = {
                    userId: this.chatInfo.user.id,
                    take: this.chatInfo.take + this.chatInfo.skip + 1,
                    skip: 0
                };
                // this.chatInfo.messages = [];
                this._chatService.getUserChatInfo(request);
            }, error => { console.error(error); });
    }
}

