import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCityAddOrUpdate, AdminCountryResponse } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminCityResponse, Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-city-item',
    templateUrl: './city-item.component.html',
    styleUrls: ['./city-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CitySiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    city: AdminCityResponse;
    cities: AdminCityResponse[];

    countries: AdminCountryResponse[];

    id = 0;
    countryId = 0;

    isNew = false;
    isReady = false;

    cityForm: FormGroup;
    id1c: FormControl;
    name: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                this.initCountries();
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initCity();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initCity(): void {
        this.client.adminCountry_CityById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.city = data;
                this.id = this.city.id;
                console.log(this.city);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getCityError );
                console.error(error); });
    }

    initCountries(): void {
        this.client.adminSetting_CountriesAll()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.countries = data;
            this.countryId = this.countries ? this.countries[0].id : 0;
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getCountryError );
            console.error(error); });
    }

    createFormControls(): void {
        if (this.city) {
            this.id1c = new FormControl(this.city.id1C, Validators.required);
            this.name = new FormControl(this.city.name, Validators.required);
            this.isActive = new FormControl(this.city.isActive, Validators.required);
            this.countryId = this.city.countryId;
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.cityForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            IsActive: this.isActive,
        });

        this.isReady = true;
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        if (this.cityForm.valid) {
            const model: AdminCityAddOrUpdate = {
                id: this.id,
                id1C: this.cityForm.value.Id1c,
                name: this.cityForm.value.Name,
                isActive: this.cityForm.value.IsActive,
                countryId: this.countryId
            };
            this.client.adminCountry_CityAddOrUpdate(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.router.navigate(['/admin/cities']); 
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.cityAddCompleate : notyf.message.cityUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.cityAddError : notyf.message.cityUpdateError );
                console.error(error); });
        }
    }
}