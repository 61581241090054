import { Client, AdminPartnerResponse, AdminPartnerRequest, AdminPartnerHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class PartnerSiteSerivce implements Resolve<any>
{
    public partnerHelper: AdminPartnerHelper;
    partner: AdminPartnerResponse[];
    onPartnersChanged: BehaviorSubject<any>;
    onPartnersHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onPartnersChanged = new BehaviorSubject({});
        this.onPartnersHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminContactUsRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminPartnerRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPartners(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getPartners(model: AdminPartnerRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminPartner_Partners(model)
                .subscribe((response: any) => {
                    this.partnerHelper = response;
                    if (this.partnerHelper && this.partnerHelper.partners && this.partnerHelper.partners.length > 0) {
                        this.partnerHelper.partners.map(x => x.image = environment.apiUrlProd + environment.partners + x.id + '/' + x.image);
                    }
                    this.partner = this.partnerHelper.partners;
                    this.onPartnersChanged.next(this.partner);
                    this.onPartnersHelperChanged.next(this.partnerHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getPartnerError );
                });
        });
    }

    changePosition(index: number, type: string): Promise<any> {

        const prom = this.partner[index];
        const fisrtId = this.partner[index].id;
        let pos = this.partner[index].position;
        let secondId = 0;
        if (type === 'up') {
            secondId = this.partner[index - 1].id;
            pos = this.partner[index - 1].position;
            this.partner[index] = this.partner[index - 1];
            this.partner[index - 1] = prom;
            this.partner[index].position = prom.position;
            this.partner[index - 1].position = pos;
        } else {
            secondId = this.partner[index + 1].id;
            pos = this.partner[index + 1].position;
            this.partner[index] = this.partner[index + 1];
            this.partner[index + 1] = prom;
            this.partner[index].position = prom.position;
            this.partner[index + 1].position = pos;
        }
        this.partnerHelper.partners = this.partner;

        return new Promise((resolve, reject) => {
            this.client.adminPartner_ChangePosition(fisrtId, secondId)
                .subscribe((response: any) => {
                    // this.partner = this.partnerHelper.partners;
                    this.onPartnersChanged.next(this.partner);
                    this.onPartnersHelperChanged.next(this.partnerHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getPartnerError );
                });
        });           
    }

    public partnersLength(): number {
        return this.partner ? this.partner.length : 0;
    }
}
