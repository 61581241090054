import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminSectorResponse, AdminRouteResponse, AdminSectorAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ArticleResponse, FileParameter, Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-sector-item',
    templateUrl: './sector-item.component.html',
    styleUrls: ['./sector-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None

})
export class SectorItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    sector: AdminSectorResponse;

    id = 0;

    isNew = false;
    isReady = false;

    routeAll: AdminRouteResponse[] = [];
    routeAllDisplayed: AdminRouteResponse[] = [];
    routeSearch = '';
    selectedRouteId = null;

    routeArray: FormArray;

    sectorForm: FormGroup;
    id1c: FormControl;
    title: FormControl;
    type: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initSector();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                if (data) {
                    this.routeAll = data.routes;
                    this.routeAllDisplayed = this.routeAll;
                }
            }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initSector(): void {
        this.client.adminLogistic_SectorById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.sector = data as AdminSectorResponse;
                console.log(this.sector);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getSectorError );
                console.error(error); });
    }

    createFormControls(): void {
        this.routeArray = new FormArray([]);
        if (this.sector) {
            this.id1c = new FormControl(this.sector.id1C, Validators.required);
            this.title = new FormControl(this.sector.title, Validators.required);
            this.type = new FormControl(this.sector.type, Validators.required);

            this.sector.routes.map(x => this.routeArray.push(this.fb.group({
                id: new FormControl(x.id),
                title: new FormControl(x.title)
            })));
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.title = new FormControl(null, Validators.required);
            this.type = new FormControl(null, Validators.required);
        }
        console.log(this.routeArray);
        this.sectorForm = new FormGroup({
            Id1C: this.id1c,
            Title: this.title,
            Type: this.type
        });
        this.isReady = true;
    }

    addSectorRoute(): void {
        const index = this.routeAll.findIndex(x => x.id === this.selectedRouteId);
        const existIndex = this.routeArray.value.findIndex(x => x.id === this.selectedRouteId);
        if (index === -1 || existIndex > -1) {
            return;
        }
        this.routeArray.push(this.fb.group({
            id: new FormControl(this.selectedRouteId),
            title: new FormControl(this.routeAll[index].title)
        }));
    }

    searchRoute(): void {
        if (!this.routeSearch) {
            this.routeAllDisplayed = this.routeAll;
            return;
        }
        this.routeAllDisplayed = this.routeAll.filter(x => x.title.toLocaleLowerCase().includes(this.routeSearch.toLocaleLowerCase()));
    }

    removeSectorRoute(index: number): void {
        this.routeArray.controls.splice(index, 1);
        this.routeArray.value.splice(index, 1);
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        const routeModel: number[] = [];

        for (let i = 0; i < this.routeArray.value.length; i++) {
            routeModel.push(this.routeArray.value[i].id);
        }

        const mainModel: AdminSectorAddOrUpdate = {
            id: this.id,
            id1C: this.sectorForm.value.Id1C,
            title: this.sectorForm.value.Title,
            type: this.sectorForm.value.Type,
            sectorIds: routeModel
        };

        this.client.adminLogistic_SectorAddOrUpdate(mainModel)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                console.log(data);
                if (this.isNew) {
                    this.adminService.helperAll.sectors.push(data);
                    data.routes.map(x => {
                        const index = this.adminService.helperAll.routes.findIndex(y => y.id === x.id);
                        if (index === -1) {
                            this.adminService.helperAll.routes.push(x);
                        }
                    });  
                    this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
                } 
                this.router.navigate(['/admin/sectors']);  
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.sectorAddCompleate : notyf.message.sectorUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.sectorAddError : notyf.message.sectorUpdateError );
                console.error(error); });
    }
}
