import { AdminTecDocModificationRequest } from './../../../../service/Client';
import { AdminBaseService } from './../../../services/admin.base.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminTecDocModificationResponse, AdminTecDocModificationHelper, Client } from '../../../../service/Client';
import { notyf } from '../../../models/notyf';

@Injectable()
export class ModificationCarService implements Resolve<any> {
    modifications: AdminTecDocModificationResponse[];
    modificationsHelper: AdminTecDocModificationHelper;
    onCategoryChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCategoryChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminTecDocModificationRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getModifications(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getModifications(model: AdminTecDocModificationRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminTecDocCar_Modifications(model)
                .subscribe((response: any) => {
                    this.modificationsHelper = response;
                    this.modifications = this.modificationsHelper.modifications;
                    this.onCategoryChanged.next(this.modifications);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getModificationError );
                });
        });
    }

    public modificationLength(): number {
        return this.modificationsHelper && this.modificationsHelper.paginator ? this.modificationsHelper.paginator.countTotal : 0;
    }
}
