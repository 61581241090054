import { AdminTecDocModelRequest } from './../../../../service/Client';
import { AdminBaseService } from './../../../services/admin.base.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminTecDocModelResponse, AdminTecDocModelHelper, Client } from '../../../../service/Client';
import { notyf } from '../../../models/notyf';

@Injectable()
export class ModelCarService implements Resolve<any> {
    models: AdminTecDocModelResponse[];
    modelsHelper: AdminTecDocModelHelper;
    onCategoryChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCategoryChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminTecDocModelRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getModels(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getModels(model: AdminTecDocModelRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminTecDocCar_Models(model)
                .subscribe((response: any) => {
                    this.modelsHelper = response;
                    this.models = this.modelsHelper.models;
                    this.onCategoryChanged.next(this.models);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getModelError );
                });
        });
    }

    public modelLength(): number {
        return this.modelsHelper && this.modelsHelper.paginator ? this.modelsHelper.paginator.countTotal : 0;
    }
}
