export const environment = {
    apiUrl: 'https://api.tiravto.com',
    apiUrlProd: 'https://api.tiravto.com',
    storageUrl: 'https://storage.tiravto.com/image/tiravto_website/images/',
    clientUrlProd: 'https://admin.tiravto.com',
    productImageUrl: '/images/products/',
    categoryImageUrl: '/images/categories/',
    storeImageUrl: '/images/stores/',
    noPhotoUrl: '/Content/Main/no-photo.jpg',
    sliderMain: '/Content/mainSlider/',
    blogMain: '/images/articles/',
    partners: '/images/partners/',
    downloadInfo: '/images/downloadInfo/',
    downloadInfoItem: '/Content/DownloadInfoItem/File/',
    infoMain: '/images/downloadInfo/',
    infoItemMain: '/images/downloadInfoItem/',
    infoItemFileMain: '/Content/DownloadInfoItem/File/',
    socialPath: '/Content/Main/',
    production: true,
    hmr: false,
    PRB: 'PRB',
    USD: 'USD',
    deliveryStringKey: {
        pickUp: 'CustomerPickup',
        courier: 'Courier'
    },
    paymentStringKey: {
        inStore: 'InStore',
        eCash: 'ECash'
    },
    daysOfWeek: [
        { title: 'Понедельник', index: 0, isCheck: false },
        { title: 'Вторник', index: 1, isCheck: false },
        { title: 'Среда', index: 2, isCheck: false },
        { title: 'Четверг', index: 3, isCheck: false },
        { title: 'Пятница', index: 4, isCheck: false },
        { title: 'Суббота', index: 5, isCheck: false },
        { title: 'Воскресенье', index: 6, isCheck: false }
    ]
};
