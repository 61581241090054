import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-site',
  templateUrl: './home-site.component.html',
  styleUrls: ['./home-site.component.scss']
})
export class HomeSiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
