import { AdminBaseService } from './../../../services/admin.base.service';
import { SliderItemResponse } from './../../../../service/Client';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
import { notyf } from 'app/admin/models/notyf';
import { FileManagerService } from '../../../services/file-manager.service';

@Component({
  selector: 'app-slider-site-item',
  templateUrl: './slider-site-item.component.html',
  styleUrls: ['./slider-site-item.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SliderSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    sliderItem: SliderItemResponse;
    token: string = '';
    id = 0;
    public storageUrl = environment.storageUrl;

    isNew = false;
    isReady = false;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    sliderForm: FormGroup;
    title: FormControl;
    titleBtn: FormControl;
    titleSecond: FormControl;
    isActive: FormControl;
    onlyImage: FormControl;
    url: FormControl;
    backgroundColor: FormControl;
    image: FormControl;
    backgroundImage: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fileManager: FileManagerService
    ) {
        this.route.params
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(params => {
            console.log(params);
            if (params.id) {
                this.isNew = false;
                this.id = params.id;
                this.initSliderItem();
            } else {
                this.isNew = true;
                this.createFormControls();
            }
        });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    changeColor(event: any): void {
        this.sliderForm.controls['BackgroundColor'].setValue(event);       
    }

    removeSliderItem(): void {
        this.client.adminSlider_RemoveItemById(this.sliderItem.id)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.router.navigate(['/admin/slider']);
            this.adminService.showNotify(notyf.type.success, notyf.message.removeSliderImageCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.removeSliderImageError );
            console.error(error); });
    }
    
    initSliderItem(): void {
        this.client.adminSlider_ItemById(this.id)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.sliderItem = data as SliderItemResponse;
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getSliderError );
            console.error(error); 
        });
    }

    createFormControls(): void {
        if (this.sliderItem) {
            this.title = new FormControl(this.sliderItem.title, Validators.required);
            this.titleSecond = new FormControl(this.sliderItem.titleSecond, Validators.required);
            this.url = new FormControl(this.sliderItem.url, Validators.required);
            this.backgroundColor = new FormControl(this.sliderItem.backgroundColor, Validators.required);
            this.isActive = new FormControl(this.sliderItem.isActive, Validators.required);
            this.titleBtn = new FormControl(this.sliderItem.buttonName, Validators.required);
            this.onlyImage = new FormControl(this.sliderItem.onlyImage, Validators.required);
            this.image = new FormControl(this.sliderItem.image, Validators.required);
            this.backgroundImage = new FormControl(this.sliderItem.backgroundImage);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.titleSecond = new FormControl(null, Validators.required);
            this.url = new FormControl(null, Validators.required);
            this.backgroundColor = new FormControl('#ffffff', Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.onlyImage = new FormControl(false, Validators.required);
            this.titleBtn = new FormControl('Подробнее', Validators.required);
            this.image = new FormControl(null, Validators.required);
            this.backgroundImage = new FormControl(this.sliderItem.backgroundImage);
        }
        
        this.sliderForm = new FormGroup({
            Title: this.title,
            TitleSecond: this.titleSecond,
            Url: this.url,
            BackgroundColor: this.backgroundColor,
            IsActive: this.isActive,
            TitleBtn: this.titleBtn,
            OnlyImage: this.onlyImage,
            Image: this.image,
            BackgroundImage: this.backgroundImage
        });
        this.isReady = true;
    }

    toPost(): void {
            this.client.adminSlider_AddOrUpdate(
                this.id,
                this.sliderForm.value.IsActive,
                this.sliderForm.value.Title,
                this.sliderForm.value.TitleSecond,
                this.sliderForm.value.Url,
                this.sliderForm.value.Image,
                this.sliderForm.value.BackgroundColor,
                this.sliderForm.value.OnlyImage,
                this.sliderForm.value.TitleBtn,
                this.sliderForm.value.BackgroundImage 
            ).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                this.router.navigate(['/admin/slider']);
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.sliderAddCompleate : notyf.message.sliderUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.sliderAddError : notyf.message.sliderUpdateError );
                console.error(error); });
    }

    removeFile(field: string): void {
        this.sliderForm.controls[field].setValue(null);
    }

    addPhoto(controlName: string): void {
       this.fileManager.getToken().subscribe({
            next: (data) => {
                console.log('data', data);
            },
            error: (e) => {
                this.token = e.error.text;
                this.openNewWindow(controlName);
            }
        })
    }

    openNewWindow(controlName: string): void {
        let url = 'https://tiravto-storage.it-trends.net' + '/auth/' + this.token

        const windowFeatures = 'width=1700,height=710,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
        
        window.open(url, 'File manager', windowFeatures);

        window.addEventListener('message', ({origin, data}) => {
            if (origin === 'https://tiravto-storage.it-trends.net') {
                if (data.type === 'selectImage') {
                    data.data.forEach((element: any) => {
                        this.sliderForm.controls[controlName].setValue(element.path);
                    });
                }
            }
        });
    }
}
