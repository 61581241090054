import { AdminUserProfileResponse } from 'app/service/Client';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { ChatSiteService } from './../../../chat-site.service';
import { ChatSiteComponent } from './../../../chat-site.component';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-chat-user-site',
  templateUrl: './user-site.component.html',
  styleUrls: ['./user-site.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChatUserSiteComponent implements OnInit, OnDestroy
{
    user: any;
    profile: AdminUserProfileResponse;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _chatService: ChatSiteService,
        private adminService: AdminBaseService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.user = this._chatService.user;
        this.profile = this.adminService.adminProfile;

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Change left sidenav view
     *
     * @param view
     */
    changeLeftSidenavView(view): void
    {
        this._chatService.onLeftSidenavViewChanged.next(view);
    }

}

