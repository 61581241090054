import { takeUntil } from 'rxjs/internal/operators';
import { AdminBaseService } from './../../../../services/admin.base.service';
import { Client, AdminOptionValueResponse, AdminOptionValueRequest } from './../../../../../service/Client';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-value-site',
    templateUrl: './value-site.component.html',
    styleUrls: ['./value-site.component.scss']
})
export class OptionValueSiteComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    @Input() optionId: string;

    values: AdminOptionValueResponse[];
    valuesDisplayed: AdminOptionValueResponse[];

    selectedValueId = '';
    searchValue = '';

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    ) { }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (data) {
                this.initValues();
            }
        });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initValues(): void {
        // const index = this.adminService.optionAll.findIndex(x => x.id === this.optionId);
        // if (index > -1) {
        //     this.values = this.adminService.optionAll[index].values;
        //     this.valuesDisplayed = this.values;
        // }
        const model: AdminOptionValueRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('pos'),
            optionId: this.optionId
        };

        this.client.adminOption_OptionValuesAll(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.values = data as AdminOptionValueResponse[];
            this.valuesDisplayed = this.values;
            console.log(this.values);
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getOptionError );
            console.error(error); });
    }

    searchOptions(): void {
        if (!this.searchValue) {
            this.valuesDisplayed = this.values;
            return;
        }
        this.valuesDisplayed = this.values.filter(x => x.value.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase()));
    }

    valueChanged(): void {
        this.adminService.optionValueChangeStatusSource.next(this.selectedValueId);
    }

}
