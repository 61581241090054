export const orderStatus = [
    {
        title: 'Заказан',
        id: 0,
        stringKey: 'Order'
    },
    {
        title: 'Зарезервирован',
        id: 1,
        stringKey: 'Reserved'
    },
    {
        title: 'Отправлен на упаковку',
        id: 2,
        stringKey: 'ToBox'
    },
    {
        title: 'Упаковывается',
        id: 3,
        stringKey: 'Box'
    },
    {
        title: 'Готов к выдаче',
        id: 4,
        stringKey: 'ReadyToGet'
    },
    {
        title: 'Выполнен',
        id: 5,
        stringKey: 'Complete'
    },
    {
        title: 'Снят с резерва',
        id: 6,
        stringKey: 'UnReserved'
    },
    {
        title: 'Ожидание перемещения',
        id: 7,
        stringKey: 'RouteWaiting'
    },
    {
        title: 'Перемещение выполнено',
        id: 8,
        stringKey: 'RouteComplete'
    },
    {
        title: 'В пути',
        id: 9,
        stringKey: 'RouteProgress'
    },
    {
        title: 'Ожидание прихода',
        id: 10,
        stringKey: 'Waiting'
    },

];
