import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCountryResponse, AdminUnitAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminUnitResponse, FileParameter, Client, ArticleResponse, UnitResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-unit-item',
    templateUrl: './unit-item.component.html',
    styleUrls: ['./unit-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UnitSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    unit: AdminUnitResponse;

    id = 0;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;


    unitForm: FormGroup;
    id1c: FormControl;
    name: FormControl;
    symbol: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initUnit();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initUnit(): void {
        this.client.adminUnit_UnitById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.unit = data;
                this.id = this.unit.id;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getUnitError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.unit) {
            this.id1c = new FormControl(this.unit.id1C, Validators.required);
            this.name = new FormControl(this.unit.name, Validators.required);
            this.symbol = new FormControl(this.unit.symbol, Validators.required);

        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.symbol = new FormControl(null, Validators.required);
        }
        this.unitForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            Symbol: this.symbol
        });

        this.isReady = true;
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        const model: AdminUnitAddOrUpdate = {
            id: this.id,
            id1C: this.unitForm.value.Id1c,
            name: this.unitForm.value.Name,
            symbol: this.unitForm.value.Symbol,

        };
        this.client.adminUnit_UnitAddOrUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.router.navigate(['/admin/units']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.unitAddCompleate : notyf.message.unitUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.unitAddError : notyf.message.unitUpdateError );
            console.error(error); });
    }
}