import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminOptionValueResponse, AdminOptionResponse, AdminOptionValueAddOrUpdate } from './../../../../../service/Client';
import { Client } from 'app/service/Client';
import { AdminBaseService } from './../../../../services/admin.base.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-value-item',
    templateUrl: './value-item.component.html',
    styleUrls: ['./value-item.component.scss']
})
export class OptionValueSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    @Input() isNew: boolean;
    @Input() option: AdminOptionResponse;

    valueId = null;
    optionValue: AdminOptionValueResponse;

    isReady = false;

    searchOption = '';
    searchValue = '';

    optionsAll: AdminOptionResponse[] = [];
    optionsAllDisplayed: AdminOptionResponse[] = [];
    optionsValuesSelected: AdminOptionValueResponse[] = [];
    optionsValuesSelectedDisplayed: AdminOptionValueResponse[] = [];

    optionValueForm: FormGroup;
    id1C: FormControl;
    value: FormControl;
    isActive: FormControl;
    dependId = null;
    optionId = null;

    constructor(
        private adminService: AdminBaseService,
        private client: Client
    ) { }

    ngOnInit(): void {
        this.initOptionsAll();
        if (!this.isNew) {
            this.initValueId();
        } else {
            this.createFormControls();
        }
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initOptionsAll(): void {
        this.adminService.helperAllChangeStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                if (data) {
                    this.optionsAll = data.options;
                    this.optionsAllDisplayed = this.optionsAll;
                }
            });
    }

    initValueId(): void {
        this.adminService.optionValueChangeStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                if (data) {
                    this.valueId = data as string;
                    this.initValue();
                }
            });
    }

    initValue(): void {
        // this.isReady = false;
        // this.client.adminOption_OptionValueById(this.valueId)
        // .pipe(takeUntil(this.ngUnsubscribe))
        // .subscribe(data => {
        //     this.optionValue = data as AdminOptionValueResponse;
        //     this.updateFormControls();
        //     console.log(this.value);
        // }, error => { console.error(error); });
        const index = this.option.values.findIndex(x => x.id === this.valueId);
        if (index > -1) {
            this.optionValue = this.option.values[index];
            this.updateFormControls();
        }
    }

    createFormControls(): void {
        this.optionId = this.option.id;
        if (!this.optionValue) {
            this.id1C = new FormControl(null, Validators.required);
            this.value = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.dependId = null;
        } else {
            this.id1C = new FormControl(this.optionValue.id1C, Validators.required);
            this.value = new FormControl(this.optionValue.value, Validators.required);
            this.isActive = new FormControl(this.optionValue.isActive, Validators.required);
        }
        this.optionValueForm = new FormGroup({
            Id1c: this.id1C,
            Value: this.value,
            IsActive: this.isActive
        });

        this.isReady = true;
    }

    updateFormControls(): void {
        this.dependId = this.optionValue.dependId;
        if (!this.optionValueForm) {
            this.createFormControls();
        } else {
            this.optionValueForm.controls['Id1c'].setValue(this.optionValue.id1C);
            this.optionValueForm.controls['Value'].setValue(this.optionValue.value);
            this.optionValueForm.controls['IsActive'].setValue(this.optionValue.isActive);
        }
    }

    changeDependValues(event: any): void {
        this.optionsValuesSelected = [];
        this.optionsValuesSelectedDisplayed = [];
        const index = this.optionsAll.findIndex(x => x.id === event.value);
        if (index > -1) {
            this.optionsValuesSelected = this.optionsAll[index].values;
            const valueIndex = this.optionsValuesSelected.findIndex(x => x.id === this.optionValue.id);
            if (valueIndex > -1) {
                this.optionsValuesSelected.splice(valueIndex, 1);
            }
            this.optionsValuesSelectedDisplayed = this.optionsAll[index].values;
        }
    }

    searchOptions(): void {
        if (!this.searchOption) {
            this.optionsAllDisplayed = this.optionsAll;
            return;
        }
        this.optionsAllDisplayed = this.optionsAll.filter(x => x.name.toLocaleLowerCase().includes(this.searchOption.toLocaleLowerCase()));
    }

    searchValues(): void {
        if (!this.searchValue) {
            this.optionsValuesSelectedDisplayed = this.optionsValuesSelected;
            return;
        }
        this.optionsValuesSelectedDisplayed = this.optionsValuesSelected.filter(x => x.value.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase()));
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        if (this.optionValueForm.valid && this.optionId) {
            const model: AdminOptionValueAddOrUpdate = {
                id: this.isNew ? null : this.optionValue.id,
                id1C: this.optionValueForm.value.Id1c,
                value: this.optionValueForm.value.Value,
                isActive: this.optionValueForm.value.IsActive,
                dependId: this.dependId,
                optionId: this.optionId
            };
            this.client.adminOption_OptionValueAddOrUpdate(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                    const index = this.adminService.helperAll.options.findIndex(x => x.id === data.optionId);
                    if (index > -1) {
                        if (this.isNew) {
                            this.adminService.helperAll.options[index].values.push(data);
                        } else {
                            const valueIndex = this.adminService.helperAll.options[index].values.findIndex(x => x.id === data.id);
                            if (valueIndex > -1) {
                                this.adminService.helperAll.options[index].values[valueIndex] = data;
                            }
                        }
                    }
                    this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
                    this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.optionAddCompleate : notyf.message.optionUpdateCompleate );
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.optionAddError : notyf.message.optionUpdateError );
                    console.error(error); });
        }
    }

    clearDepend(): void {
        this.dependId = null;
        this.searchValue = '';
        this.searchValue = '';
    }

}
