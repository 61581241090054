import { AdminBaseService } from './../admin.base.service';
import { Router } from '@angular/router';
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { async } from '@angular/core/testing';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Client, LoginRequest } from 'app/service/Client';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class AuthService {

  authToken: any;
  headers = new HttpHeaders();
  cachedRequests: Array<HttpRequest<any>> = [];
  apiUrl: string;
  private loggedIn = false;
  private jwtHelper: JwtHelperService;
  // Observable navItem source
  public authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this.authNavStatusSource.asObservable();

  constructor(
    private http: HttpClient,
    private client: Client,
    private router: Router,
    private adminService: AdminBaseService,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {

    this.jwtHelper = new JwtHelperService();
    this.apiUrl = environment.apiUrl;
    // if (this.localStorage != null) {
    //   this.loggedIn = this.localStorage.getItem('isAuth');
    // }
    if (this.isAuthenticated()) {
      if (!this.loggedIn) {
        this.loggedIn = true;
        this.authNavStatusSource.next(this.loggedIn);
      }
    }
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
  public retryFailedRequests(): void {
    // console.log('retryFailedRequests');
    // retry the requests. this method can
    // be called after the token is refreshed
    this.cachedRequests.forEach(request => {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'TimeZone': `${-new Date().getTimezoneOffset()}`,
          Authorization: `Bearer ${this.getAccessToken()}`
        }
      });

    });
  }

  login(loginRequest: LoginRequest): void {
    this.client.account_LoginAdmin(loginRequest).subscribe(data => {
      if (data && data.accessToken) {
        // this.checkLoggedIn(false);
        console.log(data);
        this.setTokens(data.accessToken.token, data.refreshToken);
          this.loggedIn = true;
          this.authNavStatusSource.next(this.loggedIn);
        this.router.navigate([`/admin`]);
      }
      this.adminService.showNotify(notyf.type.success, notyf.message.loginCompleate );
    }, error => { 
        this.adminService.showNotify(notyf.type.error, notyf.message.loginError );
        console.error(error); });
  }

  getAccessToken(): string {
    if (this.localStorage != null) {
      return localStorage.getItem('accessToken');
    }
  }

  getRefreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  getUserRoles(): any {
    const userRoles = this.localStorage.getItem('currentRoles');
    const res = userRoles.split(',');
    return res;
  }

  get(url: string, sessionId: string, licenseeType: string): Observable<any> {
    // console.log('get sessionId ' + sessionId);
    this.localStorage.setItem('currentSession', sessionId);
    return this.http.get(url).pipe(
      catchError(this.handleError));
  }

  postSession(url: string, model: any): Observable<any> {
    return this.http.post(url, model).pipe(
      catchError(this.handleError));
  }

  put(url: string, model: any, sessionId: string): Observable<any> {
    return this.http.put(url, model).pipe(
      catchError(this.handleError));
  }

  delete(url: string, id: number): Observable<any> {
    return this.http.get(url + '?id=' + id).pipe(
      catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse): any {
    console.error('handleError ');
    console.error(error);
    const parsedError = Object.assign({}, error, { error: JSON.stringify(error.error) });
    const formattedBody = JSON.parse(parsedError.error).message;
    return observableThrowError(formattedBody || 'Ошибка сервера');
  }

  logout(): any {
    // remove user from local storage to log user out
    this.clearTokens();

    // const request = this.http.delete(this.apiUrl + '/session')
    //   .catch(this.handleError);
    // request.subscribe((response) => {
    // });
    if (this.loggedIn) {
      this.loggedIn = false;
      this.authNavStatusSource.next(this.loggedIn);
    }
    this.router.navigate([`/admin/login`]);

  }


  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  /**
   * Проверка валидности токена
   */
  userHasValidTokens(): void {
    if (!this.jwtHelper.isTokenExpired(this.getAccessToken())) {
      if (this.getDecodedAccessToken()) {
        console.log('userHasValidTokens true');
        // this.loggedIn = true;
        // this.authNavStatusSource.next(this.loggedIn);
        this.checkLoggedIn(false);
      } else {
        console.log('userHasValidTokens false');
        this.checkLoggedIn(true);
      }
    }
  }

  checkLoggedIn(state: boolean): boolean {
    if (this.loggedIn === state) {
      this.loggedIn = !state;
      this.authNavStatusSource.next(this.loggedIn);
      return true;
    }
    return false;
  }

  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(this.getAccessToken());
  }

  getDecodedAccessToken(): IJwtItem {
    return this.jwtHelper.decodeToken(this.getAccessToken());
  }

  public setTokens(access_token: string, refresh_token: string): any {
    this.localStorage.setItem('accessToken', access_token);
    this.localStorage.setItem('refreshToken', refresh_token);

    // console.log('Tokens set');
  }

  clearTokens(): any {
    this.localStorage.removeItem('accessToken');
    this.localStorage.removeItem('refreshToken');
    // console.log('Tokens cleared');

  }

  getRegInfo(): Observable<any> {
    return this.client.account_GetRegisterInfo();
  }
}
