import { Client, AdminSectorResponse, AdminSectorRequest, AdminSectorHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class SectorSiteSerivce implements Resolve<any>
{
    public sectorHelper: AdminSectorHelper;
    sector: AdminSectorResponse[];
    onSectorsChanged: BehaviorSubject<any>;
    onSectorsHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onSectorsChanged = new BehaviorSubject({});
        this.onSectorsHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminContactUsRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminSectorRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getSectors(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getSectors(model: AdminSectorRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminLogistic_Sectors(model)
                .subscribe((response: any) => {
                    this.sectorHelper = response;
                    this.sector = this.sectorHelper.sectors;
                    this.onSectorsChanged.next(this.sector);
                    this.onSectorsHelperChanged.next(this.sectorHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getSectorError );
                });
        });
    }

    public sectorsLength(): number {
        return this.sectorHelper && this.sectorHelper.paginator ? this.sectorHelper.paginator.countTotal : 0;
    }
}
