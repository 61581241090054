import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminOrderResponse, AdminBrandResponse, AdminStoreResponse, 
    AdminRouteResponse, OrderNumberType, AdminOrderProdcutResponse, 
    AdminPaymentTypeResponse, AdminSectorResponse, AdminOrderProductAddOrUpdate, 
    AdminOrderCurrencyChangeRequest, AdminProductArticulRequset, AdminOrderAddOrUpdate, ErrorMessage } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ArticleResponse, FileParameter, Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';
import { orderStatus } from 'app/admin/models/orderStatus';

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None

})
export class OrderItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    order: AdminOrderResponse;

    orderStatus = orderStatus;
    selectedStatus = 0;

    userAddresses: string[] = [];

    id = 0;

    isNew = false;
    isReady = false;
    isLoadData = false;

    onlyActiveRoute = true;
    onlyUserRoute = false;

    selectedCurrencyId = environment.PRB;
    targetUserId = null;

    storeAll: AdminStoreResponse[] = [];
    storeAllDisplayed: AdminStoreResponse[] = [];
    storeSearch = '';
    selectedStoreId = null;

    newStoreAll: AdminStoreResponse[] = [];
    newStoreAllDisplayed: AdminStoreResponse[] = [];
    newStoreSearch = '';
    selectedNewStoreId = null;

    routeAll: AdminRouteResponse[] = [];
    routeAllDisplayed: AdminRouteResponse[] = [];
    selectedRouteId = null;
    routeDate = new Date();

    paymentTypeAll: AdminPaymentTypeResponse[] = [];
    selectedPaymentTypeId = null;

    deliveryAll: AdminPaymentTypeResponse[] = [];
    selectedDeliveryId = null;

    orderNumberType = null;

    productArticul = null;

    orderProductArray: FormArray;

    orderForm: FormGroup;
    id1C: FormControl;
    isPaid: FormControl;
    address: FormControl;
    priceProducts: FormControl;
    commentOnOrder: FormControl;
    adminCommentOnOrder: FormControl;
    phone: FormControl;
    userIdentificator: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initOrder();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                if (data) {
                    this.storeAll = data.stores;
                    this.newStoreAll = data.stores;
                    this.routeAll = data.routes;
                    this.storeAllDisplayed = this.storeAll;
                    this.newStoreAllDisplayed = this.newStoreAll;
                    this.deliveryAll = data.deliveries;
                    this.paymentTypeAll = data.paymentTypes;
                    this.routeAll = data.routes;
                    this.filterRoute();
                    this.isLoadData = true;
                }
            }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initOrder(): void {
        this.client.adminOrder_OrderById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.order = data as AdminOrderResponse;
                this.userAddresses = this.order.userAddresses;
                console.log(this.order);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getOrderError );
                console.error(error); });
    }

    initUserIdentificator(): string {
        if (this.isNew) {
            return '';
        } else {
            return this.order.user ? this.order.user.oldLogin1C : '';
        }
    }

    initImagePath(image: string, id: string): string {
        return image ? environment.apiUrlProd + environment.productImageUrl + id + '/' + image : environment.apiUrlProd + environment.noPhotoUrl;
    }

    initRouteUser(): void {
        if (this.order && this.order.permittedRoutes) {
            this.routeAll.map(x => x.isUser = this.order.permittedRoutes.findIndex(y => y.id === x.id) > -1);
        }
    }

    createFormControls(): void {
        this.orderProductArray = new FormArray([]);
        if (this.order) {
            this.id1C = new FormControl(this.order.id1C, Validators.required);
            this.isPaid = new FormControl(this.order.isPaid, Validators.required);
            this.address = new FormControl(this.order.address);
            this.priceProducts = new FormControl(this.order.priceProducts, Validators.required);
            this.commentOnOrder = new FormControl(this.order.commentOnOrder);
            this.adminCommentOnOrder = new FormControl(this.order.adminCommentOnOrder);
            this.phone = new FormControl(this.order.phone);
            this.userIdentificator = new FormControl(this.initUserIdentificator());
            this.selectedStatus = this.order.orderStatusEnum;

            this.targetUserId = this.order.userId;
            this.selectedCurrencyId = String(this.order.currencyEnum);        

            this.order.orderProducts.map(x => this.orderProductArray.push(this.fb.group({
                id: new FormControl(x.id),
                count: new FormControl(x.count, Validators.required),
                pricePerItem: new FormControl(x.pricePerItem, Validators.required),
                pricePerItemFull: new FormControl(x.pricePerItemFull, Validators.required),
                discount: new FormControl(x.discount, Validators.required),
                priceTotal: new FormControl(x.priceTotal, Validators.required),
                productId: new FormControl(x.productId, Validators.required),
                storeId: new FormControl(x.storeId, Validators.required),
                countOrder: new FormControl(x.count, Validators.required),
                image: new FormControl(this.initImagePath(x.product.imageIcon, x.productId)),
                title: new FormControl(`${x.product.brand.name} ${x.product.title}`)
            })));

            this.selectedStoreId = this.order.storeId;
            this.selectedNewStoreId = this.order.newStoreId;

            this.selectedPaymentTypeId = this.order.paymentTypeId;
            this.selectedDeliveryId = this.order.deliveryId;
            this.orderNumberType = this.order.type;

            this.initRouteUser();

        } else {
            this.id1C = new FormControl(null, Validators.required);
            this.isPaid = new FormControl(null, Validators.required);
            this.address = new FormControl(null);
            this.priceProducts = new FormControl(null, Validators.required);
            this.commentOnOrder = new FormControl(null);
            this.adminCommentOnOrder = new FormControl(null);
            this.phone = new FormControl(null);
            this.userIdentificator = new FormControl(null);
        }
        this.orderForm = new FormGroup({
            Id1C: this.id1C,
            IsPaid: this.isPaid,
            Address: this.address,
            PriceProducts: this.priceProducts,
            CommentOnOrder: this.commentOnOrder,
            AdminCommentOnOrder: this.adminCommentOnOrder,
            Phone: this.phone,
            UserIdentificator: this.userIdentificator,
         });

        this.isReady = true;
    }

    getLink(productId: string): string {
        const index = this.order.orderProducts.findIndex(x => x.productId === productId);
        if (index === -1) {
            return environment.clientUrlProd;
        }
        return `${environment.clientUrlProd}/product/${this.order.orderProducts[index].product.stringKey}`;
    }

    filterRoute(): void {
        const model = new Date(this.routeDate);
        const index = model.getUTCDay();
        const modelRoute = this.routeAll.filter(x => x.days[index] === '1');
        modelRoute.sort((a: AdminRouteResponse, b: AdminRouteResponse) => {
            return this.getTime(a.time) - this.getTime(b.time);
        });
        this.routeAllDisplayed = modelRoute;
        if (this.onlyActiveRoute) {
            this.routeAllDisplayed = this.routeAllDisplayed.filter(x => x.isActive);
        }

        if (this.onlyUserRoute) {
            this.routeAllDisplayed = this.routeAllDisplayed.filter(x => x.isUser);
        }
        console.log(this.routeAllDisplayed);
    }

    private getTime(date?: Date) {
        const model = new Date(date);
        return model != null ? model.getTime() : 0;
    }


    getPriceTitle(type: number): string {
        return type === 0 ? 'Бесплатная' : 'Согласно тарифам перевозчика';
    }

    getCarTitle(type: number): string {
        return type === 0 ? 'Транспорт ТирАвто' : 'ч/з Автовокзал';
    }

    routeChanged(id: number): void {
        this.selectedRouteId = id;
    }

    getDeliveryType(): number {
        const index = this.deliveryAll.findIndex(x => x.id === this.selectedDeliveryId);
        if (index === -1) {
            return 0;
        }
        return this.deliveryAll[index].stringKey === environment.deliveryStringKey.pickUp ? 0 : 1;
    }

    getPaymentType(): number {
        const index = this.paymentTypeAll.findIndex(x => x.id === this.selectedPaymentTypeId);
        if (index === -1) {
            return 0;
        }
        return this.paymentTypeAll[index].stringKey === environment.paymentStringKey.inStore ? 0 : 1;
    }

    getStoreAddress(storeId: number): string {
        const index = this.storeAll.findIndex(x => x.id === storeId);
        if (index === -1) {
            return '';
        }
        return `Склад: ${this.storeAll[index].name} по адресу ${this.storeAll[index].adress}`;
    }

    changeStoreDelivery(): void {
        const delivery = this.getDeliveryType();
        if (delivery === 0) {
            if (this.selectedNewStoreId) {
                this.orderForm.controls['Address'].setValue(this.getStoreAddress(this.selectedNewStoreId));
            } else {
                if (this.selectedStoreId) {
                    this.orderForm.controls['Address'].setValue(this.getStoreAddress(this.selectedStoreId));
                }
            }
        }
    }

    createOrderProductRequestModel(): AdminOrderProductAddOrUpdate[] {
        const modelOrderProducts: AdminOrderProductAddOrUpdate[] = [];
        for (let i = 0; i < this.orderProductArray.value.length; i++) {
            const prom: AdminOrderProductAddOrUpdate = {
                id: String(this.orderProductArray.value[i].id),
                count: Number(this.orderProductArray.value[i].count),
                pricePerItem: Number(this.orderProductArray.value[i].pricePerItem),
                pricePerItemFull: Number(this.orderProductArray.value[i].pricePerItemFull),
                discount: Number(this.orderProductArray.value[i].discount),
                priceTotal: Number(this.orderProductArray.value[i].priceTotal),
                storeId: Number(this.orderProductArray.value[i].storeId),
                productId: String(this.orderProductArray.value[i].productId)
            };
            modelOrderProducts.push(prom);
        }
        return modelOrderProducts;
    }

    changeCurrency(): void {
        if (this.orderProductArray && this.orderProductArray.value.length) {
            const modelOrderProducts = this.createOrderProductRequestModel();

            const model: AdminOrderCurrencyChangeRequest = {
                products: modelOrderProducts,
                currency: this.selectedCurrencyId === environment.PRB ? 0 : 1,
                targetUserId: this.targetUserId,
                orderId: this.id
            };

            console.log(model);

            this.client.adminOrder_OrderChangeCurrency(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.orderProductArray = new FormArray([]);
                let result = 0;
                data.map(x => {
                    result += x.priceTotal;
                    this.orderProductArray.push(this.fb.group({
                        id: new FormControl(x.id),
                        count: new FormControl(x.count, Validators.required),
                        pricePerItem: new FormControl(x.pricePerItem, Validators.required),
                        pricePerItemFull: new FormControl(x.pricePerItemFull, Validators.required),
                        discount: new FormControl(x.discount, Validators.required),
                        priceTotal: new FormControl(x.priceTotal, Validators.required),
                        storeId: new FormControl(x.storeId, Validators.required),
                        productId: new FormControl(x.productId, Validators.required),
                        countOrder: new FormControl(x.count, Validators.required),
                        image: new FormControl(this.initImagePath(x.product.imageIcon, x.productId)),
                        title: new FormControl(`${x.product.brand.name} ${x.product.title}`)
                    }));
                });
                this.orderForm.controls['PriceProducts'].setValue(result);

                this.adminService.showNotify(notyf.type.success, notyf.message.currencyChangeCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.currencyChangeError );
                console.error(error); });

        }
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    searchProductByArticul(): void {
        const model: AdminProductArticulRequset = {
            articul: this.productArticul,
            targetUserId: this.isNew ? null : this.order.userId,
            currency: this.isNew ? 0 : this.order.currencyEnum,
            orderId: this.id
        };
        this.productArticul = null;
        this.client.adminProduct_GetProductByArticul(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {

                this.order.orderProducts.push(data);
                console.log(data);
                this.orderProductArray.push(this.fb.group({
                    id: new FormControl(data.id),
                    count: new FormControl(0),
                    pricePerItem: new FormControl(data.pricePerItem),
                    pricePerItemFull: new FormControl(data.pricePerItemFull, Validators.required),
                    discount: new FormControl(data.discount),
                    priceTotal: new FormControl(data.priceTotal),
                    storeId: new FormControl(data.storeId),
                    countOrder: new FormControl(0, Validators.required),
                    productId: new FormControl(data.productId, Validators.required),
                    image: new FormControl(this.initImagePath(data.product.imageIcon, data.id)),
                    title: new FormControl(`${data.product.brand.name} ${data.product.title}`)
                }));
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.productGetByArticulError );
                console.error(error); });
    }

    removeOrderProduct(index: number): void {
        const indexOrderProduct = this.order.orderProducts.findIndex(x => x.productId === this.orderProductArray.value[index].productId);
        if (indexOrderProduct > -1) {
            const result = this.orderForm.value.PriceProducts  - this.orderProductArray.value[index].priceTotal;
            this.orderForm.controls['PriceProducts'].setValue(result);
            this.orderProductArray.value.splice(index, 1);
            this.orderProductArray.controls.splice(index, 1);
            this.order.orderProducts.splice(indexOrderProduct, 1);
        }
    }

    getCurrentStore(i: number, j:number): boolean {
        const storeId = this.selectedNewStoreId ? this.selectedNewStoreId : this.selectedStoreId;
        if (this.order.orderProducts[i] && this.order.orderProducts[i].product.storeProductCount[j]) {
            return this.order.orderProducts[i].product.storeProductCount[j].storeId === this.orderProductArray.value[i].storeId;
        }
        return false;
    }

    searchStore(): void {
        if (!this.storeSearch) {
            this.storeAllDisplayed = this.storeAll;
            return;
        }
        this.storeAllDisplayed = this.storeAll.filter(x => x.name.toLocaleLowerCase().includes(this.storeSearch.toLocaleLowerCase()));
    }

    searchNewStore(): void {
        if (!this.newStoreSearch) {
            this.newStoreAllDisplayed = this.newStoreAll;
            return;
        }
        this.newStoreAllDisplayed = this.newStoreAll.filter(x => x.name.toLocaleLowerCase().includes(this.newStoreSearch.toLocaleLowerCase()));
    }

    addressChange(index: number): void {
        if (!this.userAddresses[index]) {
            return;
        }
        this.orderForm.controls['Address'].setValue(this.userAddresses[index]);
    }

    orderProductStoreChanged(index: number): void {
        this.checkMaxCount(index);
    }

    checkMaxCount(index: number): void {
        const storeIndex = this.order.orderProducts[index].product.storeProductCount.findIndex(x => x.storeId === this.orderProductArray.value[index].storeId);
        if (storeIndex === -1) {
            return;
        }
        const maxCount = Number(this.order.orderProducts[index].product.storeProductCount[storeIndex].count) + Number(this.orderProductArray.value[index].countOrder);
        if (maxCount < Number(this.orderProductArray.value[index].count)) {
            const control = <FormArray>this.orderProductArray;
            <FormArray>control.controls[index]['controls'].count.setValue(maxCount); 
        }
    }

    calculateCount(index: number, type: string): void {
        if (!this.orderProductArray.value[index]) {
            return;
        }

        if (type === 'count') {
            this.checkMaxCount(index);
        }

        const discount = this.chechNumberRange(this.orderProductArray.value[index].discount, 0, 100);
        const count = this.chechNumberRange(this.orderProductArray.value[index].count, 0);
        const pricePerItem = this.chechNumberRange(this.orderProductArray.value[index].pricePerItem, 0);
        const pricePerItemFull = this.chechNumberRange(this.orderProductArray.value[index].pricePerItemFull, 0);

        const control = <FormArray>this.orderProductArray;
        <FormArray>control.controls[index]['controls'].discount.setValue(discount);
        <FormArray>control.controls[index]['controls'].count.setValue(count);
        <FormArray>control.controls[index]['controls'].pricePerItem.setValue(pricePerItem);
        <FormArray>control.controls[index]['controls'].pricePerItemFull.setValue(pricePerItemFull);

        this.client.adminSetting_CalculateCount(Number(count), Number(discount), Number(pricePerItem))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const oldPrice = this.orderForm.value.PriceProducts;
                const oldPriceItem = this.orderProductArray.value[index].priceTotal;
                console.log(data);
                <FormArray>control.controls[index]['controls'].priceTotal.setValue(data);
                const result = Number(oldPrice) - Number(oldPriceItem) + data;
                console.log(`${oldPrice} - ${oldPriceItem} + ${data} = ${result}`);
                this.orderForm.controls['PriceProducts'].setValue(result);
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.calculateOrderPriceError );
                console.error(error); });
    }

    chechNumberRange(model: number, min: number, max?: number): number {
        if (model < min) {
            model = min;
        }
        if (max && model >= max) {
            model = max;
        }
        return model;
    }

    rejectOrder(): void {
        this.client.adminOrder_RejectOrder(this.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.adminService.showNotify(notyf.type.success, notyf.message.orderRejectComplete );
        }, error => { 
            const res = error as ErrorMessage;
            this.adminService.showNotify(notyf.type.error, res.message);
        });
    }

    toPost(): void {
       
        const model: AdminOrderAddOrUpdate = {
            id: this.id,
            id1C: this.orderForm.value.Id1C,
            isPaid: this.orderForm.value.IsPaid, 
            address: this.orderForm.value.Address,
            priceProducts: this.orderForm.value.PriceProducts,
            commentOnOrder: this.orderForm.value.CommentOnOrder,
            routeId: this.selectedRouteId,
            orderNumberType: this.orderNumberType,
            phone: this.orderForm.value.Phone,
            currency: this.selectedCurrencyId === environment.PRB ? 0 : 1,
            dateOfOrder: new Date(),
            userId: this.order.userId,
            paymentTypeId: this.selectedPaymentTypeId,
            deliveryId: this.selectedDeliveryId,
            orderStatus: this.selectedStatus,
            storeId: this.selectedStoreId,
            newStoreId: this.selectedNewStoreId,
            adminCommentOnOrder: this.orderForm.value.AdminCommentOnOrder,
            orderProducts: this.createOrderProductRequestModel()
        };
        console.log(model);
        this.client.adminOrder_OrderAddOrUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            console.log(data);
            this.router.navigate(['/admin/orders']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.orderAddCompleate : notyf.message.orderUpdateCompleate );
        }, error => { 
            console.log(this.order.isReject);
            console.log(this.isNew);
            if (!this.isNew && this.order.isReject) {
                console.log(notyf.message.orderRejectAddOrUpdateError);
                this.adminService.showNotify(notyf.type.error, notyf.message.orderRejectAddOrUpdateError );
            } else {
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.orderAddError : notyf.message.orderUpdateError );
                console.error(error); 
            }});
    }
}
