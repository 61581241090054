import { AdminPageUpdateRequest } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, AdminPageResponse } from 'app/service/Client';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-page-site-item',
  templateUrl: './page-site-item.component.html',
  styleUrls: ['./page-site-item.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PageSiteItemComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  public Editor = ClassicEditor;

  page: AdminPageResponse;
  id = 0;

  pageForm: FormGroup;
  content: FormControl;
  metaTitle: FormControl;
  metaKeywords: FormControl;
  metaDescription: FormControl;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private client: Client,
      private adminService: AdminBaseService

  ) {
      this.route.params
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(params => {
              console.log(params);
              if (params.id) {
                  this.id = params.id;
                  this.initPage();
              } else {
                  this.router.navigate(['/admin/pages']);
              }
          });
  }


  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  initPage(): void {
      this.client.adminPage_PageById(this.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(data => {
              this.page = data as AdminPageResponse;
              this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getPageError );
                console.error(error); });
  }

  createFormControls(): void {

      this.content = new FormControl(this.page.content, Validators.required);
      this.metaTitle = new FormControl(this.page.metaTitle, Validators.required);
      this.metaKeywords = new FormControl(this.page.metaKeywords, Validators.required);
      this.metaDescription = new FormControl(this.page.metaDescription, Validators.required);

      this.pageForm = new FormGroup({
        Content: this.content,
        MetaTitle: this.metaTitle,
        MetaKeywords: this.metaKeywords,
        MetaDescription: this.metaDescription,
      });
  }

  toPost(): void {
      const model: AdminPageUpdateRequest = {
          id: this.id,
          content: this.pageForm.value.Content,
          metaTitle: this.pageForm.value.MetaTitle,
          metaKeywords: this.pageForm.value.MetaKeywords,
          metaDescription: this.pageForm.value.MetaDescription,
      };
      this.client.adminPage_PageUpdate(model)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
          this.adminService.badgeCountChangeStatusSource.next(true);
          this.router.navigate(['/admin/pages']);
          this.adminService.showNotify(notyf.type.success, notyf.message.pageUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.pageUpdateError );
            console.error(error); });
  }

}

