import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminDownloadInfoResponse } from './../../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AdminDownloadInfoItemResponse, FileParameter, Client, ArticleResponse, DownloadInfoItemResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { Subject } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-downloadinfo-item',
  templateUrl: './downloadinfo-item.component.html',
  styleUrls: ['./downloadinfo-item.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DownloadInfoItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    downloadInfoItem: AdminDownloadInfoItemResponse;
    downloadInfo: AdminDownloadInfoResponse[];

    stringKey = '';
    id = null;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    selectedFile: FileParameter;
    selectedImage: FileParameter;

    downloadInfoItemForm: FormGroup;
    title: FormControl;
    isActive: FormControl;
    downloadInfoId: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private adminService: AdminBaseService,
        private client: Client

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.stringKey) {
                    this.isNew = false;
                    this.stringKey = params.stringKey;
                    this.initDownloadInfoItem();
                } else {
                    this.isNew = true;
                    this.client.adminDownloadInfo_DownloadInfoCategory()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(data => {
                        this.downloadInfo = data as AdminDownloadInfoResponse[];   
                        console.log(this.downloadInfo);
                         
                        this.createFormControls();
                    }, error => { 
                        this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                        console.error(error); });
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initDownloadInfoItem(): void {
        this.client.adminDownloadInfo_DownloadInfoItemStringKey(this.stringKey)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.downloadInfoItem = data as AdminDownloadInfoItemResponse;
                this.id = this.downloadInfoItem.id;
                this.downloadInfoItem.image = environment.apiUrlProd + environment.downloadInfoItem + this.downloadInfoItem.id + '/' + this.downloadInfoItem.image;
                this.downloadInfoItem.file = environment.apiUrlProd + environment.downloadInfo + this.downloadInfoItem.id + '/' + this.downloadInfoItem.file;
                this.client.adminDownloadInfo_DownloadInfoCategory()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    this.downloadInfo = data as AdminDownloadInfoResponse[];   
                    console.log(this.downloadInfo);
                     
                    this.createFormControls();
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                    console.error(error); });
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.downloadInfoItem) {
            this.title = new FormControl(this.downloadInfoItem.title, Validators.required);
            this.isActive = new FormControl(this.downloadInfoItem.isActive, Validators.required);
            this.downloadInfoId = new FormControl(this.downloadInfoItem.downloadInfoId, Validators.required);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.downloadInfoId = new FormControl(null, Validators.required);
        }
        this.downloadInfoItemForm = new FormGroup({
            Title: this.title,
            IsActive: this.isActive,
            DownloadInfoId: this.downloadInfoId
        });
        this.isReady = true;
    }

    toPost(): void {
        this.client.adminDownloadInfo_DownloadInfoItemUpdate(
            this.id,
            this.downloadInfoItemForm.value.Title,
            this.selectedFile,
            this.selectedImage,
            this.downloadInfoItemForm.value.IsActive,
            this.downloadInfoItemForm.value.DownloadInfoId
        ).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                console.log(response);
                this.router.navigate(['/admin/downloadfile']);
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.downloadInfoAddCompleate : notyf.message.downloadInfoUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.downloadInfoAddError : notyf.message.downloadInfoUpdateError );
                console.error(error); });
    }

    fileChanged(event: any, type: number) {
        if (type === 1) {
            this.selectedFile = event;
        } else {
            this.selectedImage = event;
        }
    }

}


