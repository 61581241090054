import { Client, AdminPageResponse, AdminPageRequest, AdminPageHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class PageSiteSerivce implements Resolve<any>
{
    public pageHelper: AdminPageHelper;
    page: AdminPageResponse[];
    onPagesChanged: BehaviorSubject<any>;
    onPagesHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onPagesChanged = new BehaviorSubject({});
        this.onPagesHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminContactUsRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminPageRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPages(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getPages(model: AdminPageRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminPage_Pages(model)
                .subscribe((response: any) => {
                    this.pageHelper = response;
                    this.page = this.pageHelper.pages;
                    this.onPagesChanged.next(this.page);
                    this.onPagesHelperChanged.next(this.pageHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getPageError );
                });
        });
    }
}
