import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminUnitResponse, AdminUnitRequest, AdminUnitHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class UnitSiteService implements Resolve<any> {
    units: AdminUnitResponse[];
    unitsHelper: AdminUnitHelper;
    onUnitChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onUnitChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminUnitRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('name')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getUnits(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getUnits(model: AdminUnitRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminUnit_Units(model)
                .subscribe((response: any) => {
                    this.unitsHelper = response;
                    this.units = this.unitsHelper.units;
                    this.onUnitChanged.next(this.units);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getUnitError );
                });
        });
    }

    public unitLength(): number {
        return this.unitsHelper && this.unitsHelper.paginator ? this.unitsHelper.paginator.countTotal : 0;
    }
}
