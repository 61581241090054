import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-chat-start-site',
  templateUrl: './start-site.component.html',
  styleUrls: ['./start-site.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ChatStartSiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
