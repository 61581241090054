import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminCountryResponse, AdminCountryRequest, AdminCountryHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class CountrySiteService implements Resolve<any> {
    countries: AdminCountryResponse[];
    countriesHelper: AdminCountryHelper;
    onCountryChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCountryChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminCountryRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('name')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCountries(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getCountries(model: AdminCountryRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminCountry_CountriesAll(model)
                .subscribe((response: any) => {
                    this.countriesHelper = response;
                    this.countries = this.countriesHelper.countries;
                    this.onCountryChanged.next(this.countries);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getCountryError );
                });
        });
    }

    public countriesLength(): number {
        return this.countriesHelper && this.countriesHelper.paginator ? this.countriesHelper.paginator.countTotal : 0;
    }
}
