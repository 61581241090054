import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminProductResponse, AdminProductHelper, AdminProductRequest } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class ProductSiteService implements Resolve<any> {
    products: AdminProductResponse[];
    productsHelper: AdminProductHelper;
    onProductChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onProductChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminProductRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(''),
            isAll: true,
            isActive: false,
            isBestseller: false,
            isDiscount: false,
            isNew: false,
            isShowMain: false
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getProducts(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getProducts(model: AdminProductRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminProduct_Products(model)
                .subscribe((response: any) => {
                    this.productsHelper = response;
                    this.products = this.productsHelper.products;
                    if (this.products && this.products.length > 0) { 
                        this.products.map(x => x.imageIcon = x.imageIcon ? environment.apiUrlProd + environment.productImageUrl + x.id + '/' + x.imageIcon : null);
                    }
                    this.onProductChanged.next(this.products);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getProductError );
                });
        });
    }

    public productLength(): number {
        return this.productsHelper && this.productsHelper.paginator ? this.productsHelper.paginator.countTotal : 0;
    }
}
