import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'group_apps',
        title: 'Панель управления',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'setting',
                title: 'Настройки',
                translate: 'Настройки',
                type: 'item',
                icon: 'settings',
                url: '/admin/settings'
            },
            {
                id: 'slider',
                title: 'Слайдер',
                translate: 'Слайдер',
                type: 'item',
                icon: 'image',
                url: '/admin/slider'
            },
            {
                id: 'phonecountry',
                title: 'Коды телефонов',
                translate: 'Коды телефонов',
                type: 'item',
                icon: 'local_phone',
                url: '/admin/phones'
            },
        ]
    },
    {
        id: 'group_users',
        title: 'Пользователи',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'users',
                title: 'Пользователи',
                translate: 'Пользователи',
                type: 'item',
                icon: 'people',
                url: '/admin/users',
            },
            {
                id: 'sector',
                title: 'Сектора',
                translate: 'Сектора',
                type: 'item',
                icon: 'map',
                url: '/admin/sectors'
            },
            {
                id: 'route',
                title: 'Маршруты',
                translate: 'Маршруты',
                type: 'item',
                icon: 'location_on',
                url: '/admin/routes'
            },
            {
                id: 'pricelevel',
                title: 'Уровни цен',
                translate: 'Уровни цен',
                type: 'item',
                icon: 'call_split',
                url: '/admin/pricelevels'
            },            
            {
                id: 'chat',
                title: 'Переписка',
                translate: 'Переписка',
                type: 'item',
                icon: 'chat',
                badge: {
                    title: '0',
                    count: 0,
                    bg: '#f44336',
                    fg: '#FFFFFF'
                },
                url: '/admin/chat'
            },
            {
                id: 'contactus',
                title: 'Обратная связь',
                translate: 'Обратная связь',
                type: 'item',
                icon: 'contact_support',
                badge: {
                    title: '0',
                    count: 0,
                    bg: '#f44336',
                    fg: '#FFFFFF'
                },
                url: '/admin/contactus'
            },
            {
                id: 'sto',
                title: 'Запись на СТО',
                translate: 'Запись на СТО',
                type: 'item',
                icon: 'directions_car',
                badge: {
                    title: '0',
                    count: 0,
                    bg: '#f44336',
                    fg: '#FFFFFF'
                },
                url: '/admin/sto'
            },
            {
                id: 'orders',
                title: 'Заказы',
                translate: 'Заказы',
                type: 'item',
                icon: 'list',
                badge: {
                    title: '0',
                    count: 0,
                    bg: '#f44336',
                    fg: '#FFFFFF'
                },
                url: '/admin/orders'
            },
        ]
    },
    {
        id: 'group_products',
        title: 'Товарный раздел',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'products',
                title: 'Товары',
                translate: 'Товары',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/admin/products'
            },
            {
                id: 'categories',
                title: 'Категории',
                translate: 'Категории',
                type: 'item',
                icon: 'category',
                url: '/admin/categories'
            },
            {
                id: 'store',
                title: 'Склады',
                translate: 'Склады',
                type: 'item',
                icon: 'store',
                url: '/admin/stores'
            },
            {
                id: 'units',
                title: 'Единицы измерения',
                translate: 'Единицы измерения',
                type: 'item',
                icon: 'dialpad',
                url: '/admin/units'
            },
            {
                id: 'options',
                title: 'Опции',
                translate: 'Опции',
                type: 'item',
                icon: 'ballot',
                url: '/admin/options'
            },
            {
                id: 'brands',
                title: 'Бренды',
                translate: 'Бренды',
                type: 'item',
                icon: 'device_hub',
                url: '/admin/brands'
            }
        ]
    },
    {
        id: 'group_content',
        title: 'Наполнение сайта',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'news',
                title: 'Новости и акции',
                translate: 'Новости и акции',
                type: 'item',
                icon: 'library_books',
                url: '/admin/news'
            },
            {
                id: 'country',
                title: 'Страны',
                translate: 'Страны',
                type: 'item',
                icon: 'business',
                url: '/admin/countries'
            },
            {
                id: 'city',
                title: 'Города',
                translate: 'Города',
                type: 'item',
                icon: 'location_city',
                url: '/admin/cities'
            },
            {
                id: 'analycategorytics',
                title: 'Категории для скачивания',
                translate: 'Категории для скачивания',
                type: 'item',
                icon: 'category',
                url: '/admin/downloadinfo'
            },
            {
                id: 'file_copy',
                title: 'Файлы для скачивания',
                translate: 'Файлы для скачивания',
                type: 'item',
                icon: 'file_copy',
                url: '/admin/downloadfile'
            },
            {
                id: 'partners',
                title: 'Поставщики',
                translate: 'Поставщики',
                type: 'item',
                icon: 'all_inbox',
                url: '/admin/partners'
            },
            {
                id: 'pages',
                title: 'Страницы',
                translate: 'Страницы',
                type: 'item',
                icon: 'ballot',
                url: '/admin/pages'
            }
        ]
    },
    {
        id: 'group_tecdoc',
        title: 'TecDoc',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'tecdoc_marks',
                title: 'Марки авто',
                translate: 'Марки авто',
                type: 'item',
                icon: 'commute',
                url: '/admin/tecdoc/cars/marks'
            },
            {
                id: 'tecdoc_models',
                title: 'Модели авто',
                translate: 'Модели авто',
                type: 'item',
                icon: 'directions_car',
                url: '/admin/tecdoc/cars/models'
            },
            {
                id: 'tecdoc_modifications',
                title: 'Модификации авто',
                translate: 'Модификации авто',
                type: 'item',
                icon: 'settings_input_svideo',
                url: '/admin/tecdoc/cars/modifications'
            },
            {
                id: 'tecdoc_brandblacklist',
                title: 'Бренды: Черный список',
                translate: 'Бренды: Черный список',
                type: 'item',
                icon: 'label_off',
                url: '/admin/tecdoc/brands/blacklist'
            },
        ]
    },
    // {
    //     id: 'applications',
    //     title: 'Панель управления',
    //     translate: '',
    //     type: 'group',
    //     icon: 'apps',
    //     children: [
    //         {
    //             id: 'setting',
    //             title: 'Настройки',
    //             translate: 'Настройки',
    //             type: 'item',
    //             icon: 'settings',
    //             url: '/admin/settings'
    //         },
    //         {
    //             id: 'chat',
    //             title: 'Переписка',
    //             translate: 'Переписка',
    //             type: 'item',
    //             icon: 'chat',
    //             badge: {
    //                 title: '0',
    //                 count: 0,
    //                 bg: '#f44336',
    //                 fg: '#FFFFFF'
    //             },
    //             url: '/admin/chat'
    //         },
    //         {
    //             id: 'contactus',
    //             title: 'Обратная связь',
    //             translate: 'Обратная связь',
    //             type: 'item',
    //             icon: 'contact_support',
    //             badge: {
    //                 title: '0',
    //                 count: 0,
    //                 bg: '#f44336',
    //                 fg: '#FFFFFF'
    //             },
    //             url: '/admin/contactus'
    //         },
    //         {
    //             id: 'news',
    //             title: 'Новости и акции',
    //             translate: 'Новости и акции',
    //             type: 'item',
    //             icon: 'library_books',
    //             url: '/admin/news'
    //         },
    //         {
    //             id: 'slider',
    //             title: 'Слайдер',
    //             translate: 'Слайдер',
    //             type: 'item',
    //             icon: 'image',
    //             url: '/admin/slider'
    //         },
    //         {
    //             id: 'store',
    //             title: 'Склады',
    //             translate: 'Склады',
    //             type: 'item',
    //             icon: 'store',
    //             url: '/admin/stores'
    //         },
    //         {
    //             id: 'country',
    //             title: 'Страны',
    //             translate: 'Страны',
    //             type: 'item',
    //             icon: 'business',
    //             url: '/admin/countries'
    //         },
    //         {
    //             id: 'city',
    //             title: 'Города',
    //             translate: 'Города',
    //             type: 'item',
    //             icon: 'location_city',
    //             url: '/admin/cities'
    //         },
    //         {
    //             id: 'phonecountry',
    //             title: 'Коды телефонов',
    //             translate: 'Коды телефонов',
    //             type: 'item',
    //             icon: 'local_phone',
    //             url: '/admin/phones'
    //         },
    //         {
    //             id: 'units',
    //             title: 'Единицы измерения',
    //             translate: 'Единицы измерения',
    //             type: 'item',
    //             icon: 'dialpad',
    //             url: '/admin/units'
    //         },
    //         {
    //             id: 'options',
    //             title: 'Опции',
    //             translate: 'Опции',
    //             type: 'item',
    //             icon: 'ballot',
    //             url: '/admin/options'
    //         },
    //         {
    //             id: 'brands',
    //             title: 'Бренды',
    //             translate: 'Бренды',
    //             type: 'item',
    //             icon: 'device_hub',
    //             url: '/admin/brands'
    //         },
    //         {
    //             id: 'categories',
    //             title: 'Категории',
    //             translate: 'Категории',
    //             type: 'item',
    //             icon: 'category',
    //             url: '/admin/categories'
    //         },
    //         {
    //             id: 'users',
    //             title: 'Пользователи',
    //             translate: 'Пользователи',
    //             type: 'item',
    //             icon: 'people',
    //             url: '/admin/users'
    //         },
    //         {
    //             id: 'products',
    //             title: 'Товары',
    //             translate: 'Товары',
    //             type: 'item',
    //             icon: 'local_grocery_store',
    //             url: '/admin/products'
    //         },
    //         {
    //             id: 'downloadinfo',
    //             title: 'Инфо для скачивания',
    //             translate: 'Инфо для скачивания',
    //             type: 'collapsable',
    //             icon: 'perm_device_information',
    //             children: [
    //                 {
    //                     id: 'analycategorytics',
    //                     title: 'Категории',
    //                     type: 'item',
    //                     icon: 'category',
    //                     url: '/admin/downloadinfo'
    //                 },
    //                 {
    //                     id: 'file_copy',
    //                     title: 'Файлы',
    //                     type: 'item',
    //                     icon: 'file_copy',
    //                     url: '/admin/downloadfile'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'partners',
    //             title: 'Поставщики',
    //             translate: 'Поставщики',
    //             type: 'item',
    //             icon: 'all_inbox',
    //             url: '/admin/partners'
    //         },
    //         {
    //             id: 'sto',
    //             title: 'Запись на СТО',
    //             translate: 'Запись на СТО',
    //             type: 'item',
    //             icon: 'directions_car',
    //             badge: {
    //                 title: '0',
    //                 count: 0,
    //                 bg: '#f44336',
    //                 fg: '#FFFFFF'
    //             },
    //             url: '/admin/sto'
    //         },
    //         {
    //             id: 'orders',
    //             title: 'Заказы',
    //             translate: 'Заказы',
    //             type: 'item',
    //             icon: 'list',
    //             badge: {
    //                 title: '0',
    //                 count: 0,
    //                 bg: '#f44336',
    //                 fg: '#FFFFFF'
    //             },
    //             url: '/admin/orders'
    //         },
    //         {
    //             id: 'pages',
    //             title: 'Страницы',
    //             translate: 'Страницы',
    //             type: 'item',
    //             icon: 'ballot',
    //             url: '/admin/pages'
    //         }
    //         // {
    //         //     id       : 'main',
    //         //     title    : 'Dashboards',
    //         //     translate: 'NAV.DASHBOARDS',
    //         //     type     : 'collapsable',
    //         //     icon     : 'dashboard',
    //         //     children : [
    //         //         {
    //         //             id   : 'analytics',
    //         //             title: 'Analytics',
    //         //             type : 'item',
    //         //             url  : '/apps/dashboards/analytics'
    //         //         },
    //         //         {
    //         //             id   : 'project',
    //         //             title: 'Project',
    //         //             type : 'item',
    //         //             url  : '/apps/dashboards/project'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id       : 'calendar',
    //         //     title    : 'Calendar',
    //         //     translate: 'NAV.CALENDAR',
    //         //     type     : 'item',
    //         //     icon     : 'today',
    //         //     url      : '/admin/apps/calendar'
    //         // }
    //     ]
    //     // },
    //     // {
    //     //     id      : 'pages',
    //     //     title   : 'Pages',
    //     //     type    : 'group',
    //     //     icon    : 'pages',
    //     //     children: [
    //     //         {
    //     //             id      : 'authentication',
    //     //             title   : 'Authentication',
    //     //             type    : 'collapsable',
    //     //             icon    : 'lock',
    //     //             badge   : {
    //     //                 title: '10',
    //     //                 bg   : '#525e8a',
    //     //                 fg   : '#FFFFFF'
    //     //             },
    //     //             children: [
    //     //                 {
    //     //                     id   : 'login',
    //     //                     title: 'Login',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/login'
    //     //                 },
    //     //                 {
    //     //                     id   : 'login-v2',
    //     //                     title: 'Login v2',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/login-2'
    //     //                 },
    //     //                 {
    //     //                     id   : 'register',
    //     //                     title: 'Register',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/register'
    //     //                 },
    //     //                 {
    //     //                     id   : 'register-v2',
    //     //                     title: 'Register v2',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/register-2'
    //     //                 },
    //     //                 {
    //     //                     id   : 'forgot-password',
    //     //                     title: 'Forgot Password',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/forgot-password'
    //     //                 },
    //     //                 {
    //     //                     id   : 'forgot-password-v2',
    //     //                     title: 'Forgot Password v2',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/forgot-password-2'
    //     //                 },
    //     //                 {
    //     //                     id   : 'reset-password',
    //     //                     title: 'Reset Password',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/reset-password'
    //     //                 },
    //     //                 {
    //     //                     id   : 'reset-password-v2',
    //     //                     title: 'Reset Password v2',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/reset-password-2'
    //     //                 },
    //     //                 {
    //     //                     id   : 'lock-screen',
    //     //                     title: 'Lock Screen',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/lock'
    //     //                 },
    //     //                 {
    //     //                     id   : 'mail-confirmation',
    //     //                     title: 'Mail Confirmation',
    //     //                     type : 'item',
    //     //                     url  : '/pages/auth/mail-confirm'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id   : 'coming-soon',
    //     //             title: 'Coming Soon',
    //     //             type : 'item',
    //     //             icon : 'alarm',
    //     //             url  : '/pages/coming-soon'
    //     //         },
    //     //         {
    //     //             id      : 'errors',
    //     //             title   : 'Errors',
    //     //             type    : 'collapsable',
    //     //             icon    : 'error',
    //     //             children: [
    //     //                 {
    //     //                     id   : '404',
    //     //                     title: '404',
    //     //                     type : 'item',
    //     //                     url  : '/pages/errors/error-404'
    //     //                 },
    //     //                 {
    //     //                     id   : '500',
    //     //                     title: '500',
    //     //                     type : 'item',
    //     //                     url  : '/pages/errors/error-500'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'invoice',
    //     //             title   : 'Invoice',
    //     //             type    : 'collapsable',
    //     //             icon    : 'receipt',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'modern',
    //     //                     title: 'Modern',
    //     //                     type : 'item',
    //     //                     url  : '/pages/invoices/modern'
    //     //                 },
    //     //                 {
    //     //                     id   : 'compact',
    //     //                     title: 'Compact',
    //     //                     type : 'item',
    //     //                     url  : '/pages/invoices/compact'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id   : 'maintenance',
    //     //             title: 'Maintenance',
    //     //             type : 'item',
    //     //             icon : 'build',
    //     //             url  : '/pages/maintenance'
    //     //         },
    //     //         {
    //     //             id      : 'pricing',
    //     //             title   : 'Pricing',
    //     //             type    : 'collapsable',
    //     //             icon    : 'attach_money',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'style-1',
    //     //                     title: 'Style 1',
    //     //                     type : 'item',
    //     //                     url  : '/pages/pricing/style-1'
    //     //                 },
    //     //                 {
    //     //                     id   : 'style-2',
    //     //                     title: 'Style 2',
    //     //                     type : 'item',
    //     //                     url  : '/pages/pricing/style-2'
    //     //                 },
    //     //                 {
    //     //                     id   : 'style-3',
    //     //                     title: 'Style 3',
    //     //                     type : 'item',
    //     //                     url  : '/pages/pricing/style-3'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id   : 'profile',
    //     //             title: 'Profile',
    //     //             type : 'item',
    //     //             icon : 'person',
    //     //             url  : '/pages/profile'
    //     //         },
    //     //         {
    //     //             id      : 'search',
    //     //             title   : 'Search',
    //     //             type    : 'collapsable',
    //     //             icon    : 'search',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'search-classic',
    //     //                     title: 'Classic',
    //     //                     type : 'item',
    //     //                     url  : '/pages/search/classic'
    //     //                 },
    //     //                 {
    //     //                     id   : 'search-modern',
    //     //                     title: 'Modern',
    //     //                     type : 'item',
    //     //                     url  : '/pages/search/modern'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id   : 'faq',
    //     //             title: 'Faq',
    //     //             type : 'item',
    //     //             icon : 'help',
    //     //             url  : '/pages/faq'
    //     //         },
    //     //         {
    //     //             id   : 'knowledge-base',
    //     //             title: 'Knowledge Base',
    //     //             type : 'item',
    //     //             icon : 'import_contacts',
    //     //             url  : '/pages/knowledge-base'
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     id      : 'user-interface',
    //     //     title   : 'User Interface',
    //     //     type    : 'group',
    //     //     icon    : 'web',
    //     //     children: [
    //     //         {
    //     //             id   : 'cards',
    //     //             title: 'Cards',
    //     //             type : 'item',
    //     //             icon : 'crop_portrait',
    //     //             url  : '/ui/cards'
    //     //         },
    //     //         {
    //     //             id   : 'forms',
    //     //             title: 'Forms',
    //     //             type : 'item',
    //     //             icon : 'web_asset',
    //     //             url  : '/ui/forms'
    //     //         },
    //     //         {
    //     //             id   : 'icons',
    //     //             title: 'Icons',
    //     //             type : 'item',
    //     //             icon : 'photo',
    //     //             url  : '/ui/icons'
    //     //         },
    //     //         {
    //     //             id   : 'typography',
    //     //             title: 'Typography',
    //     //             type : 'item',
    //     //             icon : 'text_fields',
    //     //             url  : '/ui/typography'
    //     //         },
    //     //         {
    //     //             id   : 'helper-classes',
    //     //             title: 'Helper Classes',
    //     //             type : 'item',
    //     //             icon : 'help',
    //     //             url  : '/ui/helper-classes'
    //     //         },
    //     //         {
    //     //             id      : 'page-layouts',
    //     //             title   : 'Page Layouts',
    //     //             type    : 'collapsable',
    //     //             icon    : 'view_quilt',
    //     //             children: [
    //     //                 {
    //     //                     id      : 'carded',
    //     //                     title   : 'Carded',
    //     //                     type    : 'collapsable',
    //     //                     badge   : {
    //     //                         title: '12',
    //     //                         bg   : '#525e8a',
    //     //                         fg   : '#FFFFFF'
    //     //                     },
    //     //                     children: [
    //     //                         {
    //     //                             id   : 'full-width-1',
    //     //                             title: 'Full Width #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/full-width-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'full-width-2',
    //     //                             title: 'Full Width #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/full-width-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'full-width-tabbed-1',
    //     //                             title: 'Full Width Tabbed #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/full-width-tabbed-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'full-width-tabbed-2',
    //     //                             title: 'Full Width Tabbed #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/full-width-tabbed-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-1',
    //     //                             title: 'Left Sidebar #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/left-sidebar-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-2',
    //     //                             title: 'Left Sidebar #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/left-sidebar-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-tabbed-1',
    //     //                             title: 'Left Sidebar Tabbed #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/left-sidebar-tabbed-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-tabbed-2',
    //     //                             title: 'Left Sidebar Tabbed #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/left-sidebar-tabbed-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-1',
    //     //                             title: 'Right Sidebar #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/right-sidebar-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-2',
    //     //                             title: 'Right Sidebar #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/right-sidebar-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-tabbed-1',
    //     //                             title: 'Right Sidebar Tabbed #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/right-sidebar-tabbed-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-tabbed-2',
    //     //                             title: 'Right Sidebar Tabbed #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/carded/right-sidebar-tabbed-2'
    //     //                         }
    //     //                     ]
    //     //                 },
    //     //                 {
    //     //                     id      : 'simple',
    //     //                     title   : 'Simple',
    //     //                     type    : 'collapsable',
    //     //                     badge   : {
    //     //                         title: '10',
    //     //                         bg   : '#525e8a',
    //     //                         fg   : '#FFFFFF'
    //     //                     },
    //     //                     children: [
    //     //                         {
    //     //                             id   : 'full-width-1',
    //     //                             title: 'Full Width #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/full-width-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'full-width-tabbed-1',
    //     //                             title: 'Full Width Tabbed #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/full-width-tabbed-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-1',
    //     //                             title: 'Left Sidebar #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/left-sidebar-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-2',
    //     //                             title: 'Left Sidebar #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/left-sidebar-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-3',
    //     //                             title: 'Left Sidebar #3',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/left-sidebar-3'
    //     //                         },
    //     //                         {
    //     //                             id   : 'left-sidebar-4',
    //     //                             title: 'Left Sidebar #4',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/left-sidebar-4'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-1',
    //     //                             title: 'Right Sidebar #1',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/right-sidebar-1'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-2',
    //     //                             title: 'Right Sidebar #2',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/right-sidebar-2'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-3',
    //     //                             title: 'Right Sidebar #3',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/right-sidebar-3'
    //     //                         },
    //     //                         {
    //     //                             id   : 'right-sidebar-4',
    //     //                             title: 'Right Sidebar #4',
    //     //                             type : 'item',
    //     //                             url  : '/ui/page-layouts/simple/right-sidebar-4'
    //     //                         }
    //     //                     ]
    //     //                 },
    //     //                 {
    //     //                     id   : 'blank',
    //     //                     title: 'Blank',
    //     //                     type : 'item',
    //     //                     url  : '/ui/page-layouts/blank'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id   : 'colors',
    //     //             title: 'Colors',
    //     //             type : 'item',
    //     //             icon : 'color_lens',
    //     //             url  : '/ui/colors'
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     id      : 'angular-material-elements',
    //     //     title   : 'Angular Material Elements',
    //     //     type    : 'group',
    //     //     icon    : 'layers',
    //     //     children: [
    //     //         {
    //     //             id      : 'form-controls',
    //     //             title   : 'Form Controls',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'autocomplete',
    //     //                     title: 'Autocomplete',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/autocomplete'
    //     //                 },
    //     //                 {
    //     //                     id   : 'checkbox',
    //     //                     title: 'Checkbox',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/checkbox'
    //     //                 },
    //     //                 {
    //     //                     id   : 'datepicker',
    //     //                     title: 'Datepicker',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/datepicker'
    //     //                 },
    //     //                 {
    //     //                     id   : 'form-field',
    //     //                     title: 'Form field',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/form-field'
    //     //                 },
    //     //                 {
    //     //                     id   : 'input',
    //     //                     title: 'Input',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/input'
    //     //                 },
    //     //                 {
    //     //                     id   : 'radio-button',
    //     //                     title: 'Radio button',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/radio-button'
    //     //                 },
    //     //                 {
    //     //                     id   : 'select',
    //     //                     title: 'Select',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/select'
    //     //                 },
    //     //                 {
    //     //                     id   : 'slider',
    //     //                     title: 'Slider',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/slider'
    //     //                 },
    //     //                 {
    //     //                     id   : 'slide-toggle',
    //     //                     title: 'Slide toggle',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/slide-toggle'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'navigation',
    //     //             title   : 'Navigation',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'menu',
    //     //                     title: 'Menu',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/menu'
    //     //                 },
    //     //                 {
    //     //                     id   : 'sidenav',
    //     //                     title: 'Sidebar',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/sidenav'
    //     //                 },
    //     //                 {
    //     //                     id   : 'toolbar',
    //     //                     title: 'Toolbar',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/toolbar'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'layout',
    //     //             title   : 'Layout',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'badge',
    //     //                     title: 'Badge',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/badge'
    //     //                 },
    //     //                 {
    //     //                     id   : 'bottom-sheet',
    //     //                     title: 'Bottom Sheet',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/bottom-sheet'
    //     //                 },
    //     //                 {
    //     //                     id   : 'card',
    //     //                     title: 'Card',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/card'
    //     //                 },
    //     //                 {
    //     //                     id   : 'divider',
    //     //                     title: 'Divider',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/divider'
    //     //                 },
    //     //                 {
    //     //                     id   : 'elevation',
    //     //                     title: 'Elevation',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/elevation'
    //     //                 },
    //     //                 {
    //     //                     id   : 'expansion-panel',
    //     //                     title: 'Expansion Panel',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/expansion-panel'
    //     //                 },
    //     //                 {
    //     //                     id   : 'grid-list',
    //     //                     title: 'Grid list',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/grid-list'
    //     //                 },
    //     //                 {
    //     //                     id   : 'list',
    //     //                     title: 'List',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/list'
    //     //                 },
    //     //                 {
    //     //                     id   : 'stepper',
    //     //                     title: 'Stepper',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/stepper'
    //     //                 },
    //     //                 {
    //     //                     id   : 'tabs',
    //     //                     title: 'Tabs',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/tabs'
    //     //                 },
    //     //                 {
    //     //                     id   : 'tree',
    //     //                     title: 'Tree',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/tree'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'buttons-indicators',
    //     //             title   : 'Buttons & Indicators',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'button',
    //     //                     title: 'Button',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/button'
    //     //                 },
    //     //                 {
    //     //                     id   : 'button-toggle',
    //     //                     title: 'Button toggle',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/button-toggle'
    //     //                 },
    //     //                 {
    //     //                     id   : 'chips',
    //     //                     title: 'Chips',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/chips'
    //     //                 },
    //     //                 {
    //     //                     id   : 'icon',
    //     //                     title: 'icon',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/icon'
    //     //                 },
    //     //                 {
    //     //                     id   : 'progress-spinner',
    //     //                     title: 'Progress spinner',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/progress-spinner'
    //     //                 },
    //     //                 {
    //     //                     id   : 'progress-bar',
    //     //                     title: 'Progress bar',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/progress-bar'
    //     //                 },
    //     //                 {
    //     //                     id   : 'ripples',
    //     //                     title: 'Ripples',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/ripples'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'popups-modals',
    //     //             title   : 'Popups & Modals',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'dialog',
    //     //                     title: 'Dialog',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/dialog'
    //     //                 },
    //     //                 {
    //     //                     id   : 'snackbar',
    //     //                     title: 'Snackbar',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/snackbar'
    //     //                 },
    //     //                 {
    //     //                     id   : 'tooltip',
    //     //                     title: 'Tooltip',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/tooltip'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'data-table',
    //     //             title   : 'Data table',
    //     //             type    : 'collapsable',
    //     //             icon    : 'layers',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'paginator',
    //     //                     title: 'Paginator',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/paginator'
    //     //                 },
    //     //                 {
    //     //                     id   : 'sort-header',
    //     //                     title: 'Sort header',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/sort-header'
    //     //                 },
    //     //                 {
    //     //                     id   : 'table',
    //     //                     title: 'Table',
    //     //                     type : 'item',
    //     //                     url  : '/angular-material-elements/table'
    //     //                 }
    //     //             ]
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     id      : 'documentation',
    //     //     title   : 'Documentation',
    //     //     icon    : 'import_contacts',
    //     //     type    : 'group',
    //     //     children: [
    //     //         {
    //     //             id   : 'changelog',
    //     //             title: 'Changelog',
    //     //             type : 'item',
    //     //             icon : 'update',
    //     //             url  : '/documentation/changelog',
    //     //             badge: {
    //     //                 title: '7.1.0',
    //     //                 bg   : '#EC0C8E',
    //     //                 fg   : '#FFFFFF'
    //     //             }
    //     //         },
    //     //         {
    //     //             id      : 'getting-started',
    //     //             title   : 'Getting Started',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'introduction',
    //     //                     title: 'Introduction',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/getting-started/introduction'
    //     //                 },
    //     //                 {
    //     //                     id   : 'installation',
    //     //                     title: 'Installation',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/getting-started/installation'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'working-with-fuse',
    //     //             title   : 'Working with Fuse',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'server',
    //     //                     title: 'Server',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/server'
    //     //                 },
    //     //                 {
    //     //                     id   : 'production',
    //     //                     title: 'Production',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/production'
    //     //                 },
    //     //                 {
    //     //                     id   : 'directory-structure',
    //     //                     title: 'Directory Structure',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/directory-structure'
    //     //                 },
    //     //                 {
    //     //                     id   : 'updating-fuse',
    //     //                     title: 'Updating Fuse',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/updating-fuse'
    //     //                 },
    //     //                 {
    //     //                     id   : 'multi-language',
    //     //                     title: 'Multi Language',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/multi-language'
    //     //                 },
    //     //                 {
    //     //                     id   : 'material-theming',
    //     //                     title: 'Material Theming',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/material-theming'
    //     //                 },
    //     //                 {
    //     //                     id   : 'theme-layouts',
    //     //                     title: 'Theme Layouts',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/theme-layouts'
    //     //                 },
    //     //                 {
    //     //                     id   : 'page-layouts',
    //     //                     title: 'Page Layouts',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/working-with-fuse/page-layouts'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'components',
    //     //             title   : 'Components',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'countdown',
    //     //                     title: 'Countdown',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/countdown'
    //     //                 },
    //     //                 {
    //     //                     id   : 'highlight',
    //     //                     title: 'Highlight',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/highlight'
    //     //                 },
    //     //                 {
    //     //                     id   : 'material-color-picker',
    //     //                     title: 'Material Color Picker',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/material-color-picker'
    //     //                 },
    //     //                 {
    //     //                     id   : 'navigation',
    //     //                     title: 'Navigation',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/navigation'
    //     //                 },
    //     //                 {
    //     //                     id   : 'progress-bar',
    //     //                     title: 'Progress Bar',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/progress-bar'
    //     //                 },
    //     //                 {
    //     //                     id   : 'search-bar',
    //     //                     title: 'Search Bar',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/search-bar'
    //     //                 },
    //     //                 {
    //     //                     id   : 'sidebar',
    //     //                     title: 'Sidebar',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/sidebar'
    //     //                 },
    //     //                 {
    //     //                     id   : 'shortcuts',
    //     //                     title: 'Shortcuts',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/shortcuts'
    //     //                 },
    //     //                 {
    //     //                     id   : 'widget',
    //     //                     title: 'Widget',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components/widget'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : '3rd-party-components',
    //     //             title   : '3rd Party Components',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id      : 'datatables',
    //     //                     title   : 'Datatables',
    //     //                     type    : 'collapsable',
    //     //                     children: [
    //     //                         {
    //     //                             id   : 'ngxdatatable',
    //     //                             title: 'ngx-datatable',
    //     //                             type : 'item',
    //     //                             url  : '/documentation/components-third-party/datatables/ngx-datatable'
    //     //                         }
    //     //                     ]
    //     //                 },
    //     //                 {
    //     //                     id   : 'google-maps',
    //     //                     title: 'Google Maps',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/components-third-party/google-maps'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'directives',
    //     //             title   : 'Directives',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'fuse-if-on-dom',
    //     //                     title: 'fuseIfOnDom',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/directives/fuse-if-on-dom'
    //     //                 },
    //     //                 {
    //     //                     id   : 'fuse-inner-scroll',
    //     //                     title: 'fuseInnerScroll',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/directives/fuse-inner-scroll'
    //     //                 },
    //     //                 {
    //     //                     id   : 'fuse-mat-sidenav',
    //     //                     title: 'fuseMatSidenav',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/directives/fuse-mat-sidenav'
    //     //                 },
    //     //                 {
    //     //                     id   : 'fuse-perfect-scrollbar',
    //     //                     title: 'fusePerfectScrollbar',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/directives/fuse-perfect-scrollbar'
    //     //                 }
    //     //             ]
    //     //         },
    //     //         {
    //     //             id      : 'services',
    //     //             title   : 'Services',
    //     //             type    : 'collapsable',
    //     //             icon    : 'import_contacts',
    //     //             children: [
    //     //                 {
    //     //                     id   : 'fuse-config',
    //     //                     title: 'Fuse Config',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/services/fuse-config'
    //     //                 },
    //     //                 {
    //     //                     id   : 'fuse-splash-screen',
    //     //                     title: 'Fuse Splash Screen',
    //     //                     type : 'item',
    //     //                     url  : '/documentation/services/fuse-splash-screen'
    //     //                 }
    //     //             ]
    //     //         }
    //     //     ]
    // }
];
