import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCountryResponse, AdminBrandAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminBrandResponse, FileParameter, Client, ArticleResponse, BrandResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-brand-item',
    templateUrl: './brand-item.component.html',
    styleUrls: ['./brand-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class BrandSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    brand: AdminBrandResponse;
    countries: AdminCountryResponse[];

    id = 0;

    isNew = false;
    isReady = false;

    countryId = 0;

    phoneArray: FormArray;

    brandForm: FormGroup;
    id1c: FormControl;
    shortName: FormControl;
    seoTitle: FormControl;
    seoDescription: FormControl;
    seoKeywords: FormControl;
    tecDocBrandId: FormControl;
    isTecDoc: FormControl;
    name: FormControl;
    description: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initBrand();
                } else {
                    this.isNew = true;
                    this.initCountries();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initCountries(): void {
        this.client.adminSetting_CountriesAll()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.countries = data;
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getCountryError);
                console.error(error); });
    }

    initBrand(): void {
        this.client.adminBrand_BrandById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.brand = data.brand;
                this.countries = data.countries;
                this.id = this.brand.id;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getBrandError);
                console.error(error); });
    }

    createFormControls(): void {
        this.phoneArray = new FormArray([]);
        if (this.brand) {
            this.id1c = new FormControl(this.brand.id1C, Validators.required);
            this.name = new FormControl(this.brand.name, Validators.required);
            this.shortName = new FormControl(this.brand.shortName, Validators.required);
            this.seoTitle = new FormControl(this.brand.seoTitle);
            this.seoDescription = new FormControl(this.brand.seoDescription);
            this.seoKeywords = new FormControl(this.brand.seoKeywords);
            this.isTecDoc = new FormControl(this.brand.isTecDoc);
            this.tecDocBrandId = new FormControl(this.brand.tecDocBrandId);
            this.description = new FormControl(this.brand.description);
            this.isActive = new FormControl(this.brand.isActive, Validators.required);
            this.countryId = this.brand.countryId;
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.shortName = new FormControl(null, Validators.required);
            this.seoTitle = new FormControl(null);
            this.seoDescription = new FormControl(null);
            this.seoKeywords = new FormControl(null);
            this.isTecDoc = new FormControl(false);
            this.tecDocBrandId = new FormControl(null);
            this.description = new FormControl(null);
            this.isActive = new FormControl(false, Validators.required);
            this.countryId = null;
        }
        this.brandForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            ShortName: this.shortName,
            SeoTitle: this.seoTitle,
            SeoDescription: this.seoDescription,
            SeoKeywords: this.seoKeywords,
            IsTecDoc: this.isTecDoc,
            TecDocBrandId: this.tecDocBrandId,
            Description: this.description,
            IsActive: this.isActive
        });
        this.isReady = true;
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        if (this.brandForm && this.brandForm.valid) {
            const model: AdminBrandAddOrUpdate = {
                id: this.id,
                id1C: this.brandForm.value.Id1c,
                name: this.brandForm.value.Name,
                shortName: this.brandForm.value.ShortName,
                isActive: this.brandForm.value.IsActive,
                description: this.brandForm.value.Description,
                seoTitle: this.brandForm.value.SeoTitle,
                seoDescription: this.brandForm.value.SeoDescription,
                seoKeywords: this.brandForm.value.SeoKeywords,
                tecDocBrandId: this.brandForm.value.TecDocBrandId,
                isTecDoc: this.brandForm.value.IsTecDoc,
                countryId: this.countryId
            };
            this.client.adminBrand_BrandAddOrUpdate(model)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    this.router.navigate(['/admin/brands']);
                    this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.brandAddCompleate : notyf.message.brandUpdateCompleate );
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.brandAddError : notyf.message.brandUpdateError );
                    console.error(error); });
        }
    }

}