import { AdminBaseService } from './../../services/admin.base.service';
import { Client, AdminUserResponse, AdminUserHelper, AdminUserRequest } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';
@Injectable()
export class UserSiteService implements Resolve<any> {
    users: AdminUserResponse[];
    usersHelper: AdminUserHelper;
    onUserChanged: BehaviorSubject<any>;
    constructor(private client: Client, private adminService: AdminBaseService) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminUserRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getUsers(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get users
     *
     * @returns {Promise<any>}
     */
    getUsers(model: AdminUserRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminUser_Users(model)
                .subscribe((response: any) => {
                    this.usersHelper = response;
                    this.users = this.usersHelper.users;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getUserError );
                });
        });
    }
    public userLength(): number {
        return this.usersHelper && this.usersHelper.paginator ? this.usersHelper.paginator.countTotal : 0;
    }
}
