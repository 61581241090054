import { Client, ArticleResponse, BlogRequest, AdminContactUsHelper, AdminContactUsResponse, AdminContactUsRequest } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class ContactusSiteSerivce implements Resolve<any>
{
    public contactusHelper: AdminContactUsHelper;
    contactus: AdminContactUsResponse[];
    onContactusChanged: BehaviorSubject<any>;
    onContactusHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onContactusChanged = new BehaviorSubject({});
        this.onContactusHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminContactUsRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminContactUsRequest = {
            type: 4,
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getContactus(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    deleteContact(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminContactUs_RemoveItemById(id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getContactus(model: AdminContactUsRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminContactUs_Contacts(model)
                .subscribe((response: any) => {
                    this.contactusHelper = response;
                    this.contactus = this.contactusHelper.contactUs;
                    this.onContactusChanged.next(this.contactus);
                    this.onContactusHelperChanged.next(this.contactusHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getContactUsError );
                     });
        });
    }
}
