import { AdminStoreRequest } from './../../../service/Client';
import { StoreSiteService } from './store-site.service';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { MatPaginator, MatSort } from '@angular/material';
import { Client, AdminPageFilterRequest, AdminContactUsRequest } from 'app/service/Client';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-store-site',
    templateUrl: './store-site.component.html',
    styleUrls: ['./store-site.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class StoreSiteComponent implements OnInit {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    dataSource: FilesDataSource | null;

    displayedColumns: string[] = ['pos', 'id1c', 'name', 'type', 'created', 'updated', 'active'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild('filter')
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private client: Client,
        private storeSiteService: StoreSiteService,
        private adminBaseService: AdminBaseService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.adminBaseService.resetPage = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.storeSiteService, this.paginator, this.sort, this.adminBaseService);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.adminBaseService.resetPage = true;
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    getTypeInfo(type: string): string {
        switch (type) {
            case 'Store':
                return 'Склад';
            case 'Sto':
                return 'СТО';
            case 'All':
                return 'Скалд + СТО';
            case 'ForOrder':
                return 'Под заказ';
        }
    }

    changePos(index: number, type: string): void {
        this.storeSiteService.changePosition(index, type);
      }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    pageFilter: AdminPageFilterRequest;

    constructor(
        private storeSiteService: StoreSiteService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
        private _adminBaseService: AdminBaseService
    ) {
        super();

        this.filteredData = this.storeSiteService.stores;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.storeSiteService.onStoreChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    if (!this.pageFilter) {
                        this.pageFilter = this._adminBaseService.getDefaultPageFilter(this._matSort.direction);
                    }
                    const newPageFilter = this._adminBaseService.getPageFilter(
                        this._matPaginator.pageIndex + 1,
                        this._matPaginator.pageSize,
                        this._filterChange.value,
                        this._matSort.active,
                        this._matSort.direction
                    );

                    if (!this._adminBaseService.comparePageFilter(this.pageFilter, newPageFilter)) {
                        const model: AdminStoreRequest = {
                            pageFilter: newPageFilter
                        };
                        this.pageFilter = newPageFilter;
                        if (this._adminBaseService.resetPage) {
                            const result = this._adminBaseService.getDefaultPageFilterViaSearch(model.pageFilter, this._matPaginator);
                            model.pageFilter = result[0];
                            this._matPaginator = result[1];
                        }
                        this.storeSiteService.getStores(model);
                    }
                    let data = this.storeSiteService.stores;

                    if (this.storeSiteService.storesHelper.paginator) {
                        this._matPaginator._length = this.storeSiteService.storesHelper.paginator.countTotal;
                    }
                    if (data) {
                        data = data.slice();
                        this.filteredData = [...data];
                    } else {
                        this.filteredData = [];
                    }
                    console.log(data);

                    return data;
                }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    get paginatorLength(): number {
        return this.storeSiteService.storesHelper.paginator ? this.storeSiteService.storesHelper.paginator.countTotal : 0;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}

