import { AdminBaseService } from './../../../services/admin.base.service';
import { AdminStoResponse, AdminStoUpdateRequest, Client } from './../../../../service/Client';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { notyf } from 'app/admin/models/notyf';

@Component({
  selector: 'app-sto-site-item',
  templateUrl: './sto-site-item.component.html',
  styleUrls: ['./sto-site-item.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class StoSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    sto: AdminStoResponse;
    id = '';

    stoForm: FormGroup;
    commentManager: FormControl;
    date: FormControl;
    rejectReason: FormControl;
    status: FormControl;
    time: FormControl;

    timeList: string[] = [
        '09:00-10:00',
        '10:00-11:00',
        '11:00-12:00',
        '12:00-13:00',
        '13:00-14:00',
        '14:00-15:00',
        '15:00-16:00',
        '16:00-17:00',
        '17:00-18:00'
      ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.id = params.id;
                    this.initContactUs();
                } else {
                    this.router.navigate(['/admin/sto']);
                }
            });
    }


    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initContactUs(): void {
        this.client.adminSto_StoById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.sto = data as AdminStoResponse;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getContactUsError );
                console.error(error); });
    }

    createFormControls(): void {
        this.commentManager = new FormControl(this.sto.commentManager);
        this.date = new FormControl(this.sto.date);
        this.rejectReason = new FormControl(this.sto.rejectReason);
        this.status = new FormControl(this.sto.status);
        this.time = new FormControl(this.sto.time);


        this.stoForm = new FormGroup({
            CommentManager: this.commentManager,
            Date: this.date,
            RejectReason: this.rejectReason,
            Status: this.status,
            Time: this.time
        });
    }

    toPost(): void {
        console.log(this.stoForm.value);
        
        const model: AdminStoUpdateRequest = {
            id: this.sto.id,
            time: this.stoForm.value.Time,
            date: this.stoForm.value.Date,
            commentManager: this.stoForm.value.CommentManager,
            status: this.stoForm.value.Status,
            rejectReason: this.stoForm.value.RejectReason
        };
        this.client.adminSto_StoUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.adminService.badgeCountChangeStatusSource.next(true);
            this.router.navigate(['/admin/sto']);
            this.adminService.showNotify(notyf.type.success, notyf.message.settingUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.settingUpdateError );
            console.error(error); });
    }

}
