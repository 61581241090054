import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminOptionResponse, AdminOptionRequest, AdminOptionHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class OptionSiteService implements Resolve<any> {
    options: AdminOptionResponse[];
    optionsHelper: AdminOptionHelper;
    onOptionChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onOptionChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminOptionRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('name')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getOptions(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getOptions(model: AdminOptionRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminOption_OptionsAll(model)
                .subscribe((response: any) => {
                    this.optionsHelper = response;
                    this.options = this.optionsHelper.options;
                    this.onOptionChanged.next(this.options);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getOptionError);
                });
        });
    }

    changePosition(index: number, type: string): Promise<any> {

        const prom = this.options[index];
        const fisrtId = this.options[index].id;
        let secondId = '';
        if (type === 'up') {
            secondId = this.options[index - 1].id;
            this.options[index] = this.options[index - 1];
            this.options[index - 1] = prom;
        } else {
            secondId = this.options[index + 1].id;
            this.options[index] = this.options[index + 1];
            this.options[index + 1] = prom;
        }
        this.optionsHelper.options = this.options;

        return new Promise((resolve, reject) => {
            this.client.adminOption_ChangeOptionPosition(fisrtId, secondId)
                .subscribe((response: any) => {
                    this.onOptionChanged.next(this.options);
                    resolve(response);
                    this.adminService.showNotify(notyf.type.success, notyf.message.postionChangeCompleate );
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.postionChangeError );
                });
        });           
    }

    public optionLength(): number {
        return this.optionsHelper && this.optionsHelper.paginator ? this.optionsHelper.paginator.countTotal : 0;
    }
}
