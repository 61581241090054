import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminStoreResponse, AdminStoreRequest, AdminStoreHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class StoreSiteService implements Resolve<any> {
    stores: AdminStoreResponse[];
    storesHelper: AdminStoreHelper;
    onStoreChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onStoreChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminStoreRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('name')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getStores(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getStores(model: AdminStoreRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminStore_Stores(model)
                .subscribe((response: any) => {
                    this.storesHelper = response;
                    this.stores = this.storesHelper.strores;
                    if (this.stores && this.stores.length > 0) { 
                        this.stores.map(x => x.image = environment.apiUrlProd + environment.blogMain + x.id + '/' + x.image);
                    }
                    this.onStoreChanged.next(this.stores);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getStoreError );
                });
        });
    }

    changePosition(index: number, type: string): Promise<any> {

        const prom = this.stores[index];
        const fisrtId = this.stores[index].id;
        let secondId = 0;
        if (type === 'up') {
            secondId = this.stores[index - 1].id;
            this.stores[index] = this.stores[index - 1];
            this.stores[index - 1] = prom;

            const position = this.stores[index - 1].position;
            this.stores[index - 1].position = this.stores[index].position;
            this.stores[index].position = position;

        } else {
            secondId = this.stores[index + 1].id;
            this.stores[index] = this.stores[index + 1];
            this.stores[index + 1] = prom;

            const position = this.stores[index + 1].position;
            this.stores[index + 1].position = this.stores[index].position;
            this.stores[index].position = position;
        }
        this.storesHelper.strores = this.stores;

        return new Promise((resolve, reject) => {
            this.client.adminStore_ChangePosition(fisrtId, secondId)
                .subscribe((response: any) => {
                    this.onStoreChanged.next(this.stores);
                    resolve(response);
                    this.adminService.showNotify(notyf.type.success, notyf.message.postionChangeCompleate );
                }, reject => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.postionChangeError );});
        });           
    }

    public storeLength(): number {
        return this.storesHelper && this.storesHelper.paginator ? this.storesHelper.paginator.countTotal : 0;
    }
}
