import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminContactUsResponse, AdminContactUsUpdateRequest } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from 'app/service/Client';
import { takeUntil } from 'rxjs/operators';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-contact-site-item',
    templateUrl: './contact-site-item.component.html',
    styleUrls: ['./contact-site-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ContactSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    contactus: AdminContactUsResponse;
    id = '';

    contactusForm: FormGroup;
    managerComment: FormControl;
    state: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private client: Client,
        private adminService: AdminBaseService

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.id = params.id;
                    this.initContactUs();
                } else {
                    this.router.navigate(['/admin/contactus']);
                }
            });
    }


    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initContactUs(): void {
        this.client.adminContactUs_Contact(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.contactus = data as AdminContactUsResponse;
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getContactUsError );
                console.error(error); });
    }

    createFormControls(): void {

        this.managerComment = new FormControl(this.contactus.managerComment);
        this.state = new FormControl(this.contactus.state, Validators.required);

        this.contactusForm = new FormGroup({
            ManagerComment: this.managerComment,
            State: this.state,
        });
    }

    toPost(): void {
        const model: AdminContactUsUpdateRequest = {
            id: this.id,
            managerComment: this.contactusForm.value.ManagerComment,
            state: this.contactusForm.value.State
        };
        this.client.adminContactUs_ContactUpdate(model)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.adminService.badgeCountChangeStatusSource.next(true);
            this.adminService.showNotify(notyf.type.success, notyf.message.contactUpdateCompleate );
            this.router.navigate(['/admin/contactus']);
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.contactUpdateError );
            console.error(error); });
    }

}
