import { ModelCarService } from './../tecdoc-car-site/model-car-site/model-car.service';
import { ModelCarSiteComponent } from './../tecdoc-car-site/model-car-site/model-car-site.component';
import { MarkCarSiteComponent } from './../tecdoc-car-site/mark-car-site/mark-car-site.component';
import { MarkCarService } from './../tecdoc-car-site/mark-car-site/mark-car.service';
import { OrderItemComponent } from './../orders-site/order-item/order-item.component';
import { SectorSiteSerivce } from './../sector-site/sector.service';
import { PhoneCountrySiteComponent } from './../phone-country-site/phone-country-site.component';
import { CitySiteComponent } from './../city-site/city-site.component';
import { CitySiteService } from './../city-site/city-site.service';
import { StoreSiteService } from './../store-site/store-site.service';
import { StoreSiteComponent } from './../store-site/store-site.component';
import { StoreSiteItemComponent } from './../store-site/store-item/store-item.component';
import { ChatSiteService } from './../chat-site/chat-site.service';
import { PageSiteItemComponent } from './../page-site/page-site-item/page-site-item.component';
import { DownloadInfoFileService } from './../downloadinfo-site/downloadinfo-files-site/downloadinfo-files.service';
import { DownloadInfoSiteService } from './../downloadinfo-site/downloadinfo-site.service';
import { PartnersSiteComponent } from './../partners-site/partners-site.component';
import { PartnerSiteSerivce } from './../partners-site/partner-site.service';
import { ContactSiteItemComponent } from './../contactus-site/contact-site-item/contact-site-item.component';
import { SliderSiteItemComponent } from './../slider-site/slider-site-item/slider-site-item.component';
import { NewsSiteItemComponent } from './../news-site/news-site-item/news-site-item.component';
import { HomeSiteComponent } from './../home-site/home-site.component';
import { SettingSiteComponent } from './../setting-site/setting-site.component';
import { AuthGuard } from './../../guards/auth.guard';

import { Routes } from '@angular/router';
import { ChatSiteComponent } from '../chat-site/chat-site.component';
import { ContactusSiteComponent } from '../contactus-site/contactus-site.component';
import { DownloadInfoSiteComponent } from '../downloadinfo-site/downloadinfo-site.component';
import { NewsSiteComponent } from '../news-site/news-site.component';
import { OrdersSiteComponent } from '../orders-site/orders-site.component';
import { SliderSiteComponent } from '../slider-site/slider-site.component';
import { StoSiteComponent } from '../sto-site/sto-site.component';
import { NewsSiteSerivce } from '../news-site/news-site.service';
import { SliderSiteService } from '../slider-site/slider-site.service';
import { ContactusSiteSerivce } from '../contactus-site/contactus.site.service';
import { OrderSiteSerivce } from '../orders-site/orders.site.service';
import { StoSiteItemComponent } from '../sto-site/sto-site-item/sto-site-item.component';
import { StoSiteSerivce } from '../sto-site/sto-site.service';
import { PartnersSiteItemComponent } from '../partners-site/partners-site-item/partners-site-item.component';
import { DownloadinfoSiteCategoryComponent } from '../downloadinfo-site/downloadinfo-site-category/downloadinfo-site-category.component';
import { DownloadinfoFileSiteComponent } from '../downloadinfo-site/downloadinfo-files-site/downloadinfo-files-site.component';
import { DownloadInfoItemComponent } from '../downloadinfo-site/downloadinfo-files-site/downloadinfo-item/downloadinfo-item.component';
import { PageSiteComponent } from '../page-site/page-site.component';
import { PageSiteSerivce } from '../page-site/page-site.servoce';
import { CountrySiteItemComponent } from '../country-site/country-item/country-item.component';
import { CountrySiteComponent } from '../country-site/country-site.component';
import { CountrySiteService } from '../country-site/country-site.service';
import { CitySiteItemComponent } from '../city-site/city-item/city-item.component';
import { UnitSiteComponent } from '../unit-site/unit-site.component';
import { UnitSiteService } from '../unit-site/unit-site.service';
import { UnitSiteItemComponent } from '../unit-site/unit-item/unit-item.component';
import { OptionSiteItemComponent } from '../option-site/option-item/option-item.component';
import { OptionSiteComponent } from '../option-site/option-site.component';
import { OptionSiteService } from '../option-site/option-site.service';
import { BrandSiteItemComponent } from '../brand-site/brand-item/brand-item.component';
import { BrandSiteComponent } from '../brand-site/brand-site.component';
import { BrandSiteService } from '../brand-site/brand-site.service';
import { CategorySiteItemComponent } from '../category-site/category-item/category-item.component';
import { CategorySiteComponent } from '../category-site/category-site.component';
import { CategorySiteService } from '../category-site/category-site.service';
import { ProductSiteComponent } from '../product-site/product-site.component';
import { ProductSiteService } from '../product-site/product-site.service';
import { ProductSiteItemComponent } from '../product-site/product-item/product-item.component';
import { PriceLevelSiteComponent } from '../price-level-site/price-level-site.component';
import { PriceLevelSiteService } from '../price-level-site/price-level.service';
import { PriceLevelItemComponent } from '../price-level-site/price-level-item/price-level-item.component';
import { RouteItemComponent } from '../route-site/route-item/route-item.component';
import { RouteSiteComponent } from '../route-site/route-site.component';
import { SectorItemComponent } from '../sector-site/sector-item/sector-item.component';
import { SectorSiteComponent } from '../sector-site/sector-site.component';
import { RouteSiteSerivce } from '../route-site/route.service';
import { UserSiteComponent } from '../user-site/user-site.component';
import { UserSiteService } from '../user-site/user-site.service';
import { UserItemComponent } from '../user-site/user-item/user-item.component';
import { MarkItemComponent } from '../tecdoc-car-site/mark-car-site/mark-item/mark-item.component';
import { ModelItemComponent } from '../tecdoc-car-site/model-car-site/model-item/model-item.component';
import { ModificationItemComponent } from '../tecdoc-car-site/modification-car-site/modification-item/modification-item.component';
import { ModificationCarSiteComponent } from '../tecdoc-car-site/modification-car-site/modification-car-site.component';
import { ModificationCarService } from '../tecdoc-car-site/modification-car-site/modification-car.service';
import { BrandBlacklistComponent } from '../brand-blacklist/brand-blacklist.component';


export const ADMIN_ROUTES: Routes = [
    // { path: 'home', canActivate: [AuthGuard], component: HomeSiteComponent, pathMatch: 'full' },
    { path: 'settings', canActivate: [AuthGuard], component: SettingSiteComponent, pathMatch: 'full' },

    { path: 'chat', canActivate: [AuthGuard], component: ChatSiteComponent, pathMatch: 'full', resolve: { chat: ChatSiteService } },

    { path: 'phones', canActivate: [AuthGuard], component: PhoneCountrySiteComponent, pathMatch: 'full'},

    { path: 'contactus/item/:id', canActivate: [AuthGuard], component: ContactSiteItemComponent, pathMatch: 'full' },
    { path: 'contactus', canActivate: [AuthGuard], component: ContactusSiteComponent, pathMatch: 'full' , resolve: { data: ContactusSiteSerivce } },

    { path: 'orders/item/:id', canActivate: [AuthGuard], component: OrderItemComponent, pathMatch: 'full' },
    // { path: 'orders/item', canActivate: [AuthGuard], component: OrderItemComponent, pathMatch: 'full' },
    { path: 'orders', canActivate: [AuthGuard], component: OrdersSiteComponent, pathMatch: 'full', resolve: { data: OrderSiteSerivce } },

     { path: 'users/item/:id', canActivate: [AuthGuard], component: UserItemComponent, pathMatch: 'full' },
    // { path: 'orders/item', canActivate: [AuthGuard], component: OrderItemComponent, pathMatch: 'full' },
    { path: 'users', canActivate: [AuthGuard], component: UserSiteComponent, pathMatch: 'full', resolve: { data: UserSiteService } },

    { path: 'downloadinfo/category/:stringKey', canActivate: [AuthGuard], component: DownloadinfoSiteCategoryComponent, pathMatch: 'full' },
    { path: 'downloadinfo/category', canActivate: [AuthGuard], component: DownloadinfoSiteCategoryComponent, pathMatch: 'full' },
    { path: 'downloadinfo', canActivate: [AuthGuard], component: DownloadInfoSiteComponent, pathMatch: 'full' , resolve: { data: DownloadInfoSiteService } },
    
    { path: 'downloadfile/item/:stringKey', canActivate: [AuthGuard], component: DownloadInfoItemComponent, pathMatch: 'full' },
    { path: 'downloadfile/item', canActivate: [AuthGuard], component: DownloadInfoItemComponent, pathMatch: 'full' },
    { path: 'downloadfile', canActivate: [AuthGuard], component: DownloadinfoFileSiteComponent, pathMatch: 'full' , resolve: { data: DownloadInfoFileService } },

    { path: 'countries/item/:id', canActivate: [AuthGuard], component: CountrySiteItemComponent, pathMatch: 'full' },
    { path: 'countries/item', canActivate: [AuthGuard], component: CountrySiteItemComponent, pathMatch: 'full' },
    { path: 'countries', canActivate: [AuthGuard], component: CountrySiteComponent, pathMatch: 'full' , resolve: { data: CountrySiteService } },

    { path: 'brands/item/:id', canActivate: [AuthGuard], component: BrandSiteItemComponent, pathMatch: 'full' },
    { path: 'brands/item', canActivate: [AuthGuard], component: BrandSiteItemComponent, pathMatch: 'full' },
    { path: 'brands', canActivate: [AuthGuard], component: BrandSiteComponent, pathMatch: 'full' , resolve: { data: BrandSiteService } },
    { path: 'tecdoc/brands/blacklist', canActivate: [AuthGuard], component: BrandBlacklistComponent, pathMatch: 'full' },

    { path: 'tecdoc/cars/marks/item/:id', canActivate: [AuthGuard], component: MarkItemComponent, pathMatch: 'full' },
    // { path: 'tecdoc/cars/marks/item', canActivate: [AuthGuard], component: MarkItemComponent, pathMatch: 'full' },
    { path: 'tecdoc/cars/marks', canActivate: [AuthGuard], component: MarkCarSiteComponent, pathMatch: 'full' , resolve: { data: MarkCarService } },

    { path: 'tecdoc/cars/models/item/:id', canActivate: [AuthGuard], component: ModelItemComponent, pathMatch: 'full' },
    // { path: 'tecdoc/cars/models/item', canActivate: [AuthGuard], component: ModelItemComponent, pathMatch: 'full' },
    { path: 'tecdoc/cars/models', canActivate: [AuthGuard], component: ModelCarSiteComponent, pathMatch: 'full' , resolve: { data: ModelCarService } },

    { path: 'tecdoc/cars/modifications/item/:id', canActivate: [AuthGuard], component: ModificationItemComponent, pathMatch: 'full' },
    // { path: 'tecdoc/cars/modifications/item', canActivate: [AuthGuard], component: ModificationItemComponent, pathMatch: 'full' },
    { path: 'tecdoc/cars/modifications', canActivate: [AuthGuard], component: ModificationCarSiteComponent, pathMatch: 'full' , resolve: { data: ModificationCarService } },
    
    { path: 'cities/item/:id', canActivate: [AuthGuard], component: CitySiteItemComponent, pathMatch: 'full' },
    { path: 'cities/item', canActivate: [AuthGuard], component: CitySiteItemComponent, pathMatch: 'full' },
    { path: 'cities', canActivate: [AuthGuard], component: CitySiteComponent, pathMatch: 'full' , resolve: { data: CitySiteService } },

    { path: 'news/item/:keyString', canActivate: [AuthGuard], component: NewsSiteItemComponent, pathMatch: 'full' },
    { path: 'news/item', canActivate: [AuthGuard], component: NewsSiteItemComponent, pathMatch: 'full' },
    { path: 'news', canActivate: [AuthGuard], component: NewsSiteComponent, pathMatch: 'full', resolve: { data: NewsSiteSerivce } },
     
    { path: 'partners/item', canActivate: [AuthGuard], component: PartnersSiteItemComponent, pathMatch: 'full' },
    { path: 'partners/item/:id', canActivate: [AuthGuard], component: PartnersSiteItemComponent, pathMatch: 'full' },
    { path: 'partners', canActivate: [AuthGuard], component: PartnersSiteComponent, pathMatch: 'full', resolve: { data: PartnerSiteSerivce } },
         
    { path: 'products/item', canActivate: [AuthGuard], component: ProductSiteItemComponent, pathMatch: 'full' },
    { path: 'products/item/:id', canActivate: [AuthGuard], component: ProductSiteItemComponent, pathMatch: 'full' },
    { path: 'products', canActivate: [AuthGuard], component: ProductSiteComponent, pathMatch: 'full', resolve: { data: ProductSiteService } },
         
    { path: 'pricelevels/item', canActivate: [AuthGuard], component: PriceLevelItemComponent, pathMatch: 'full' },
    { path: 'pricelevels/item/:id', canActivate: [AuthGuard], component: PriceLevelItemComponent, pathMatch: 'full' },
    { path: 'pricelevels', canActivate: [AuthGuard], component: PriceLevelSiteComponent, pathMatch: 'full', resolve: { data: PriceLevelSiteService } },

    { path: 'routes/item', canActivate: [AuthGuard], component: RouteItemComponent, pathMatch: 'full' },
    { path: 'routes/item/:id', canActivate: [AuthGuard], component: RouteItemComponent, pathMatch: 'full' },
    { path: 'routes', canActivate: [AuthGuard], component: RouteSiteComponent, pathMatch: 'full', resolve: { data: RouteSiteSerivce } },

    { path: 'sectors/item', canActivate: [AuthGuard], component: SectorItemComponent, pathMatch: 'full' },
    { path: 'sectors/item/:id', canActivate: [AuthGuard], component: SectorItemComponent, pathMatch: 'full' },
    { path: 'sectors', canActivate: [AuthGuard], component: SectorSiteComponent, pathMatch: 'full', resolve: { data: SectorSiteSerivce } },
     
    { path: 'stores/item', canActivate: [AuthGuard], component: StoreSiteItemComponent, pathMatch: 'full' },
    { path: 'stores/item/:id', canActivate: [AuthGuard], component: StoreSiteItemComponent, pathMatch: 'full' },
    { path: 'stores', canActivate: [AuthGuard], component: StoreSiteComponent, pathMatch: 'full', resolve: { data: StoreSiteService } },

    { path: 'categories/item', canActivate: [AuthGuard], component: CategorySiteItemComponent, pathMatch: 'full' },
    { path: 'categories/item/:id', canActivate: [AuthGuard], component: CategorySiteItemComponent, pathMatch: 'full' },
    { path: 'categories', canActivate: [AuthGuard], component: CategorySiteComponent, pathMatch: 'full', resolve: { data: CategorySiteService } },

    { path: 'options/item', canActivate: [AuthGuard], component: OptionSiteItemComponent, pathMatch: 'full' },
    { path: 'options/item/:id', canActivate: [AuthGuard], component: OptionSiteItemComponent, pathMatch: 'full' },
    { path: 'options', canActivate: [AuthGuard], component: OptionSiteComponent, pathMatch: 'full', resolve: { data: OptionSiteService } },
     
    { path: 'units/item', canActivate: [AuthGuard], component: UnitSiteItemComponent, pathMatch: 'full' },
    { path: 'units/item/:id', canActivate: [AuthGuard], component: UnitSiteItemComponent, pathMatch: 'full' },
    { path: 'units', canActivate: [AuthGuard], component: UnitSiteComponent, pathMatch: 'full', resolve: { data: UnitSiteService } },

    { path: 'slider/item', canActivate: [AuthGuard], component: SliderSiteItemComponent, pathMatch: 'full' },
    { path: 'slider/item/:id', canActivate: [AuthGuard], component: SliderSiteItemComponent, pathMatch: 'full' },
    { path: 'slider', canActivate: [AuthGuard], component: SliderSiteComponent, pathMatch: 'full' , resolve: { data: SliderSiteService } },

    { path: 'pages/item/:id', canActivate: [AuthGuard], component: PageSiteItemComponent, pathMatch: 'full' },
    { path: 'pages', canActivate: [AuthGuard], component: PageSiteComponent, pathMatch: 'full' , resolve: { data: PageSiteSerivce } },

    { path: 'sto/item/:id', canActivate: [AuthGuard], component: StoSiteItemComponent, pathMatch: 'full' },
    { path: 'sto/item', canActivate: [AuthGuard], component: StoSiteItemComponent, pathMatch: 'full' },
    { path: 'sto', canActivate: [AuthGuard], component: StoSiteComponent, pathMatch: 'full', resolve: { data: StoSiteSerivce } },

    { path: '**', redirectTo: 'settings' }

    // { path: 'track/:orderNumber', component: OrderPostInfoComponent, pathMatch: 'full' }
];
