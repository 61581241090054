// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    apiUrl: 'https://mainapi.tiravto.com',
    // apiUrlProd: 'https://localhost:44358',
    apiUrlProd: 'https://mainapi.tiravto.com',
    storageUrl: 'https://storage.tiravto.com/image/tiravto/images/',
    clientUrlProd: 'https://admin.tiravto.com/',
    productImageUrl: '/images/products/',
    categoryImageUrl: '/images/categories/',
    storeImageUrl: '/images/stores/',
    noPhotoUrl: '/Content/Main/no-photo.jpg',
    sliderMain: '/Content/mainSlider/',
    blogMain: '/images/articles/',
    partners: '/images/partners/',
    downloadInfo: '/images/downloadInfo/',
    downloadInfoItem: '/Content/DownloadInfoItem/File/',
    infoMain: '/images/downloadInfo/',
    infoItemMain: '/images/downloadInfoItem/',
    infoItemFileMain: '/Content/DownloadInfoItem/File/',
    socialPath: '/Content/Main/',
    production: false,
    hmr: false,
    PRB: 'PRB',
    USD: 'USD',
    deliveryStringKey: {
        pickUp: 'CustomerPickup',
        courier: 'Courier'
    },
    paymentStringKey: {
        inStore: 'InStore',
        eCash: 'ECash'
    },
    daysOfWeek: [
        { title: 'Понедельник', index: 0, isCheck: false },
        { title: 'Вторник', index: 1, isCheck: false },
        { title: 'Среда', index: 2, isCheck: false },
        { title: 'Четверг', index: 3, isCheck: false },
        { title: 'Пятница', index: 4, isCheck: false },
        { title: 'Суббота', index: 5, isCheck: false },
        { title: 'Воскресенье', index: 6, isCheck: false }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
