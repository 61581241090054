import { AdminTecDocMarkRequest } from './../../../../service/Client';
import { AdminBaseService } from './../../../services/admin.base.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminTecDocMarkResponse, AdminTecDocMarkHelper, Client } from '../../../../service/Client';
import { notyf } from '../../../models/notyf';

@Injectable()
export class MarkCarService implements Resolve<any> {
    marks: AdminTecDocMarkResponse[];
    marksHelper: AdminTecDocMarkHelper;
    onCategoryChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCategoryChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminTecDocMarkRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getMarks(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getMarks(model: AdminTecDocMarkRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminTecDocCar_Marks(model)
                .subscribe((response: any) => {
                    this.marksHelper = response;
                    this.marks = this.marksHelper.marks;
                    this.onCategoryChanged.next(this.marks);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getMarkError );
                });
        });
    }

    public markLength(): number {
        return this.marksHelper && this.marksHelper.paginator ? this.marksHelper.paginator.countTotal : 0;
    }
}
