import { AdminBaseService } from './../../services/admin.base.service';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminPhoneCountryResponse, AdminPhoneCountryUpdateRequest } from './../../../service/Client';
import { Client } from 'app/service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-phone-country-site',
    templateUrl: './phone-country-site.component.html',
    styleUrls: ['./phone-country-site.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PhoneCountrySiteComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    phoneCountries: AdminPhoneCountryResponse[];

    phoneArray: FormArray;

    constructor(
        private client: Client,
        private adminService: AdminBaseService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.initPhoneCountry();
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initPhoneCountry(): void {
        this.client.adminPhoneCounty_PhoneCountryAll()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.phoneCountries = data as AdminPhoneCountryResponse[];
            this.initPhoneArray();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getPhoneCountryError );
            console.error(error); });
    }

    initPhoneArray(): void {
        this.phoneArray = new FormArray([]);
        this.phoneCountries.map(x => {
            this.phoneArray.push(
                this.fb.group({
                  name: new FormControl(x.name, Validators.required),
                  length: new FormControl(x.length, Validators.required),
                  code: new FormControl(x.code, Validators.required),
                  isActive: new FormControl(x.isActive, Validators.required),
                  id: new FormControl(x.id, Validators.required)
                }));
        });
    }

    toPost(): void {
        console.log(this.phoneArray.valid);
        if (this.phoneArray.valid) {
            const model: AdminPhoneCountryUpdateRequest[] = [];
            this.phoneArray.value.map(x => {
                const prom: AdminPhoneCountryUpdateRequest = {
                    id: x.id,
                    name: x.name,
                    length: x.length,
                    code: x.code,
                    isActive: x.isActive
                };
                model.push(prom);
            });
            console.log(model);
            this.client.adminPhoneCounty_PhoneCountryUpdate(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.adminService.showNotify(notyf.type.success, notyf.message.phoneCountryUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.phoneCountryUpdateError );
                console.error(error); });
        }
    }

}
