import { AdminBaseService } from './../../../services/admin.base.service';
import {  AdminCountryAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminCountryResponse, FileParameter, Client, ArticleResponse, CountryResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';

@Component({
    selector: 'app-country-item',
    templateUrl: './country-item.component.html',
    styleUrls: ['./country-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CountrySiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    country: AdminCountryResponse;
    countries: AdminCountryResponse[];

    id = 0;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    countryForm: FormGroup;
    id1c: FormControl;
    name: FormControl;
    isActive: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initCountry();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initCountry(): void {
        this.client.adminCountry_CountryById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.country = data;
                this.id = this.country.id;
                console.log(this.country);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getCountryError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.country) {
            this.id1c = new FormControl(this.country.id1C, Validators.required);
            this.name = new FormControl(this.country.name, Validators.required);
            this.isActive = new FormControl(this.country.isActive, Validators.required);
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
        }
        this.countryForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            IsActive: this.isActive,
        });

        this.isReady = true;
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        if (this.countryForm.valid) {
            const model: AdminCountryAddOrUpdate = {
                id: this.id,
                id1C: this.countryForm.value.Id1c,
                name: this.countryForm.value.Name,
                isActive: this.countryForm.value.IsActive
            };
            this.client.adminCountry_CountryAddOrUpdate(model)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.countryAddCompleate : notyf.message.countryUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.countryAddError : notyf.message.countryUpdateError );
                console.error(error); });
        }
    }

    changePos(index: number, type: string): void {
        const prom = this.country.cities[index];
        const fisrtId = this.country.cities[index].id;
        const firstPos = this.country.cities[index].position;
        const secondId = type === 'up' ? this.country.cities[index - 1].id : this.country.cities[index + 1].id;
        this.client.adminCountry_CityChangePosition(fisrtId, secondId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            let secondPos = 0;
            if (type === 'up') {
                secondPos = this.country.cities[index - 1].position;
                this.country.cities[index] = this.country.cities[index - 1];
                this.country.cities[index - 1] = prom;
                this.country.cities[index].position = firstPos;
                this.country.cities[index - 1].position = secondPos;
            } else {
                 secondPos = this.country.cities[index + 1].position;
                this.country.cities[index] = this.country.cities[index + 1];
                this.country.cities[index + 1] = prom;
                this.country.cities[index].position = firstPos;
                this.country.cities[index + 1].position = secondPos;
            }
            this.router.navigate(['/admin/countries']); 
            this.adminService.showNotify(notyf.type.success, notyf.message.postionChangeCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.postionChangeError );
            console.error(error); });
    }
}