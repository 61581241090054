import { getFilters } from './../../../main/apps/mail-ngrx/store/selectors/filters.selectors';
import { AdminBaseService } from '../../services/admin.base.service';
import { environment } from '../../../../environments/environment.hmr';
import { Client, AdminCityResponse, AdminCityHelper, AdminCityRequest } from '../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class CitySiteService implements Resolve<any> {
    cities: AdminCityResponse[];
    citiesHelper: AdminCityHelper;
    onCityChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCityChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminCityRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('name'),
            countryId: 0
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCities(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getCities(model: AdminCityRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminCountry_CityAll(model)
                .subscribe((response: any) => {
                    this.citiesHelper = response;
                    this.cities = this.citiesHelper.cities;
                    this.onCityChanged.next(this.cities);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getCityError );
                });
        });
    }

    public citiesLength(): number {
        return this.citiesHelper && this.citiesHelper.paginator ? this.citiesHelper.paginator.countTotal : 0;
    }
}
